
import { PAYMENTMSG, RAZORPAYRES} from '../constants/actionTypes';
import * as api from '../api/index.js';

export const producer_transaction = (formData) => async (dispatch) => {
    try {
      const { data } = await api.producer_transaction(formData);
      dispatch({ type: PAYMENTMSG, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };
  export const proxy_payouts = (formData) => async (dispatch) => {
    try {
      const { data } = await api.proxy_payouts(formData);
      dispatch({ type: RAZORPAYRES, payload:data });
      return data
   
    } catch (error) {
      console.log(error);
    }
  };