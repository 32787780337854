

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from '../components/Layout/Layout';
import CustomerDetail from "../components/CustomerDetail/CustomerDetail"

import MoviesDownload from '../components/CustomerDetail/MoviesDownload';
import MoviesWatched from '../components/CustomerDetail/MoviesWatched';
function CustomerDetailModule() {
  return (
    <div className="App">
    
<Routes>
< Route path='/CustomerDetail' element={<Layout children={CustomerDetail()}/>}/>

< Route path='/MoviesDownload' element={<Layout children={MoviesDownload()}/>}/>
< Route path='/MoviesWatched' element={<Layout children={MoviesWatched()}/>}/>



      </Routes>
    </div>
  );
}

export default CustomerDetailModule;
