import React from "react";
import { useState, useEffect, useMemo } from "react";

import ListTable from "../utils/Table";
import { all_transaction_list } from "./../../actions/transaction";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Export from "./../utils/Export";
export default function Transaction() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isEdit, setIsEdit] = useState(false);
  const [totalAmount, setTotelAmount] = useState("");
  const [filter, setFilter] = useState("");
  const [toggle, setToggle] = useState("Movie");
  const [toggle1, setToggle1] = useState("Movie");

  const [open, setOpen] = useState(false);

  const [form, setForm] = useState({});

  const [tableData, setTableData] = useState({
    tableTitle: "Video List",
    notForDelete: true,
    // disableDelete: true,
    // column_sum: {
    //   name: "payment_amount",
    //   title: "Total Amount",
    // },
    toggle_state: {
      title: "Audio Language",
      name: toggle,
      default: "Movie",
      // fixed:
      options: [
        { value: "Movie", color: "success" },
        { value: "Series", color: "success" },
      ],
      handleChangeto: (event ,newDevices) => {
        setToggle(event.target.value);
        // setToggle(newDevices)
      },
    },
    tableHead: [
      {
        id: "name1",
        label: "Video List",
        color: "var(--gradientColor2)",
      },
      {
        id: "RentalCount",
        label: "Views",
      },
   
      {
        id: "RentalPrice",
        label: "Total Amount",
      },
      
      {
        id: "RentalCount",
        label: "Total Rental",
        // isSpecial : "true",
        // align : "center"
      },
 
      // {
      //   id: "plan",
      //   label: "Plan",
      //   // isSpecial: true,
      //   align: "left",
      // },
      // {
      //   id: "payment_amount",
      //   label: "Amount",
      // },
      // {
      //   id: "payment_id",
      //   label: "Payment ID",
      //   // subText: "payment_method"
      // },
      // {
      //   id: "date",
      //   label: "Date",
      // },

      // {
      //   id: "device_type",
      //   label: "Device Type",
      //   subText: "device_id",
      // },

      // // {
      // // 	id: "payment_status",
      // // 	label: "Status",
      // // 	isButtonDisplay: true
      // // },
    ],
    tableBody: [],
    filterColumn: [
      // {
      //   id: "1",
      //   title: "Category Type",
      //   name: "category_type",
      //   // value: totalAmount,
      //   // default: location?.state,
      //   options: ["Video", "Web Series"],
      // },
      //   {
      //     id: "1",
      //     title: "Status",
      //     name: "payment_status",
      //     displayOn: "category_type",
      //     // value: totalAmount,
      //     // default: location?.state,
      //     options: ["Failed", "Success"],
      //   },
    ],

    // isDateRangeFilter: "date",
  });
  // console.log(toggle, tableData.toggle_state.value, "paffjjfj");
  const transactions = useSelector(
    (state) => state?.transactions?.transactions
  );
  // console.log(transactions, "tttt");

  useEffect(() => {
    const data = new FormData();
    data.append("category_type", toggle);
    data.append("id", 0);
    dispatch(all_transaction_list(data));
  }, [toggle]);
  const handleClose = () => {
    setOpen(false);
  };
  useMemo(() => {
    if (transactions?.statuscode == 200 && tableData.tableBody[tableData.tableBody.length - 1]?.id!=transactions?.data[transactions?.data.length - 1]?.id) {
      const temp = tableData;
      temp.tableBody = transactions?.data.map((ele) => ({
        ...ele,


        RentalCount : (ele.count).toString(),
        RentalPrice: (ele?.price).toFixed(2),
        name1:
         
            <Link
              to="/Transactions/TransactionsDetails/TransactionsDetails"
              state={{ id: ele?.id,category_type:toggle }}
            >
              <p style={{ fontWeight: "700", color: "black" }}>{ele?.name}</p>
            </Link>
          
      }));

      //   temp.filterColumn[0]["options"] = [
      //     ...new Set(
      //       transactions?.data.map((ele) =>
      //         ele.plan?.status === "Active" ? ele?.plan?.plan_name : ""
      //       )
      //     ),
      //   ];

      setTableData({ ...temp });
      if(transactions?.isFull=="False" ){
				
        const data = new FormData();
        data.append("category_type", toggle);
        data.append("id", transactions?.data[transactions?.data.length - 1]?.id);
        dispatch(all_transaction_list(data));
    
				// dispatch(all_transaction_list({id:transactions?.data[transactions?.data.length - 1]?.id}))
			
			}
    }
  }, [transactions]);
  // console.log(transactions, "75533589");

  const user = useSelector((state) => state.layout.profile);
  const movies = useSelector((state) => state.movies.movies);
  const series = useSelector((state) => state.webseries.series);

  //   useEffect(() => {
  //     const data = new FormData();
  //     data.append("id", user?.id);
  //     dispatch(all_series_list(data));
  // 	dispatch(all_movie_list(data));
  //   }, [user]);
  // console.log( tableData.filterColumn["category_type"],"159159")
  //   useMemo(() => {
  // 	const temp = tableData;
  // if(temp.filterColumn[0]["value"] ="Video" )
  // 	temp.filterColumn[0]["options"]
  //   }, []);
// console.log(tableData?.tableBody,"parth gohel")
  return (
    <>
      <Export
        fileName={"Video Report"}
        exportData={tableData?.exportData || tableData?.tableBody}
        headings={tableData.tableHead.map((value) => value.label)}
      />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert severity="info" variant="filled" color="success">
          You can not view user details because the user is already deleted
        </Alert>
      </Snackbar>

      <ListTable
        tableData={tableData}
        key={"ListTable"}
        setTableData={setTableData}
        setForm={setForm}
        setIsEdit={setIsEdit}
      />
    </>
  );
}
