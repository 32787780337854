

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from '../components/Layout/Layout';
// import DistributorsFormData from "../components/ProducerForm/DistributorsFormData"
// import FilmFestivalFormData from '../components/FilmFestivalSubmission/FilmFestivalFormPendingData';
import FilmFestivalDetails from "./FilmFestivalFormDetailsModule"
import FilmFestivalFormPendingData from '../components/FilmFestivalSubmission/FilmFestivalFormPendingData';
// import FilmFestivalFormShortListData from '../components/FilmFestivalSubmission/FilmFestivalFormShortListData';
// import FilmFestivalFormRejectedData from '../components/FilmFestivalSubmission/FilmFestivalFormRejectedData';
// import FilmFestivalFormNominatedData from '../components/FilmFestivalSubmission/FilmFestivalFormNominatedData';



function FilmFestivalFormPendingDataModule() {
  return (
    <div className="App">
    
<Routes>
< Route path='/FilmFestivalPendingData' element={<Layout children={FilmFestivalFormPendingData()}/>}/>
{/* < Route path='/FilmFestivalFormShortListData' element={<Layout children={FilmFestivalFormShortListData()}/>}/> */}
{/* < Route path='/FilmFestivalFormRejectedData' element={<Layout children={FilmFestivalFormRejectedData()}/>}/> */}
{/* < Route path='/FilmFestivalFormNominatedData' element={<Layout children={FilmFestivalFormNominatedData()}/>}/> */}

< Route path='/FilmFestivalPendingData/*' element={<FilmFestivalDetails key="FilmFestivalFormDetails"/>}/>

      </Routes>
    </div>
  );
}

export default FilmFestivalFormPendingDataModule;