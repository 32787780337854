import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import SSEpisode from "../components/WebSeries/SSEpisode/SSEpisode";
import ProductDetails from "../components/ProductDetails/ProductDetailsContent"


function SSEpisodeModule() {
  return (
    <div className="App">
      <Routes>
        <Route path="/SSEpisode" element={<Layout children={SSEpisode()} />} />
        <Route
          path="/CreateSSEpisode"
          element={<Layout children={SSEpisode()} />}
        />
        <Route
          path="/EditSSEpisode"
          element={<Layout children={SSEpisode()} />}
        />
        <Route
          path="/SSEpisode/EpisodeDetails"
          element={<Layout children={ProductDetails()} />}
        />
      </Routes>
    </div>
  );
}

export default SSEpisodeModule;
