import { MESSAGE , FILMFESTIVALFORMDATA,FILMFESTIVALFORMDETAILS} from '../constants/actionTypes';
import * as api from '../api/index.js';


export const film_festival_movie_form_create = (formData) => async (dispatch) => {
    try {
      const { data } = await api.film_festival_movie_form_create(formData);
      dispatch({ type: MESSAGE, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };

  
export const film_festival_movie_status_update = (formData) => async (dispatch) => {
  try {
    const { data } = await api.film_festival_movie_status_update(formData);
    dispatch({ type: MESSAGE, payload:data });

 
  } catch (error) {
    console.log(error);
  }
};

export const all_film_festival_movie_form_list = (formData) => async (dispatch) => {
    try {
      const { data } = await api.all_film_festival_movie_form_list(formData);
      dispatch({ type: FILMFESTIVALFORMDATA, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };

  export const film_festival_movie_detail_form = (formData) => async (dispatch) => {
    try {
      const { data } = await api.film_festival_movie_detail_form(formData);
      dispatch({ type: FILMFESTIVALFORMDETAILS, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };
