import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import TransactionDetails from "../components/Transaction/TransactionDetails";

function TransactionsDetailsModule() {
  return (
    <div className="App">
      <Routes>
        <Route
          path="/TransactionsDetails"
          element={<Layout children={TransactionDetails()} />}
        />
      </Routes>
    </div>
  );
}

export default TransactionsDetailsModule;
