

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Transaction from '../components/Transaction/Transaction';
import AllTransaction from '../components/TransactionList/AllTransaction';

import Layout from '../components/Layout/Layout';
import TransactionsDetailsModule from './TransactionsDetailsModule';

function TransactionModule() {
  return (
    <div className="App">
    
<Routes>
< Route path='/Transactions' element={<Layout children={Transaction()}/>}/>
{/* < Route path='/AllTransaction' element={<Layout children={AllTransaction()}/>}/> */}

< Route path='/TransactionsDetails/*' element={<TransactionsDetailsModule key="TransactionsDetails" />} />


      </Routes>
    </div>
  );
}

export default TransactionModule;