import React, { useEffect, useMemo, useState } from "react";
import MultiStepForm from "../utils/MultiStepForm";
import { FormStructure } from "./FormStructure";
// import { all_language_list } from "../../../actions/Masters/language";
import { useDispatch, useSelector } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import RemoveIcon from "@mui/icons-material/Remove";
import Alert from "@mui/material/Alert";
// import { TableData } from "./TableData";
import ViewChange from "../utils/ViewChange";
import { useLocation, useNavigate } from "react-router-dom";
import { all_genre_list } from "../../actions/Masters/genre";
import { all_language_list } from "../../actions/Masters/language";
import { film_festival_movie_form_create } from "../../actions/filmfestival";

import Popup from "../utils/Popup";

const FilmFestivalForm = () => {
  const tempFormStruct = FormStructure();
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [form, setForm] = useState({});
  const [openAdError, setOpenAdError] = useState(false);

  const [open, setOpen] = useState(false);
  const [content, setPopupContent] = useState("");
  const [result, setResult] = useState(undefined);
  const [errorFields, setErrorFields] = useState({});
  const [subTitleID, setsubTitleID] = useState("");
  const [audioFileId, setAudioFileId] = useState("");
  const [subtitlePosition, setSubtitlePosition] = useState();
  const [audioPosition, setAudioPosition] = useState();
  const [formTitle, setFormTitle] = useState(
    [
      "Movie Detail",
      "Movie Resolutions",
      "Upload Audio Language",
      "Upload Subtitle File",
    ].filter((e) => e)
  );

  const [formStructure, setFormStructure] = useState([...tempFormStruct]);
  useEffect(() => {
    dispatch(all_genre_list());
    dispatch(all_language_list());
  }, []);
  const genre = useSelector((state) => state.masters.genre);
  const language = useSelector((state) => state.masters.languages);
  const message = useSelector((state) => state.layout.message);

  const handleAddSubtitle = (e) => {
    // console.log(e.currentTarget,"1111");
    const temp = formStructure;

    if (formStructure[3].length < language?.data.length * 3) {
      // console.log(form, "tr");

      const randomName = formStructure[3].length / 3;
      // console.log(randomName, "101010");

      temp[3].push(
        {
          id: "21",
          type: "select",
          title: "Select Language",
          size: "6",
          name: "subtitle_language_" + randomName,
          options: temp[3][1]["options"],
        },
        {
          id: "19",
          type:"inputBox",
          subtitle: "*",
          title: "Browse Subtitle file(.srt)",
          size: "5.5",
          name: "subtitle_file_" + randomName,
          endsWith: ".srt",
          errorText: "File extension should be .srt only",
          // required: true,
        },

        {
          id: "23",
          type: "button",
          title: <RemoveIcon sx={{ color: "#fff !important" }} />,
          align: "right",
          size: ".5",
          padding: "2px 2px",
          position: randomName,
          forceShow: true,
          handleClick: () => {
            setOpen(true);
            setSubtitlePosition(randomName);
            setsubTitleID(form["subtitle_id_" + randomName]);
            setPopupContent(
              "Do you want to remove this subtitle from this movie?"
            );
          },
        }
      );

      setFormStructure([...temp]);
    } else {
      setOpenAdError(true);
      setPopupContent(
        "You can add max " + language?.data.length + " subtitles in a movie"
      );
    }
  };
  const handleAddAudio = (e) => {
    // console.log(e.currentTarget,"1111");
    const temp = formStructure;

    if (formStructure[2].length < language?.data.length * 3) {
      // console.log(form, "tr");

      const randomName = formStructure[2].length / 3;
      // console.log(randomName, "11111");

      temp[2].push(
        {
          id: "21",
          type: "select",
          title: "Select Language",
          size: "6",
          name: "audio_language_" + randomName,
          options: temp[2][1]["options"],
        },
        {
          id: "19",
          type: "inputBox",
          subtitle: "*",
          title: "Upload wav file",
          name: "audio_file_" + randomName,
          size: "5.5",
          endsWith: ".wav" ,
        
          errorText: "File extension should be .wav only",
          // required: true,
        },

        {
          id: "23",
          type: "button",
          title: <RemoveIcon sx={{ color: "#fff !important" }} />,
          align: "right",
          size: ".5",
          padding: "2px 2px",
          position: randomName,
          forceShow: true,
          handleClick: () => {
            setOpen(true);
            setAudioPosition(randomName);
            setAudioFileId(form["audio_id_" + randomName]);
            setPopupContent(
              "Do you want to remove this audio file from this movie?"
            );
          },
        }
      );

      setFormStructure([...temp]);
    } else {
      setOpenAdError(true);
      setPopupContent(
        "You can add max " + language?.data.length + " audio file in a movie"
      );
    }
  };
  useMemo(() => {
    const temp = formStructure;
    temp[2][0]["handleClick"] = handleAddAudio;
    temp[3][0]["handleClick"] = handleAddSubtitle;
    setFormStructure([...temp])

  }, [language]);
  useMemo(() => {
    if (language?.statuscode == 200) {
      const temp = formStructure;
      temp[0][9]["options"] = language?.data.map(
        (language) => language?.language_name
      );
      temp[3][1]["options"] = language?.data.map(
        (language) => language?.language_name
      );
      temp[2][1]["options"] = language?.data.map(
        (language) => language?.language_name
      );
      setFormStructure([...temp]);
      // const tempFilter = tableData;
      // tempFilter["filterColumn"][0]["options"] = language?.data.map(
      //   (language) => language?.language_name
      // );

      // setTableData({ ...tempFilter });
    }
  }, [language]);
  useMemo(() => {
    if (genre?.statuscode == 200) {
      const temp = formStructure;
      temp[0][10]["options"] = genre?.data.map((genre) => genre?.genre_title);
      setFormStructure([...temp])

    }
  }, [genre]);
  useMemo(() => {
    const temp = formStructure;
    if (form?.category_type == "Web Series") {
      temp[0][5]["display"] = "block";
      temp[0][6]["display"] = "block";
      temp[0][7]["display"] = "block";
      temp[0][4]["display"] = "none";
      setFormStructure([...temp])

    } else {
      temp[0][5]["display"] = "none";
      temp[0][6]["display"] = "none";
      temp[0][7]["display"] = "none";
      temp[0][4]["display"] = "block";
      setFormStructure([...temp])
    }
  }, [form?.category_type]);
  useMemo(()=>{
    const temp = formStructure;
    if(form?.movie_released_status == "Released"){
      temp[1][7]["display"] = "block"
      temp[1][8]["display"] = "none"
      temp[1][9]["display"] = "none"

    } else{
      temp[1][7]["display"] = "none"
      temp[1][8]["display"] = "block"
      temp[1][9]["display"] = "block"
    }
  },[form?.movie_released_status])
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData();
    Object.keys(form).map((key) => data.append(key, form?.[key]));
    dispatch(film_festival_movie_form_create(data));
    // console.log(form);
  };
  useMemo(() => {
    if (message?.statuscode == 200 ) {  
      setTimeout(() => {
       
        navigate("/Digital_Submission_form/Success", { state: { view: "view_all" } });
           }, 900);
     
  }
  }, [message]);
  useMemo(() => {
    if (result) {
      const temp = formStructure;
      if (subtitlePosition != undefined) {
        temp[3].map((value, index) => {
          if (value.position == subtitlePosition) {
            const tempForm = form;
            tempForm["subtitle_language_" + value.position] = "";
            tempForm["subtitle_file_" + value.position] = "";

            setForm({ ...tempForm });
            temp[3].splice(index - 2, 3);
          }
        });
        setFormStructure([...temp]);
        setSubtitlePosition(undefined);

        // dispatch(subtitle_delete({ id: subTitleID, video_type: "Movie" }));
        // console.log(form, "idssss");
      } else if (audioPosition != undefined) {
        temp[2].map((value, index) => {
          if (value.position == audioPosition) {
            // console.log(value.position, "8888");
            const tempForm = form;

            tempForm["audio_file_" + value.position] = "";
            tempForm["audio_language_" + value.position] = "";

            setForm({ ...tempForm });
            temp[2].splice(index - 2, 3);
          }
        });
        setFormStructure([...temp]);
        setAudioPosition(undefined);
        // dispatch(audio_file_delete({ id: audioFileId, video_type: "Movie" }));
      }
    }
    setResult(undefined);
  }, [result]);

  const handleClose = () => {
    setOpenAdError(false);
  };
  useEffect(() => {
    const temp = formStructure;

    temp[3][0]["handleClick"] = handleAddSubtitle;
    temp[2][0]["handleClick"] = handleAddAudio;
    setFormStructure([...temp]);
  }, []);
  const subtitles = language?.data?.map((ele, index) =>
    eval("form?.subtitle_language_" + index)
  );
  const audio_language = language?.data?.map((ele, index) =>
    eval("form?.audio_language_" + index)
  );
  useMemo(() => {
    const newLanguageList = language?.data
      ?.map(
        (ele, index) =>
          !subtitles.includes(ele?.language_name) && ele?.language_name
      )
      .filter((e) => e);
    const newAudioLanguage = language?.data
      ?.map(
        (ele, index) =>
          !audio_language.includes(ele?.language_name) && ele?.language_name
      )
      .filter((e) => e);
    const temp = formStructure;
    temp[3].map(
      (ele, index) =>
        ele.type == "select" && (temp[3][index]["options"] = newLanguageList)
    );
    temp[2].map(
      (ele, index) =>
        ele.type == "select" && (temp[2][index]["options"] = newAudioLanguage)
    );

    setFormStructure([...temp]);
  }, [form]);

  return (
    <>
      <Popup
        open={open}
        setOpen={setOpen}
        content={content}
        setResult={setResult}
      />
      <MultiStepForm
        formStructure={formStructure}
        handleSubmit={handleSubmit}
        formTitle={formTitle}
        key={"Form"}
        setForm={setForm}
        isProducerForm
        isFilmFestivalForm
        form={form}
        //   tableData={tableData}
        //   setTableData={setTableData}
        //   isEdit={isEdit}
        //   setIsEdit={setIsEdit}
      />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openAdError}
        autoHideDuration={1500}
        onClose={handleClose}
      >
        <Alert severity="info" variant="filled" color="success">
          {content}
        </Alert>
      </Snackbar>
    </>
  );
};

export default FilmFestivalForm;
