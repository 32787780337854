import React from "react";
import { useState, useEffect, useMemo } from "react";
import ListTable from "../utils/Table";
import { all_successfull_transaction } from "./../../actions/transaction";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { all_country_list } from "./../../actions/Masters/country";
import { movie_name_listing } from "./../../actions/Movie/movie";
import { series_name_listing } from "./../../actions/WebSeries/series";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Export from "./../utils/Export";
export default function AllTransaction() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isEdit, setIsEdit] = useState(false);
  const [totalAmount, setTotelAmount] = useState("");
  const [filter, setFilter] = useState("");
  const [open, setOpen] = useState(false);

  const [form, setForm] = useState({});

  const [tableData, setTableData] = useState({
    tableTitle: "All Transactions",
    disableDelete: true,
    column_sum: {
      name: "payment_amount",
      title: "Total Amount",
    },
    tableHead: [
      {
        id: "name1",
        label: "Name",
        // link: "/Customer/CustomerDetail/CustomerDetail",
        color: "var(--gradientColor2)",
      },
      {
        id: "email",
        label: "Customer Info",
        subText: "mobile_no",
      },
      {
        id:"movie_series_name",
        label:"Movie/Series"
      },
      // {
      //   id: "plan",
      //   label: "Plan",
      //   isSpecial: true,
      //   align: "left",
      // },
      {
        id: "payment_amount1",
        label: "Amount",
        isSpecial: true,
        align: "left",
      },
      {
        id: "payment_id",
        label: "Payment ID",
        // subText: "payment_method"
      },
      {
        id: "date",
        label: "Date",
      },

      {
        id: "device_type",
        label: "Device Type",
        subText: "device_id",
      },

      // {
      // 	id: "payment_status",
      // 	label: "Status",
      // 	isButtonDisplay: true
      // },
    ],
    tableBody: [],
    filterColumn: [
        {
          id: "1",
          title: "Currency",
          name: "currency",
          // displayOn: "category_type",
          // // value: totalAmount,
          // default: location?.state,
          options: ["Failed", "Success"],
        },
        {
          id: "2",
          title: "Movie",
          name: "movie",
          options: ["Failed", "Success"],
        },
        {
          id: "3",
          title: "Series",
          name: "series",
          options: ["Failed", "Success"],
        },
    ],
    isDateRangeFilter: "date",
  });

  const transactions = useSelector(
    (state) => state?.transactions?.alltransactionslist
  );
  // console.log(transactions, "tttt");
  // console.log(tableData.filterColumn[0]?.value,"oooppp")
  useEffect(() => {
    dispatch(all_successfull_transaction());
    dispatch(all_country_list());
    dispatch(movie_name_listing())
    dispatch(series_name_listing())
  }, []);
  const country = useSelector((state) => state?.masters?.country);
  const movie_name = useSelector((state) => state?.movies?.movie_name);
  const webseries = useSelector((state) => state?.webseries?.series_name);
  console.log(webseries , "newMovir")
  useMemo(()=>{
    if(country?.statuscode == 200){

      const tempFilter = tableData;
      tempFilter["filterColumn"][0]["options"] = country?.data.map(
        (country) => country?.currency
      );
  
      setTableData({ ...tempFilter });
    }
  },[country])
  useMemo(()=>{
    if(movie_name?.statuscode == 200){
      const tempFilter = tableData;
      tempFilter["filterColumn"][1]["options"] = movie_name?.data?.map(
        (movie) => movie?.movie_name
      );
  
      setTableData({ ...tempFilter });
    }
  },[movie_name])
  useMemo(()=>{
    if(webseries?.statuscode == 200){
      const tempFilter = tableData;
      tempFilter["filterColumn"][2]["options"] = webseries?.data?.map(
        (series) => series?.series_name
      );
  
      setTableData({ ...tempFilter });
    }
  },[webseries])
  const handleClose = () => {
    setOpen(false);
  };
  useMemo(() => {
    if (transactions?.statuscode == 200) {
      const temp = tableData;
      temp.tableBody = transactions?.data.map((ele) => ({
        ...ele,
        id: ele?.user?.id,
        payment_status: ele?.status,
        deviceID: ele?.user?.deviceId,
        mobile_no: ele?.mobile_no != null ? ele?.mobile_no : " - ",
        device_type : ele?.device_type ? ele?.device_type : "Web" ,
        device_id : ele?.device_id ? ele?.device_id : "-" ,
        payment_amount: parseFloat(ele?.payment_amount).toFixed(2),
        payment_amount1 : ele?.currency !== "INR" ? <><p>{ele?.amount + " " + ele?.currency}</p><p>({ele?.payment_amount + " " + "INR"})</p></> : <p>{ele?.payment_amount+ " " + ele?.currency}</p>,
        movie_series_name : ele?.movie ? ele?.movie?.movie_name : ele?.series?.series_name,
        series : ele?.series?.series_name,
        movie: ele?.movie?.movie_name,
        name1:
          ele?.name !== "-" ? (
            <Link
              to="/Customer/CustomerDetail/CustomerDetail"
              state={{ id: ele?.user_id }}
            >
              <p style={{ fontWeight: "700", color: "black" }}>{ele?.name}</p>
            </Link>
          ) : (
            <>
              <p
                onClick={() => setOpen(true)}
                style={{ fontWeight: "700", color: "red" }}
              >
                User deleted
              </p>
            </>
          ),
      }));

      //   temp.filterColumn[0]["options"] = [
      //     ...new Set(
      //       transactions?.data.map((ele) =>
      //         ele.plan?.status === "Active" ? ele?.plan?.plan_name : ""
      //       )
      //     ),
      //   ];

      setTableData({ ...temp });
    }
  }, [transactions]);

  const user = useSelector((state) => state.layout.profile);
  const movies = useSelector((state) => state.movies.movies);
  const series = useSelector((state) => state.webseries.series);

  //   useEffect(() => {
  //     const data = new FormData();
  //     data.append("id", user?.id);
  //     dispatch(all_series_list(data));
  // 	dispatch(all_movie_list(data));
  //   }, [user]);
  // console.log( tableData.filterColumn["category_type"],"159159")
  //   useMemo(() => {
  // 	const temp = tableData;
  // if(temp.filterColumn[0]["value"] ="Video" )
  // 	temp.filterColumn[0]["options"]
  //   }, []);

  return (
    <>
      <Export
        fileName={"All Transactions"}
        exportData={tableData?.exportData || tableData?.tableBody}
        headings={tableData.tableHead.map((value) => value.label)}
      />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert severity="info" variant="filled" color="success">
          You can not view user details because the user is already deleted
        </Alert>
      </Snackbar>

      <ListTable
        tableData={tableData}
        key={"ListTable"}
        setTableData={setTableData}
        setForm={setForm}
        setIsEdit={setIsEdit}
      />
    </>
  );
}
