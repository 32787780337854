import React from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";

import "./ProductDetailsContent.css";
import { IMAGE } from "./../../api/index";
import { Box } from "@mui/material";
const ProductProfile = ({ data, path , download }) => {
  // console.log(data,"pparr")
  return (
    <>
      <Card
        sx={{
          textAlign: "center",
          boxShadow: "none",
          borderRadius: "10px",
          p: "15px 15px",
          mb: "15px",
          // backgroundColor:"var(--themeColor)"
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-evenly",height:"200px" }}>
        { path =="MovieDetails" &&  <img
							src={IMAGE+data?.movie_thumbnail}
							alt="Member"
							height="180px"
							style={{ borderRadius: "10px" }}
						/>
}
          <img
            src={
              IMAGE +
              (path == "MovieDetails"
                ? data?.movie_poster
                : data?.episode_poster)
            }
            alt="Image"
            height="180px"
            style={{ borderRadius: "10px" }}
          />
        </div>
        <Typography
          as="h4"
          sx={{
            fontSize: 16,
            fontWeight: 500,
            mt: "15px",
          }}
        >
          {path == "MovieDetails"
            ? data?.movie_name.length <= 50
              ? data?.movie_name
              : data?.movie_name.substring(0, 50) + "..."
            : data?.episode_title}
        </Typography>
     
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              textAlign: "center",
              mt: "17px",
            }}
          >
            <Box>
              <Typography color="#A9A9C8" fontSize="13px">
                Downloads
              </Typography>
              <Typography fontWeight="500" fontSize="16px">
              {path == "MovieDetails" ?data?.total_downloads :download }  
              </Typography>
            </Box>

            <Box>
              <Typography color="#A9A9C8" fontSize="13px">
                Views
              </Typography>
              <Typography fontWeight="500" fontSize="16px">
                {path == "MovieDetails" ? data?.movieViews : data?.episodeViews}
              </Typography>
            </Box>
          </Box>
     
      </Card>
    </>
  );
};

export default ProductProfile;
