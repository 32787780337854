import * as actionType from "../constants/actionTypes";

const filmFestivalReducer = (state = { producerData: null }, action) => {
  switch (action.type) {
 
      case actionType.FILMFESTIVALFORMDATA:
        return { ...state, film_festival_data: action.payload };
        case actionType.FILMFESTIVALFORMDETAILS : 
        return {...state,formdetail:action.payload}

    default:
      return state;
  }
};

export default filmFestivalReducer;
