import { BANKDETAILCREATE,BANKDETAILS ,BANKDETAILSLIST } from '../constants/actionTypes'
import * as api from '../api/index.js';




  export const get_bank_detail = (formData) => async (dispatch) => {
    try {
      const { data } = await api.get_bank_detail(formData);
      dispatch({ type: BANKDETAILS, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };
  export const add_bank_account = (formData) => async (dispatch) => {
    try {
      const  { data } = await api.add_bank_account(formData);
      dispatch({type: BANKDETAILCREATE, payload:data });
      return data
    }catch(error){
      console.log(error);
    }
  }
  export const bank_detail_list = (formData) => async (dispatch) => {
    try{
      const { data } = await api.bank_detail_list(formData);
      dispatch({type:BANKDETAILSLIST , payload : data});
      return data
    }catch(error){
      console.log(error)
    }
  }