import React, { useState, useMemo } from "react";
import LeftSidebar from "./LeftSidebar";
import TopNavbar from "./TopNavbar";
import BreadCrumb from "./BreadCrumb"
import ScrollToTop from "./ScrollToTop";
import { useSelector, useDispatch } from "react-redux"
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Footer from './Footer'
import TopSidebar from "./TopSidebar";
import { useLocation, useNavigate } from "react-router-dom";
import { MESSAGE } from './../../constants/actionTypes';

const Layout = ({ children }) => {

  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [active, setActive] = useState(true);

  const toogleActive = () => {
    setActive(!active);
  };
  const message = useSelector((state) => state.layout.message);
  const right = useSelector((state) => state.layout.right);
  const role = useSelector((state) => state.layout.role);

// console.log(right,"newRIGHT")
const [open, setOpen] = useState(false);
const [show, setShow] = useState(false);
  useMemo(() => {

    if (message?.message != undefined) {

      setOpen(true)

    }
    if (location?.state?.forceShow && message?.message != "" && message?.message != undefined) {

      setOpen(true)

    }
  }, [message?.message])
  const handleClose = () => {


    setOpen(false);

    dispatch({ type: MESSAGE, payload: undefined })
  };
  const isLoggedIn = useSelector((state) => state.layout.loggedin)
  if (isLoggedIn == true)
    return (
      <>


        <div className={`main-wrapper-content ${active && "active"}`}>

          <>
            <TopNavbar toogleActive={toogleActive} active={active} showToolTip={role != "Distributor"} />
            {active&&<TopSidebar/>}
            <BreadCrumb />
            <LeftSidebar toogleActive={toogleActive} />
          </>


          <div className="main-content" style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "85vh",
            margin: "0"
          }}>
            <div style={{ flex: "1" }}>{children}</div>


            <Footer />
          </div>

        </div>


        <ScrollToTop />

        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}


        >
          <Alert severity="info" variant="filled" color="success">{message?.message}</Alert>
        </Snackbar>

      </>
    );
  else
    navigate("/Authentication")
};

export default Layout;
