import React from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import { useSelector } from "react-redux";
export const FormStructure = (isEdit) => {
  const role = useSelector((state) => state.layout.role);
  return [
    [
      {
        id: "1",
        type: isEdit ? "inputBox" : "select",
        title: "Ownership",
        name: "series_ownership",
        options: ["In house", "Collaborator"],
        required: true,
        disabled: isEdit ? true : false,
        // display: role == "Producer" ? "none" : "block"
      },
      {
        id: "2",
        type: isEdit ? "inputBox" : "select",
        title: "Select Collaborator",
        name: "distributor",
        disabled: isEdit ? true : false,
        options: ["Later", "Producer"],
        // display: "none"
      },
      {
        id: "3",
        type: isEdit ? "inputBox" : "select",
        title: "Series Type",
        name: "series_type",
        options: ["Web Series", "Super Short Series"],
        required: true,
        disabled: isEdit ? true : false,
        // display: role == "Producer" ? "none" : "block"
      },
      {
        id: "4",
        type: "inputBox",
        title: "Show Name",
        name: "series_name",
        maxLength: "25",
        // regex: /^[a-zA-Z0-9\s\&]+$/,
        isCapitalise: true,
        required: true,
      },
      {
        id: "5",
        type: "select",
        title: "Language",
        name: "language",
        options: ["English", "Hindi", "Gujarati"],
        required: true,
      },
      {
        id: "6",
        type: "select_multiple",
        title: "Select Genre",
        name: "genre",
        maxSelections: "6",
        errorText: "You can select max 6 genre at a time",
        options: ["Portrait", "Landscape"],
        required: true,
      },
      {
        id: "7",
        type: "select",
        title: "Series Access",
        options: ["Free", "TVOD"],
        name: "series_access",
        required:true,
        // disabled: true,
      },
      {
        id: "8",
        type: "inputBox",
        size: "6",
        title: "Available For (Days)",
        name: "available_for_days",
        regex: /^[0-9]+$/,
        maxLength: "2",
        display: "none",
        required: true,
      },
      {
        id: "9",
        type: isEdit ? "lockedInput" : "inputBox",
        title: "Trailer URL (.m3u8)",
        endsWith: ".mp4",
        endsWith1: ".mkv",
        endsWith2: ".mov",

        errorText: "File extension should be .mp4 or .mkv or .mov only",
        name: "series_trailer",
        required: true,
      },
      {
        id: "11",
        type: "toggle",
        title: "First Episode Free",
        name: "first_episode_free",
        display: "none",
        size: "6",
        options: [
          { value: "Yes", color: "success" },
          { value: "No", color: "danger" },
        ],
        required: true,
      },
      {
        id: "10",
        type: "duration",
        title: "Free Preview Duration",
        name: "free_preview_duration",
        display: "none",
        size: "6",
      },

      {
        id: "12",
        type: "toggle",
        title: "Status",
        display: isEdit ? "block" : "none",
        name: "status",
        default: "Active",
        size: "6",
        options: [
          { value: "Active", color: "success" },
          { value: "Inactive", color: "danger" },
        ],
      },
      {
        id: "9",
        type: isEdit ? "lockedInput" : "inputBox",
        title: "Free preview",
        endsWith: ".mp4",
        endsWith1: ".mkv",
        endsWith2: ".mov",

        errorText: "File extension should be .mp4 or .mkv or .mov only",
        name: "free_preview",
        required: true,
      },
      {
        id: "13",
        type: "inputBox",
        title: "Description",
        name: "series_description",
        maxLength: "1000",
        size: "12",
        isCapitalise: true,
        required: true,
      },
      {
        id: "14",
        type: "image",
        title: "Browse Thumbnail",
        subtitle: "(Resolution : 1920px x 720px)",
        subsubtitle: "Max File Size 1MB",
        subsubsubtitle: "Support only JPG,PNG,JPEG",
        name: "series_thumbnail",
        required: true,
      },
      {
        id: "15",
        type: "image",
        title: "Browse Poster",
        subtitle: "(Resolution : 1920px x 1080px)",
        subsubtitle: "Max File Size 1MB",
        subsubsubtitle: "Support only JPG,PNG,JPEG",
        size: "4",
        name: "series_poster",
        required: true,
      },
      {
        id: "16",
        type: "image",
        title: "Series Scene",
        subtitle: "(Resolution : 1080px x 1080px)",
        subsubtitle: "Max File Size 1MB",
        subsubsubtitle: "Support only JPG,PNG,JPEG",
        size: "4",
        name: "series_scene",
        required: true,
      },
      {
        id: "17",
        type: "button",
        title: "Create",
      },
    ],
    [
      {
        id: "1",
        type: "button",
        title: <AddIcon sx={{ color: "#fff !important" }} />,
        align: "left",
        forceShow: true,
      },
      {
        id: "2",
        type: "inputBox",
        title: "United States",
        size: "3",
        name: "country0",
        // options: ["United States"],

        disabled: true,

        // options: ["Advertise 1", "Advertise 2"]
      },
      {
        id: "3",
        type: "inputBox",
        size: "1.5",
        title: "Rental Price",
        name: "rental_price0",
        required: true,
      },
      {
        id: "4",
        type: "inputBox",
        //  variant: "number",
        title: "Country Tax",
        size: "2",
        name: "co_tax0",
        disabled: true,
        // display: "none",
        required: true,
      },

      {
        id: "5",
        type: "inputBox",
        size: "2",
        // regex: /^[0-9\.]+$/,
        title: "Total Tax",
        maxLength: "5",
        name: "tax0",
        disabled: true,
        required: true,
      },
      {
        id: "6",
        type: "inputBox",
        variant: "number",
        size: "2.5",
        title: "Amount After Deduction",
        name: "amount_after_deduction0",
        disabled: true,
        required: true,
      },
      {
        id: "7",
        type: "select",
        size: "3",
        title: "Select Country",
        name: "country1",
        options: ["1", "2"],
      },
      {
        id: "8",
        type: "inputBox",
        size: "1.5",
        name: "rental_price1",
        title: "Rental Price",
        required: true,
      },
      {
        id: "9",
        type: "inputBox",
        // variant: "number",
        title: "Country Tax",
        size: "2",
        name: "co_tax1",
        disabled: true,

        required: true,
      },

      {
        id: "10",
        type: "inputBox",
        size: "2",
        // regex: /^[0-9\.]+$/,
        maxLength: "5",
        title: "Total Tax",
        name: "tax1",
        required: true,
        disabled: true,
      },
      {
        id: "11",
        type: "inputBox",
        size: "2.5",
        variant: "number",
        title: "Amount After Deduction",
        name: "amount_after_deduction1",
        disabled: true,
        required: true,
      },

      {
        id: "12",
        type: "button",
        title: <RemoveIcon sx={{ color: "#fff !important" }} />,
        align: "right",
        size: ".5",
        padding: "2px 2px",
      },
    ],
  ];
};
