import { Routes, Route  } from "react-router-dom";
import Layout from "../components/Layout/Layout";

import Producer from "../components/Distributors/Producer";

function DistributorsModule() {
  return (
    <div className="App">
      <Routes>
        <Route
          path="/Collaborator"
          element={<Layout children={Producer()} />}
        />
        <Route
          path="/CreateCollaborator"
          element={<Layout children={Producer()} />}
        />
        <Route
          path="/EditCollaborator"
          element={<Layout children={Producer()} />}
        />
        {/* < Route path='/CustomerDetail/*' element={<CustomerDetail key="CustomerDetail"/>}/> */}
      </Routes>
    </div>
  );
}

export default DistributorsModule;
