

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from '../components/Layout/Layout';
import Series from "../components/WebSeries/Series/Series"
// import Episode from '../components/WebSeries/Episode/Episode';
import WBEpisode from '../components/WebSeries/WBEpisode/WBEpisode';
import ProductDetails from "../components/ProductDetails/ProductDetailsContent"
import Season from '../components/WebSeries/Season/Season';
import SSEpisode from '../components/WebSeries/SSEpisode/SSEpisode';
function WebSeriesModule() {
  return (
    <div className="App">
    
<Routes>
{/* < Route path='/Series' element={<Layout children={Series()}/>}/>
< Route path='/CreateSeries' element={<Layout children={Series()}/>}/>
< Route path='/EditSeries' element={<Layout children={Series()}/>}/> */}

{/* <Route path='/Season' element={<Layout children={Season()}/>}/>
<Route path='/CreateSeason' element={<Layout children={Season()}/>}/>
<Route path='/EditSeason' element={<Layout children={Season()}/>}/> */}


{/* < Route path='/WebEpisode' element={<Layout children={WBEpisode()}/>}/>
< Route path='/CreateWebEpisode' element={<Layout children={WBEpisode()}/>}/>
< Route path='/EditWebEpisode' element={<Layout children={WBEpisode()}/>}/> */}

{/* < Route path='/ShortEpisode' element={<Layout children={SSEpisode()}/>}/>
< Route path='/CreateShortEpisode' element={<Layout children={SSEpisode()}/>}/>
< Route path='/EditShortEpisode' element={<Layout children={SSEpisode()}/>}/> */}

{/* < Route path='/WebEpisode/EpisodeDetails' element={<Layout children={ProductDetails()}/>}/> */}
      </Routes>
    </div>
  );
}

export default WebSeriesModule;
