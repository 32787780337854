import React from "react";
import { useState, useMemo, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "../../../styles/PageTitle.module.css";
import ListTable from "../../utils/Table";
import Form from "../../utils/Form";
import { TableData } from "./TableData";
import ViewChange from "../../utils/ViewChange";
import { FormStructure } from "./FormStructure";
import {
  series_create,
  series_delete,
  series_update,
  all_series_list,
} from "../../../actions/WebSeries/series";
import {
  country_price_delete_from_videos
} from "../../../actions/subtitle_delete";
import { all_genre_list } from "../../../actions/Masters/genre";
import RemoveIcon from "@mui/icons-material/Remove";
import { all_language_list } from "../../../actions/Masters/language";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Export from "../../utils/Export";
import Reload from "../../utils/Reload"

import MultiStepForm from "../../utils/MultiStepForm";
import { all_country_list } from "../../../actions/Masters/country";
import Popup from "../../utils/Popup";
import { Alert, Snackbar } from "@mui/material";
import { producer_list_movie } from "../../../actions/producer";
export default function Series() {
  const user = useSelector((state) => state.layout.profile);
  const rights = useSelector((state) => state.layout.right);
  const role = useSelector((state) => state?.layout?.role);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [result, setResult] = useState(undefined);
  const [view, setView] = useState(location?.state?.view || "view_all");
  const [isEdit, setIsEdit] = useState(false);
  const [openAdError, setOpenAdError] = useState(false);
  const [adPosition, setAdPosition] = useState();
  const [countryList, setCountryList] = useState([]);
  const [countryPricePosition, setCountryPricePosition] = useState();
  const [countryPriceID, setCountryPriceID] = useState("");

  const [open, setOpen] = useState(false);
  const [content, setPopupContent] = useState("");
  const [flag, setFlag] = useState(false);

  const tempTableData = {
    ...TableData(),
    deleteRecord: series_delete,
    disableDelete: role !== "Distributor" ? false : true,
    updateRecord: series_update,
    onDeleteText: "Are you sure want to delete?",
    onUpdateText: "Are you Sure?",
  };
  const [tableData, setTableData] = useState({ ...tempTableData });

  const [form, setForm] = useState(
    (location?.state?.form && JSON.parse(location?.state?.form)) || {}
  );
  const tempFormStruct = FormStructure(isEdit);
  const [formStructure, setFormStructure] = useState([...tempFormStruct]);

  const [formTitle, setFormTitle] = useState(
    ["Series Details", "Price Details"].filter((e) => e)
  );
  useMemo(() => {
    if (isEdit) {
      setView("create_new");
    } else {
      setView("view_all");
      setForm({});
    }
    const temp = formStructure;
    tempFormStruct.map((ele, j) => (temp[j] = ele));
    setFormStructure([...temp]);
  }, [isEdit]);
  const path = location?.pathname.split("/")[2];
  useEffect(() => {
    setView(path != "Series" ? "create_new" : "view_all");
    setForm(
      (location?.state?.form && JSON.parse(location?.state?.form)) || {
        first_episode_free: "Yes",
        status: "Active",
      }
    );

    setIsEdit(path == "EditSeries");
  }, [location]);

  const series = useSelector((state) => state.webseries.series);

  // console.log(series, "bfdfdffd");
  useEffect(() => {
    const data = new FormData();
    data.append("id", user?.id);

    dispatch(all_series_list(data));
  }, [user]);

  useMemo(() => {
    if (series?.statuscode == 200) {
      const temp = tableData;
      const TVODdata = [];
      series?.data.map((value) => {
        // console.log(value, "value");
        const tlist = {};
        value?.tvod_list &&
          value?.tvod_list?.map((ele, index) => {
            // console.log(ele?.subtitle_file, ele?.subtitle_language, "idd");
            tlist["country" + index] = ele?.tvod_location;
            tlist["rental_price" + index] = ele?.tvod_location_price;
            tlist["amount_after_deduction" + index] =
              ele?.tvod_location_amount_after_deduction;
            tlist["tax" + index] = ele?.tvod_location_tax;
            tlist["tvod_price_list_" + index] = ele?.id
          });
        TVODdata.push(tlist);
      });
      temp.tableBody = series?.data.map((ele, index) => ({
        ...ele,
        ...TVODdata[index],
        language: ele?.language_name,
        genre: ele?.genre.map((value) => value?.genre_title),
        total_episode1 : `Total Episodes : ${ele?.total_episode}`,
        uploaded_by: ele?.created_by?.firstName,
        distributor:
          ele?.distributor?.name +
          " " +
          "(" +
          ele?.distributor?.company_name +
          ")",
      }));

      setTableData({ ...temp });
    }
  }, [series]);
  useMemo(() => {
    setTableData({ ...tempTableData });
  }, [rights]);
  useMemo(() => {
    if (form?.series_access == "Free") {
      // const temp = formStructure
      // temp[0][7]["display"] = "none"
      // setFormStructure([...temp])
      setFormTitle(
        [
          "Series Details"

          // pricePageShow && "Price Details",
        ].filter((e) => e)
      );
     
    } else {
      // const temp = formStructure
      // temp[0][7]["display"] = "block"
      // setFormStructure([...temp])
      setFormTitle(
        [
          "Series Details", "Price Details"
        ].filter((e) => e)
      );

    }
  }, [form?.series_access, formStructure]);

  useMemo(()=>{
    if(form?.series_access == "TVOD"){
      const temp = formStructure
      temp[0][7]["display"] = "block"
      temp[0][9]["display"] = "block"
      // temp[0][10]["display"] = "block"
      setFormStructure([...temp])
    } else{
      const temp = formStructure
      temp[0][7]["display"] = "none"
      temp[0][9]["display"] = "none"
      // temp[0][10]["display"] = "none"
      setFormStructure([...temp])
    }
  },[form?.series_access])

  useMemo(()=>{
    if(form?.first_episode_free == "No" && form?.series_access == "TVOD"){
      const temp = formStructure
      temp[0][10]["display"] = "block"
      setFormStructure([...temp])
    } else{
      const temp = formStructure
      temp[0][10]["display"] = "none"
      setForm({...form, free_preview_duration :null})
      setFormStructure([...temp])
    }
  },[form?.first_episode_free ,form?.series_access])
  
  useEffect(() => {
    dispatch(all_genre_list());
    dispatch(all_language_list());
    dispatch(all_country_list());
    dispatch(producer_list_movie());
  }, []);
  const genre = useSelector((state) => state.masters.genre);
  const countries = useSelector((state) => state.masters.country);
  const ProList = useSelector((state) => state?.producer.producermovielist);
  const language = useSelector((state) => state.masters.languages);
  const handleClick = () => {
    if (formStructure[1]?.length < countries?.data?.length * 6) {

      // formStructure[3].length - 6 < (language?.data.length * 3)
      const temp = formStructure;
      // const index = temp[1]?.length - 3;
      // console.log(adPosition, "85858585");
      const randomName = formStructure[1]?.length / 6;
      // console.log(countries?.data?.length ,randomName , )
      temp[1].push(
        {
          id: "21",
          type: "select",
          size: "3",
          title: "Select Country",
          name: "country" + randomName,
          options: temp[1][6]["options"],
        },
        {
          id: "24",
          type: "inputBox",
          size: "1.5",
          name: "rental_price" + randomName,
          title: "Rental Price",
          required: true,
        },
        {
          id: "24",
          type: "inputBox",
          // variant: "number",
          title: "Country Tax",
          size: "2",
          name: "co_tax" + randomName,
          disabled: true,

          required: true,
        },

        {
          id: "24",
          type: "inputBox",
          size: "2",
          // regex: /^[0-9\.]+$/,
          maxLength: "5",
          title: "Total Tax",
          name: "tax" + randomName,
          required: true,
          disabled: true,
        },
        {
          id: "24",
          type: "inputBox",
          size: "2.5",
          variant: "number",
          title: "Amount After Deduction",
          name: "amount_after_deduction" + randomName,
          disabled: true,
          required: true,
        },

        {
          id: "23",
          type: "button",
          title: <RemoveIcon sx={{ color: "#fff !important" }} />,

          align: "right",
          size: ".5",
          padding: "2px 2px",
          position: randomName,
          forceShow: true,
          handleClick: (e) => {
            setOpen(true);
            setAdPosition(randomName);
            setCountryPricePosition(randomName)
            setCountryPriceID(form["tvod_price_list_" + randomName])
            setPopupContent(
              "Do you want to remove this Country from this Series?"
            );
          },
        }
      );
      // setForm({
      // 	...form,
      // 	[`amount_received${randomName}`] :
      // 		form?.rental_price1 -
      // 		form?.streaming_fees1 -
      // 		Number(form?.rental_price1) * 0.185,
      // 		tax1: Number(form?.rental_price1) * 0.185,
      // });

      setFormStructure([...temp]);
    } else {
      setOpenAdError(true);
      setPopupContent(
        "You can add max " + countries?.data?.length + " countries in a Series"
      );
    }
  };
  useMemo(() => {
    if (genre?.statuscode == 200) {
      const temp = formStructure;
      temp[0][5]["options"] = genre?.data.map((genre) => genre?.genre_title);

      setFormStructure([...temp]);
    }
  }, [genre, form?.genre]);
  useMemo(() => {
    const temp = formStructure;
    const currentDate = new Date();
    temp[1][6]["options"] = countries?.data
      ?.map((countries) =>  countries?.country_name)
      .filter((e) => e);

    setFormStructure([...temp]);
    // console.log(advertisements, temp, "statuscode")
  }, [countries]);
  useMemo(() => {
    if (isEdit) {
      setView("create_new");
      if (form?.tvod_list?.length >( formStructure?.[1]?.length - 5) / 5)
        form?.tvod_list?.map(
          (ele) =>
            form?.tvod_list?.length >( formStructure?.[1]?.length  - 5)/ 5 &&
            handleClick()
        );
    } else {
      setView("view_all");
      setForm({});
    }
    // const temp = formStructure;
    formStructure.map((arr, i) =>
      arr.map(
        (ele, j) =>
          !["select", "select_multiple"].includes(ele.type) &&
          (formStructure[i][j] = ele)
      )
    );
    
    // temp[1][0]["handleClick"] = handleClick;
    // // temp[3][4]["handleClick"] = handleAddSubtitle;

    // setFormStructure([...temp]);
  }, [isEdit, countries, location]);
  useEffect(()=>{
    const temp = formStructure;
    temp[1][0]["handleClick"] = handleClick;
    setFormStructure([...temp]);

  },[isEdit, countries, location])
  useMemo(() => {
    if (language?.statuscode == 200) {
      const temp = formStructure;
      temp[0][4]["options"] = language?.data.map(
        (language) => language?.language_name
      );

      setFormStructure([...temp]);
    }
    if (language?.statuscode == 200) {
      const tempTableData = tableData;
      tempTableData.filterColumn[0]["options"] = language?.data.map(
        (language) => language?.language_name
      );

      setTableData({ ...tempTableData });
    }
  }, [language, form?.language]);
  useEffect(() => {
    const temp = formStructure;
    temp[1][0]["handleClick"] = handleClick;
 
    setFormStructure([...temp]);
  }, []);
  const message = useSelector((state) => state.layout.message);
  // console.log(isEdit,"popop")
  useMemo(() => {
    if (form?.series_ownership == "Collaborator") {
      const temp = formStructure;
      temp[0][1]["display"] = "block";
      setFormStructure([...temp]);
    } else {
      const temp = formStructure;
      temp[0][1]["display"] = "none";
      setFormStructure([...temp]);
    }
  }, [form?.series_ownership]);

  useMemo(() => {
    if (ProList?.statuscode == 200) {
      const temp = formStructure;
      temp[0][1]["options"] = ProList?.data?.map((ele) => ele?.name);
      setFormStructure([...temp]);
    }
  }, [ProList]);
  useMemo(()=>{
    if(form?.rental_price0){
      const temp = form
      temp["country0"] = "United States"
      // temp["co_tax0"] =  countries?.data
      // ?.map(
      //   (ele, index) =>
      //     form?.["country0"] == ele?.country_name &&
      //     ele?.country_tax
      // )
      // .filter((e) => e);
      // temp["amount_after_deduction0"] = (
      //   form?.["rental_price0"] -
      //   Number(form?.["rental_price0"]) *
      //     Number(form?.["co_tax0"] / 100)
      // ).toFixed(2);
      // temp["tax0"] = (
      //   Number(form?.["rental_price0"]) *
      //   Number(form?.["co_tax0"] / 100)
      // ).toFixed(2);
      setForm({...form})
    }
  },[form?.rental_price0 ,countries])

  useMemo(() => {
    if (result) {
      const temp = formStructure;
      if (countryPricePosition != undefined) {
        temp[1].map((value, index) => {
          if (value.position == countryPricePosition) {
            const tempForm = form;
            tempForm["country" + value.position] = "";
            tempForm["rental_price" + value.position] = "";
            tempForm["co_tax" + value.position] = "";
            tempForm["tax" + value.position] = "";
            tempForm["amount_after_deduction" + value.position] = "";
            setForm({ ...tempForm });
            temp[1].splice(index - 5, 6);
          }
        });
        setFormStructure([...temp]);
        setAdPosition(undefined);
        dispatch(country_price_delete_from_videos({ id: countryPriceID, video_type: "Series" }));
      }
    }
    setResult(undefined);
  }, [result]);
  const handleClose = () => {
    setOpenAdError(false);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const temp_movie_category = form;
    temp_movie_category["tempgenre"] = form?.["genre"];
    temp_movie_category["tempdistributor"] = form?.["distributor"];
    temp_movie_category["templanguage"] = form?.["language"];
    temp_movie_category["distributor"] = ProList?.data
      .map((option) => form["distributor"]?.includes(option?.name) && option.id)
      .filter((e) => e);
    temp_movie_category["genre"] = genre?.data
      .map(
        (option) => form?.["genre"]?.includes(option.genre_title) && option.id
      )
      .filter((e) => e);
    temp_movie_category["language"] = language?.data
      .map(
        (option) =>
          form?.["language"]?.includes(option.language_name) && option.id
      )
      .filter((e) => e);
    temp_movie_category["created_by"] = user?.id;
    setForm({
      ...temp_movie_category,
    });

    const data = new FormData();
    Object.keys(form).map((key) => data.append(key, form?.[key]));
    data.append("user", user?.id);
    formStructure.map((element) => {
      if (
        element.type == "image" &&
        form?.[element.name] &&
        typeof form?.[element.name] != "string"
      ) {
        const temp = form;
        temp["temp" + element.name] = form?.[element.name];
        temp[element.name] = URL.createObjectURL(form?.[element.name]);

        setForm({
          ...temp,
        });
      }
    });

    if (isEdit) {
      dispatch(series_update(data));
    } else {
      dispatch(series_create(data));
    }
    setFlag(true);
  };
  useMemo(() => {
    if (message?.statuscode == 200) {
      const temp = tableData;
      if (isEdit) {
        temp.tableBody.map(
          (value, index) =>
            value.id == form.id && (temp.tableBody[index] = { ...form })
        );
      } else {
        temp.tableBody[temp.tableBody?.length] = {
          id: temp.tableBody?.length,
          ...form,
          edit: temp.tableBody?.length,
        };
      }
      setTableData({ ...temp });
      setIsEdit(false);
      setForm({});
      setFlag(false);
      setTimeout(() => {
        const data = new FormData();
        data.append("id", user?.id);

        dispatch(all_series_list(data));
        navigate("/Series/Series/", { state: { view: "view_all" } });
        setView("view_all");
      }, 1000);
    } else {
      const tempForm = form;
      tempForm["series_thumbnail"] = form?.tempseries_thumbnail;
      tempForm["series_poster"] = form?.tempseries_poster;
      tempForm["language"] = form?.templanguage;
      tempForm["genre"] = form?.tempgenre;
      tempForm["distributor"] = form?.tempdistributor;
      setForm({ ...tempForm });
    }
  }, [message]);



  const country_list = countries?.data?.map((ele, index) => 
    eval("form?.country" + index)
  );
  var dependancy_list = countries?.data?.map(
    (ele, index) => form?.["rental_price" + index]
  );

  useMemo(() => {
    dependancy_list = countries?.data?.map(
      (ele, index) => form?.["rental_price" + index]
    );
    const newCountryList = countries?.data
      ?.map(
        (ele, index) => 
          !country_list.includes(ele?.country_name) && ele?.country_name
      )
      .filter((e) => e);
    const temp = formStructure;
    temp[1].map(
      (ele, index) =>
        ele.type == "select"  && (temp[1][index]["options"] = newCountryList)
    );
    setFormStructure([...temp]);
  }, [form]);
  const tex_dependancy_list = countries?.data?.map(
    (ele, index) => form?.["country" + index]
  );

  useMemo(() => {
    // console.log(form,"fdfdfsdffsdf")
    var path = 0;
    if (countries?.statuscode == 200) {
      Object.keys(form).map((key) => {
        if (key.includes("country")) {
          const temp = form;
          temp["co_tax" + path] = countries?.data
            ?.map(
              (ele, index) =>
                form?.["country" + path] == ele?.country_name &&
                ele?.country_tax
            )
            .filter((e) => e);
          
          // temp["tax" + path] =
          //   Number(form?.["rental_price" + path]) *
          //   Number(temp?.["co_tax" + path] / 100).toFixed(2);
          // temp["amount_after_deduction" + path] = (
          //   form?.["rental_price" + path] -
          //   Number(form?.["rental_price" + path]) *
          //     Number(temp?.["co_tax" + path] / 100)
          // ).toFixed(2);

          setForm({ ...temp });
          

          path += 1;
        }
      });
    }
  }, tex_dependancy_list);
  useMemo(() => {
    var i = 0;
    // setTimeout(()=>{

    Object.keys(form).map((key) => {
      if (key.includes("rental_price")) {
        const temp = form;
        temp["amount_after_deduction" + i] = (
          form?.["rental_price" + i] -
          Number(form?.["rental_price" + i]) *
            Number(form?.["co_tax" + i] / 100)
        ).toFixed(2);
        temp["tax" + i] = (
          Number(form?.["rental_price" + i]) *
          Number(form?.["co_tax" + i] / 100)
        ).toFixed(2);

        setForm({ ...temp });
        // console.log(i,form?.["rental_price" + i] ,form?.["co_tax" + i] ,"popopo")

        i += 1;
      }
    });
    // },1000)
  }, dependancy_list);

  return (
    <>
      <Popup
        open={open}
        setOpen={setOpen}
        content={content}
        setResult={setResult}
      />
     { role !== "Distributor" && <ViewChange
        setForm={setForm}
        setView={setView}
        setIsEdit={setIsEdit}
        view={view}
        isEdit={isEdit}
        create_new={"/Series/CreateSeries/"}
        view_all={"/Series/Series/"}
        access={rights?.["Series"]?.["create"] == "true"}
        form={form}
        export_excel={
          <Export
            fileName={"Series"}
            isClubed={true}
            exportData={tableData?.exportData || tableData?.tableBody}
            headings={tableData.tableHead.map((value) => value.label)}
          />
        }
        reload={<Reload isClubed={true} />}
      />}

      {view == "create_new" && (
        <MultiStepForm
          formStructure={formStructure}
          handleSubmit={handleSubmit}
          formTitle={formTitle}
          key={"Form"}
          setForm={setForm}
          form={form}
          tableData={tableData}
          setTableData={setTableData}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
        />
      )}
      {view == "view_all" && (
        <ListTable
          tableData={tableData}
          key={"ListTable"}
          setForm={setForm}
          setTableData={setTableData}
          setIsEdit={setIsEdit}
          create_new={"/Series/EditSeries/"}
        />
      )}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openAdError}
        autoHideDuration={1500}
        onClose={handleClose}
      >
        <Alert severity="info" variant="filled" color="success">
          {content}
        </Alert>
      </Snackbar>
    </>
  );
}
