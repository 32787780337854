import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

export const FormStructure = (isEdit) => {
  return [
    [
      {
        id: "1",
        type: "select",
        title: "Ownership",
        name: "movie_ownership",
        options: ["In house", "Collaborator"],
        required: true,
        // disabled: isEdit ? true : false,
        // display: role == "Producer" ? "none" : "block"
      },
      {
        id: "2",
        display: "none",
        type: "select",
        title: "Select Collaborator",
        name: "distributor",
        options: ["Later", "Producer"],
        disabled: false,
      },
      {
        id: "3",
        display: "none",
        type: "inputBox",
        title: "Compant Name",
        name: "distributor_company",
        options: ["Later", "Producer"],
        disabled: true,
      },
      {
        id: "4",
        type: "select",
        title: "Video Category",
        name: "movie_category",
        options: ["Portrait", "Landscape"],
        required: true,
      },
      {
        id: "5",
        type: "select",
        title: "Sub Category",
        name: "movie_subcategory",
        options: ["Portrait", "Landscape"],
        required: true,
      },
      {
        id: "6",
        type: "select",
        title: "Video Access",
        options: ["Free", "TVOD"],
        name: "movie_access",
        required:true,
        // disabled: true,
      },
      {
        id: "7",
        type: "inputBox",
        title: "Name",
        name: "movie_name",
        maxLength: "100",
        // regex: /^[a-zA-Z0-9\s\&]+$/,
        isCapitalise: true,
        required: true,
      },
      {
        id: "8",
        type: "select",
        title: "Language",
        name: "movie_language",
        options: ["English", "Hindi", "Gujarati"],
        required: true,
      },
      {
        id: "9",
        type: "select_multiple",
        title: "Genre",
        name: "movie_genre",
        maxSelections: "6",
        options: ["English", "Hindi", "Gujarati"],
        errorText: "You can select max 6 genre at a time",
        required: true,
      },

      {
        id: "10",
        type: "select_multiple",
        title: "Select Cast",
        name: "movie_cast",
        maxSelections: "6",
        errorText: "You can select max 6 cast at a time",
        options: ["Portrait", "Landscape"],
        isPlusBtnOpen :true,

        // required: true
      },
      {
        id: "11",
        type: "select",
        title: "Publish Type",
        name: "publish_type",
        options: ["Now", "Later"],
        required: true,
      },
      {
        id: "12",
        type: "inputBox",
        variant: "time",
        title: "Publish Time",
        name: "publish_time",
        display: "none",
        required: true,
      },
      {
        id: "13",
        type: "inputBox",
        variant: "date",
        title: "Publish Date",
        min: new Date().toISOString().split("T")[0],
        name: "release_date",
        display: "none",
        required: true,
      },
      {
        id: "14",
        type: "inputBox",
        size: "6",
        title: "Available For (Days)",
        name: "available_for_days",
        regex: /^[0-9]+$/,
        maxLength: "2",
        required: true,
      },

      {
        id: "15",
        type: "inputBox",
        title: "Description",
        name: "movie_description",
        maxLength: "1000",
        size: "12",
        isCapitalise: true,
        required: true,
      },
    ],
    [
      // {
      //   id: "15",
      //   type: isEdit ? "lockedInput" : "inputBox",
      //   title: "M3U8 URL ( Resolution : 1080p)",
      //   endsWith: ".m3u8",
      //   errorText: "File extension should be .m3u8 only",
      //   name: "movieLink_1080p",
      //   // disabled:isEdit ? true :false,
      //   required: true,
      // },
      // {
      //   id: "14",
      //   type: isEdit ? "lockedInput" : "inputBox",
      //   title: "M3U8 URL ( Resolution : 720p)",
      //   endsWith: ".m3u8",
      //   errorText: "File extension should be .m3u8 only",
      //   name: "movieLink_720p",
      //   required: true,
      // },
      // {
      //   id: "13",
      //   type: isEdit ? "lockedInput" : "inputBox",
      //   title: "M3U8 URL ( Resolution : 480p)",
      //   endsWith: ".m3u8",
      //   errorText: "File extension should be .m3u8 only",
      //   name: "movieLink_480p",
      //   required: true,
      // },
      // {
      //   id: "12",
      //   type: isEdit ? "lockedInput" : "inputBox",
      //   title: "M3U8 URL ( Resolution: 360p)",
      //   endsWith: ".m3u8",
      //   errorText: "File extension should be .m3u8 only",
      //   name: "movieLink_360p",
      //   required: true,
      // },
      {
        id: "1",
        type: isEdit ? "lockedInput" : "inputBox",
        title: "Upload Movie Mp4 URL",
        endsWith: ".mp4",
        errorText: "File extension should be .mp4 only",
        name: "movieMp4_URL",
        required: true,
      },
      {
        id: "2",
        type: "inputBox",
        title: "Duration",
        name: "movie_duration",
        size: "6",
        disabled: true,
        required: true,
      },
      {
        id: "3",
        type: "duration",
        title: "Free Preview Duration",
        name: "free_preview_duration",
        size: "6",
        // disabled: true,
        required: true
      },
  
      {
        id: "4",
        type: isEdit ? "lockedInput" : "inputBox",
        title: "Free Preview URL",
        endsWith: ".mp4",
        endsWith1: ".mkv",
        endsWith2: ".mov",

        errorText: "File extension should be .mp4 or .mkv or .mov only",
        name: "free_preview",
        required: true,
      },
      {
        id: "4",
        type: isEdit ? "lockedInput" : "inputBox",
        title: "Upload Tailer URL",
        endsWith: ".mp4",
        endsWith1: ".mkv",
        endsWith2: ".mov",
        errorText: "File extension should be .mp4 or .mkv or .mov only",
        name: "movie_trailer",
        required: true,
      },
      
      // {
      //     id: "17",
      //     type: "lockedInput",
      //     variant: "number",
      //     title: "Set Position",
      //     name: "set_position",
      //     min: "0",
      //     max: "99",
      //     required: true,
      //     size: "3",

      // },
      // {
      //     id: "21",
      //     type: "toggle",
      //     title: "Send Notification",
      //     name: "show_notification",
      //     default: "Yes",
      //     size: "3",

      //     options: [{ value: "Yes", color: "success" }, { value: "No", color: "danger" }]

      // },
      // {
      //     id: "22",
      //     type: "toggle",
      //     title: "Allow Download",
      //     name: "allow_download",
      //     default: "Yes",
      //     size: "3",
      //     options: [{ value: "Yes", color: "success" }, { value: "No", color: "danger" }]

      // }, {
      //     id: "33",
      //     name: undefined
      // },
      {
        id: "5",
        type: "undefined",
        size:6,
        // display:"none"
      },
      {
        id: "6",
        type: "image",
        subtitle: "(Resolution : 1920px x 1080px) *",
        subsubtitle: "Max File Size 1MB",
        subsubsubtitle: "Support only JPG,PNG,JPEG",
        title: "Browse Poster",
        name: "movie_poster",
        size: 4,
        required: true,
      },
      {
        id: "7",
        type: "image",
        subtitle: "(Resolution : 1920px x 720px) *",
        subsubtitle: "Max File Size 1MB",
        subsubsubtitle: "Support only JPG,PNG,JPEG",
        title: "Movie Thumbnail",
        name: "movie_thumbnail",
        size: 4,
        required: true,
      },
      {
        id: "8",
        type: "image",
        subtitle: "(Resolution : 1080px x 1080px) *",
        subsubtitle: "Max File Size 1MB",
        subsubsubtitle: "Support only JPG,PNG,JPEG",
        title: "Movie Scene",
        name: "movie_scene",
        size: 4,
        required: true,
      },
    ],
    // [
    //   {
    //     id: "23",
    //     type: "toggle",
    //     title: "Audio Language",
    //     name: "audio_language",
    //     default: "Inactive",
    //     size: "12",
    //     options: [
    //       { value: "Active", color: "success" },
    //       { value: "Inactive", color: "danger" },
    //     ],
    //   },
    //   {
    //     id: "23",
    //     type: "button",
    //     title: <AddIcon sx={{ color: "#fff !important" }} />,
    //     align: "left",
    //     forceShow: true,
    //     display:"none",
    //   },
    //   {
    //     id: "21",
    //     type: "select",
    //     title: "Select Language",
    //     size: "6",
    //     name: "audio_language_0",
    //     options: ["Advertise 1", "Advertise 2"],
    //     display: "none",
    //     required: true,
    //   },
    //   {
    //     id: "19",
    //     type: isEdit ? "lockedInput" : "inputBox",
    //     subtitle: "*",
    //     title: "Upload mp3 file link",
    //     endsWith: ".mp3",
    //     endsWith1: ".wav",
    //     errorText: "File extension should be .mp3 or .wav only",
    //     name: "audio_file_0",
    //     size: "5.5",
    //     required: true,
    //     display: "none",
    //     // {
    //     //     id: "15",

    //     //     title: "M3U8 URL ( Resolution : 1080p)",
    //     //     endsWith: ".m3u8",
    //     //     errorText: "File extension should be .m3u8 only",
    //     //     name: "movieLink_1080p",
    //     //     // disabled:isEdit ? true :false,
    //     //     required: true
    //     // },
    //   },
    // ],
    [
      {
        id: "1",
        type: "button",
        title: <AddIcon sx={{ color: "#fff !important" }} />,
        align: "left",
        forceShow: true,
        // display:"none",
      },
      {
        id: "2",
        type: "select",
        title: "Movie Default Language",
        size: "6",
        name: "movie_language_0",
        options: ["Advertise 1", "Advertise 2"],
        // display: "none",
        disabled : true,
        required: true,
      },
      {
        id: "3",
        type: isEdit ? "lockedInput" : "inputBox",
        subtitle: "*",
        title: "Upload m3u8 file link",
        endsWith: ".m3u8",
        // endsWith1: ".wav",
        errorText: "File extension should be .m3u8 only",
        name: "movie_file_0",
        size: "5.5",
        required: true,
      },
    ],
    [
      {
        id: "21",
        type: "toggle",
        title: "Is This Video 18+ ?",
        name: "movie_is_for18Plus",
        default: "No",
        size: "3",

        options: [
          { value: "Yes", color: "success" },
          { value: "No", color: "danger" },
        ],
      },
      {
        id: "22",
        type: "toggle",
        title: "Allow Download ?",
        name: "allow_download",
        default: "Yes",
        size: "3",
        options: [
          { value: "Yes", color: "success" },
          { value: "No", color: "danger" },
        ],
      },
      // {
      //     id: "22",
      //     type: "toggle",
      //     title: "Allow Download ?",
      //     name: "movie_is_for18Plus",
      //     default: "Yes",
      //     size: "3",
      //     options: [{ value: "Yes", color: "success" }, { value: "No", color: "danger" }],
      // },
      {
        id: "20",
        type: "toggle",
        title: "Subtitles",
        name: "subtitles",
        default: "Inactive",
        size: "3",
        options: [
          { value: "Active", color: "success" },
          { value: "Inactive", color: "danger" },
        ],
      },
      {
        id: "20",
        type: "toggle",
        title: "Status",
        name: "status",
        default: "Active",
        display: isEdit ? "block" : "none",
        size: "3",
        options: [
          { value: "Active", color: "success" },
          { value: "Inactive", color: "danger" },
        ],
      },
      {
        id: "23",
        type: "button",
        title: <AddIcon sx={{ color: "#fff !important" }} />,
        align: "left",
        forceShow: true,
        // display: "none"
      },
      {
        id: "22",
        type: "select",
        title: "Select Language",
        size: "6",
        name: "subtitle_language_0",
        options: ["Advertise 1", "Advertise 2"],
        // display: "none"
      },
      {
        id: "28",
        type: isEdit ? "lockedInput" : "inputBox",
        subtitle: "*",
        title: "Upload Subtitle file Link",
        name: "subtitle_file_0",
        size: "5.5",
        endsWith: ".srt",
        endsWith1: ".asc",

        errorText: "File extension should be .srt or .ASC only",
        // required: true,
        // display: "none",
      },
    ],
    [
      {
        id: "23",
        type: "button",
        title: <AddIcon sx={{ color: "#fff !important" }} />,
        align: "left",
        forceShow: true,
      },
      {
        id: "21",
        type: "inputBox",
        title: "United States",
        size: "3",
        name: "country0",
        // options: ["United States"],

        disabled: true,

        // options: ["Advertise 1", "Advertise 2"]
      },
      {
        id: "24",
        type: "inputBox",
        size: "1.5",
        title: "Rental Price",
        name: "rental_price0",
        regex: /^[0-9\.]+$/,
        required: true,
      },
      {
        id: "24",
        type: "inputBox",
        //  variant: "number",
        title: "Country Tax",
        size: "2",
        name: "co_tax0",
        disabled: true,
        // display: "none",
        required: true,
      },

      {
        id: "24",
        type: "inputBox",
        size: "2",
        // regex: /^[0-9\.]+$/,
        title: "Total Tax",
        maxLength: "5",
        name: "tax0",
        disabled: true,
        required: true,
      },
      {
        id: "24",
        type: "inputBox",
        variant: "number",
        size: "2.5",
        title: "Amount After Deduction",
        name: "amount_after_deduction0",
        disabled: true,
        required: true,
      },
      {
        id: "21",
        type: "select",
        size: "3",
        title: "Select Country",
        name: "country1",
        options: ["1", "2"],
      },
      {
        id: "24",
        type: "inputBox",
        size: "1.5",
        name: "rental_price1",
        title: "Rental Price",
        regex: /^[0-9\.]+$/,

        required: true,
      },
      {
        id: "24",
        type: "inputBox",
        // variant: "number",
        title: "Country Tax",
        size: "2",
        name: "co_tax1",
        disabled: true,

        required: true,
      },

      {
        id: "24",
        type: "inputBox",
        size: "2",
        // regex: /^[0-9\.]+$/,
        maxLength: "5",
        title: "Total Tax",
        name: "tax1",
        required: true,
        disabled: true,
      },
      {
        id: "24",
        type: "inputBox",
        size: "2.5",
        variant: "number",
        title: "Amount After Deduction",
        name: "amount_after_deduction1",
        disabled: true,
        required: true,
      },

      {
        id: "23",
        type: "button",
        title: <RemoveIcon sx={{ color: "#fff !important" }} />,
        align: "right",
        size: ".5",
        padding: "2px 2px",
      },
    ],
  ];
};
