import React from "react";
import { useState, useMemo, useEffect } from "react";
import ListTable from "../utils/Table";
import Form from "../utils/Form";
import {
  subscription_create,
  subscription_delete,
  subscription_update,
  all_subscription_list,
} from "../../actions/subscription";
import { useDispatch, useSelector } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useLocation } from "react-router-dom";
export default function Subscription() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [form, setForm] = useState(
    (location?.state?.form && JSON.parse(location?.state?.form)) || {}
  );
  const [view, setView] = useState(location?.state?.view || "view_all");
  const [content, setPopupContent] = useState("");
  const [openAdError, setOpenAdError] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  useMemo(() => {
    if (isEdit) {
      setView("create_new");
    } else {
      setView("view_all");
      setForm({});
    }
  }, [isEdit]);
  const path = location?.pathname.split("/")[2];
  useEffect(() => {
    setView(path != "Subscriptions" ? "create_new" : "view_all");
    setForm((location?.state?.form && JSON.parse(location?.state?.form)) || {});
    setIsEdit(path == "EditSubscriptions");
  }, [location]);
  const [tableData, setTableData] = useState({
    tableTitle: "Subscriptions",
    updateRecord: subscription_update,
    disableDelete: true,
    customisedStatusUpdateMessage: true,
    onActiveText: "Are you Sure want to Activate this Plan?",
    onInactiveText: "Are you Sure want to Inactivate this Plan?",
    amount: undefined,
    tableHead: [
      {
        id: "plan_name",
        label: "Plan Name",
      },
      {
        id: "duration",
        label: "Duration",
      },
      {
        id: "price",
        label: "Original Price",
      },
      {
        id: "final_amount",
        label: "Payable Price",
      },

      {
        id: "status",
        label: "Status",
      },
      {
        id: "edit",
        label: "Update",
      },
    ],
    tableBody: [],
  });

  const formStructure = [
    {
      id: "1",
      type: "inputBox",
      title: "Plan Name",
      name: "plan_name",
      maxLength: "20",
      regex: /^[a-zA-Z\s]+$/,
      disabled: true,
    },
    {
      id: "2",
      type: "inputBox",
      title: "Duration",
      name: "duration",
      disabled: true,
    },
    {
      id: "3",
      type: "inputBox",
      regex: /^[0-9\.]+$/,
      maxLength: "6",
      title: "original Price",
      name: "price",
    },
    // {
    // 	id: "4",
    // 	type: "select",
    // 	title: "Discount type",
    // 	name: "discount_type",
    // 	options: ["Percentage", "Fixed"],
    // 	required:true
    // },
    {
      id: "6",
      type: "inputBox",
      title: "Discount Amount",
      name: "discount_amount",
      maxLength: "3",
      regex: /^[0-9\.]+$/,
      required: true,
    },
    {
      id: "7",
      type: "inputBox",
      variant: "number",
      title: "Payable Amount (INR)",
      name: "final_amount",
      disabled: true,
      required: true,
    },
    {
      id: "4",
      type: "inputBox",
      variant: "number",
      title: "No. Days",
      name: "no_of_days",
      disabled: true,
    },
    {
      id: "5",
      type: "description",
      title: "Description",
      name: "description",
    },

    {
      id: "7",
      type: "button",
      title: isEdit ? "Edit" : "Create",
      disabled: false,
    },
  ];

  const subscriptions = useSelector(
    (state) => state.subscriptions.subscriptions
  );
  // console.log(subscriptions, "ssssss")
  useEffect(() => {
    dispatch(all_subscription_list());
  }, []);
  useMemo(() => {
    if (subscriptions?.statuscode == 200) {
      const temp = tableData;
      temp.tableBody = subscriptions?.data.map((ele) => ({
        ...ele,
        price: parseFloat(ele.price).toFixed(2),
      }));
      setTableData({ ...temp });
    }
  }, [subscriptions]);
  useMemo(() => {
    const tempFormStructure = formStructure;
    tempFormStructure[4]["maxLength"] = 3;
    // setFormStructure([...tempFormStructure])
    setForm({
      ...form,
      final_amount: (form?.price - form?.discount_amount).toFixed(2),
    });
  }, [form?.discount_amount]);

  //

  useMemo(() => {
    if (form?.final_amount < 0) {
      setOpenAdError(true);
      setPopupContent("Your receivable amount goes into Minus(-)");
      setForm({ ...form, discount_amount: 0 });
    }
    if (form?.final_amount == 0) {
      setOpenAdError(true);
      setPopupContent("Your receivable amount is 0");
      setForm({ ...form, discount_amount: 0 });
    }
  }, [form?.final_amount]);

  const formTitle = isEdit ? "Edit Subscription" : "Create Subscription";
  const message = useSelector((state) => state.layout.message);
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData();
    Object.keys(form).map((key) => data.append(key, form?.[key]));

    if (isEdit) {
      dispatch(subscription_update(data));
    } else {
      dispatch(subscription_create(data));
    }
  };
  useMemo(() => {
    if (message?.statuscode == 200) {
      setIsEdit(false);
      setForm({});
      setTimeout(() => {
        dispatch(all_subscription_list());
      }, 1000);
      setView("view_all");
    } else {
      setForm({ ...form });
    }
  }, [message]);
  const handleClose = () => {
    setOpenAdError(false);
  };
  return (
    <>
      {view == "create_new" && (
        <Form
          formStructure={formStructure}
          formTitle={formTitle}
          key={"Form"}
          handleSubmit={handleSubmit}
          setForm={setForm}
          form={form}
          tableData={tableData}
          setTableData={setTableData}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
        />
      )}
      {view == "view_all" && (
        <ListTable
          tableData={tableData}
          key={"ListTable"}
          setForm={setForm}
          setTableData={setTableData}
          setIsEdit={setIsEdit}
          create_new={"/Subscriptions/EditSubscriptions"}
        />
      )}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openAdError}
        autoHideDuration={1500}
        onClose={handleClose}
      >
        <Alert severity="info" variant="filled" color="success">
          {content}
        </Alert>
      </Snackbar>
    </>
  );
}
