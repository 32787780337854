import React,{useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Features from './Features';
import PersonalInfo from './PersonalInfo'
import MySubscriptions from './MySubscriptions';
import ProfileContent from './ProfileContent';
import { customer_details } from '../../actions/customer';
import { useDispatch,useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";

export default function CustomerDetail() {
  const dispatch=useDispatch()
  const location = useLocation()
  const customer=useSelector((state)=>state.customers.customer)
  // console.log(location,"cccccc")
  useEffect(()=>{
    const id = location.state?.id
    dispatch(customer_details({id:location.state?.id }))
},[location])

  return (
    <>
    

      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 2 }}
      >
        <Grid item xs={12} md={12} lg={12} xl={3}>
      
         
          <ProfileContent data={customer}/>
          <Features data={customer}/>
       
 

          
        </Grid>

        <Grid item xs={12} md={12} lg={12} xl={9}>
         
         

         
          <PersonalInfo data={customer?.data} />
          
          <MySubscriptions data={customer?.transaction_history}/>
        </Grid>
     
      </Grid>
    </>
  );
}
