import React from 'react'
import { useState, useMemo, useEffect } from "react";
import ListTable from "../utils/Table";
import {
	
	all_producer_movie_form_list,
} from "../../actions/producer";
import { useDispatch, useSelector } from "react-redux";


const DistributorsFormData = () => {
    const dispatch = useDispatch();

    const [isEdit, setIsEdit] = useState(false);
	const [form, setForm] = useState({});

    const tempTableData={
		tableTitle: "Video Collections",
		disableDelete: true,	

		tableHead: [
			{
				id: "production_name",
				label: "Production Name",
				subText:"distribution_name",
				link:"/DistributorsFormData/DistributorsFormDetails/DistributorsFormDetails"
			},
			{
				id:"created_at",
				label:"Data"
			},
			{
				id: "type_of_film1",
				label: "Video Title",
				subText:"category_type"
			},
			{
				id: "email",
				label: "Contact info" ,
				subText:"mobileNumber"
			},
			{
				id: "language",
				label: "Language" ,
			},
			{
				id: "rent1",
				label: "Rental Amount" ,
			},
			
			
		],
		tableBody: [
			
		],
		isDateRangeFilter: "created_at",
	}
	const [tableData, setTableData] = useState({...tempTableData});
    const producerformdata = useSelector((state) => state.producer.producerformdata);
    // console.log(producerformdata)
	useEffect(() => {
		dispatch(all_producer_movie_form_list());
	}, []);
		useMemo(() => {
			if (producerformdata?.statuscode == 200) {
				const temp = tableData;
				temp.tableBody = producerformdata?.data.map((ele)=> ({...ele,
					rent1 :parseFloat(ele.rent).toFixed(2) ,
					type_of_film1: ele?.category_type =="Web Series" ? ele?.type_of_series : ele?.type_of_film
				
				}));
				setTableData({ ...temp });
			}
		}, [producerformdata]);
  return (
    <>
			
    { <ListTable
        tableData={tableData}
        key={"ListTable"}
        setForm={setForm}
        setTableData={setTableData}
        setIsEdit={setIsEdit}
    />}

</>
  )
}
// all_producer_movie_form_list
export default DistributorsFormData