import { useSelector } from "react-redux";


export const TableData= () => {
	const rights = useSelector((state) => state.layout.right);
  
  return{
    tableTitle:"Categories",
    onDeleteText:"Are you Sure?",
    tableHead:[
        {
            id: 'category_name',
            label: 'Category',
          },
          {
            id: 'category_image',
            label: 'Image',
            isImage:true
          },
          {
            id: 'sequence',
            label: 'Sequence',
            align:"center"
          },
          {
            id: 'status',
            label: 'Status',
          },
          {
            id: 'edit',
            label: 'Update',
            access: rights?.["Masters"]?.["edit"] == "true"
          },
    ],
    tableBody:[
       
    ]}}