export const CountaryData = [
    {
        countary_name:"United Kingdom",
        currency_Symbol :"£",
        tax:"18.5"
    },
    {
        countary_name:"Europe",
        currency_Symbol :"€",
        tax:"17.5"
    },
    {
        countary_name:"India",
        currency_Symbol :"₹",
        tax:"15.5",
    },
    {
        countary_name:"United States of America",
        currency_Symbol :"$",
        tax:"16.5"
    },
    {
        countary_name:"Singapore",
        currency_Symbol :"$",
        tax:"20"
    },
    {
        countary_name:"United Arab Emirates",
        currency_Symbol :"د.إ",
        tax:"25"
    },
    {
        countary_name:"Canada",
        currency_Symbol :"$",
        tax:"10.5"
    },
    {
        countary_name:"Australia",
        currency_Symbol :"$",
        tax:"27.5"
    },
    
]