import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import poster from "./../../images/poster.jpg";
import "./ProductDetailsContent.css";
import Features from "./Features";
import ProductProfile from "./ProductProfile";
import { movie_details } from "./../../actions/Movie/movie";
import { episode_details } from "./../../actions/WebSeries/episode";
import { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IMAGE } from "./../../api/index";
import WatchedCustomers from "./WatchedCustomers";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { series_details_admin } from "../../actions/WebSeries/series";

const ProductDetailsContent = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const role = useSelector((state) => state.layout.role);
  // const path = location.pathname.split("/").filter((x) => x)[1];
  // console.log(path1,"879665")
  const formData = new FormData();
  formData.append("id", location.state?.id);
  useMemo(() => {
    dispatch(series_details_admin(formData));
  }, [location.state?.id]);
  const data = useSelector((state) => state?.webseries?.series_detail?.data);
  const Total_data = useSelector((state) => state?.webseries?.series_detail);
  const data1 = useSelector(
    (state) => state?.webseries?.series_detail?.data?.data[0]
  );
  const data2 = useSelector(
    (state) => state?.webseries?.series_detail?.data?.data[1]
  );
  const data3 = useSelector(
    (state) => state?.webseries?.series_detail?.data?.data[2]
  );
  const data4 = useSelector(
    (state) => state?.webseries?.series_detail?.data?.data[3]
  );
  const data5 = useSelector(
    (state) => state?.webseries?.series_detail?.data?.data[4]
  );
  const data6 = useSelector(
    (state) => state?.webseries?.series_detail?.data?.data[5]
  );
  const data7 = useSelector(
    (state) => state?.webseries?.series_detail?.data?.data[6]
  );
  const data8 = useSelector(
    (state) => state?.webseries?.series_detail?.data?.data[7]
  );
  const data9 = useSelector(
    (state) => state?.webseries?.series_detail?.data?.data[8]
  );
  const data10 = useSelector(
    (state) => state?.webseries?.series_detail?.data?.data[9]
  );
  const country_price_list = useSelector(
    (state) => state?.webseries?.series_detail?.price_by_country
  );

  // console.log(country_price_list, "45566332");

  const earning = useSelector((state) => state.webseries?.episode);
  // const earning1 =useSelector((state) =>state)

  // const access =
  //   path == "MovieDetails"
  //     ? data?.movie_access
  //     : data?.series_name?.series_type;
  // console.log(data,"110000")S
  return (
    <>
      <Grid
        container
        rowSpacing={1}
        justifyContent="center"
        columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
      >
        <Grid item spacing={4} xs={12} sm={12} md={6} lg={7} xl={7}>
          <Card
            sx={{
              boxShadow: "none",
              borderRadius: "10px",
              p: "15px 25px",
              mb: "15px",
            }}
          >
            <Box mt={2}>
              <Tabs className="product-details-tabs">
                <TabList>
                  <Tab>Series</Tab>
                  <Tab>status </Tab>
                  <Tab>Price</Tab>
                  {/* <Tab>Cast </Tab> */}
                </TabList>

                <TabPanel>
                  <Card
                    sx={{
                      boxShadow: "none",
                      borderRadius: "10px",
                      p: "25px 20px",
                      mb: "10px",
                      backgroundColor: "#F7FAFF",
                    }}
                  >
                    <Typography
                      as="h3"
                      sx={{
                        fontSize: 16,
                        mb: "15px",
                        fontWeight: 500,
                        mt: "-25px",
                        ml: "-25px",
                        mr: "-25px",
                        padding: "10px 25px",
                        borderRadius: "8px 8px 0px 0px",
                        background:
                          "linear-gradient(225deg,  var(--gradientColorLighter1) 0%, var(--gradientColorLighter2) 91.25%)",

                        color: "black",
                      }}
                    >
                      <span style={{ color: "white" }}>Series Details</span>
                    </Typography>

                    <Box>
                      <Typography
                        sx={{
                          fontSize: 13,
                          mt: "10px",
                          display: "flex",
                        }}
                      >
                        <span
                          style={{
                            width: "150px",
                            fontWeight: "500",
                          }}
                          className="mr-10px"
                        >
                          Series Ownership:
                        </span>
                        {data?.series_ownership}
                      </Typography>
                      {data?.series_ownership === "Producer" && (
                        <Typography
                          sx={{
                            fontSize: 13,
                            mt: "10px",
                            display: "flex",
                          }}
                        >
                          <span
                            style={{
                              width: "150px",
                              fontWeight: "500",
                            }}
                            className="mr-10px"
                          >
                            Producer Name:
                          </span>
                          {data?.distributor?.name}
                        </Typography>
                      )}
                      <Typography
                        sx={{
                          fontSize: 13,
                          mt: "10px",
                          display: "flex",
                        }}
                      >
                        <span
                          style={{
                            width: "150px",
                            fontWeight: "500",
                          }}
                          className="mr-10px"
                        >
                          Series Type:
                        </span>
                        {data?.series_type}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 13,
                          mt: "10px",
                          display: "flex",
                        }}
                      >
                        <span
                          style={{
                            width: "150px",
                            fontWeight: "500",
                          }}
                          className="mr-10px"
                        >
                          Language :
                        </span>
                        {data?.language_name}
                      </Typography>
                    </Box>
                  </Card>
                </TabPanel>
                <TabPanel>
                  <Card
                    sx={{
                      boxShadow: "none",
                      borderRadius: "10px",
                      p: "25px 20px",
                      mb: "10px",
                      backgroundColor: "#F7FAFF",
                    }}
                  >
                    <Typography
                      as="h3"
                      sx={{
                        fontSize: 16,
                        fontWeight: 500,
                        mb: "15px",
                        mt: "-25px",
                        ml: "-25px",
                        mr: "-25px",
                        padding: "10px 25px",
                        borderRadius: "8px 8px 0px 0px",
                        background:
                          "linear-gradient(225deg,  var(--gradientColorLighter1) 0%, var(--gradientColorLighter2) 91.25%)",

                        color: "black",
                      }}
                    >
                      <span style={{ color: "white" }}>Status</span>
                    </Typography>

                    <Box>
                      <Typography
                        sx={{
                          fontSize: 13,
                          mt: "10px",
                          display: "flex",
                        }}
                      >
                        <span
                          style={{
                            width: "150px",
                            fontWeight: "500",
                          }}
                          className="mr-10px"
                        >
                          status :
                        </span>
                        {data?.status}
                      </Typography>

                      <Typography
                        sx={{
                          fontSize: 13,
                          mt: "10px",
                          display: "flex",
                        }}
                      >
                        <span
                          style={{
                            width: "150px",
                            fontWeight: "500",
                          }}
                          className="mr-10px"
                        >
                          Available For Days :
                        </span>
                        {data?.available_for_days}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 13,
                          mt: "10px",
                          display: "flex",
                        }}
                      >
                        <span
                          style={{
                            width: "150px",
                            fontWeight: "500",
                          }}
                          className="mr-10px"
                        >
                          First Episode Free ?:
                        </span>
                        {data?.first_episode_free}
                      </Typography>
                    </Box>
                  </Card>
                </TabPanel>

                <TabPanel>
                  <Card
                    sx={{
                      boxShadow: "none",
                      borderRadius: "10px",
                      p: "25px 20px",
                      mb: "10px",
                      backgroundColor: "#F7FAFF",
                    }}
                  >
                    <Typography
                      as="h3"
                      sx={{
                        fontSize: 16,
                        mb: "15px",
                        fontWeight: 500,
                        mt: "-25px",
                        ml: "-25px",
                        mr: "-25px",
                        padding: "10px 25px",
                        borderRadius: "8px 8px 0px 0px",
                        background:
                          "linear-gradient(225deg,  var(--gradientColorLighter1) 0%, var(--gradientColorLighter2) 91.25%)",

                        color: "black",
                      }}
                    >
                      <span style={{ color: "#fff" }}>Country wise Price List</span>
                    </Typography>

                    <Box>
                      {country_price_list?.map((data) => (
                        <Typography
                          sx={{
                            fontSize: 13,
                            mt: "10px",
                            display: "flex",
                            //   justifyContent: "space-between",
                          }}
                        >
                          <Typography>
                            <p
                              style={{
                                width: "250px",
                                fontWeight: "500",
                              }}
                              className="mr-10px"
                            >
                              Country:
                              <span
                                style={{
                                  paddingLeft: "5px",

                                  fontWeight: "normal",
                                }}
                                // className="mr-10px"
                              >
                                {data?.country}
                              </span>
                            </p>
                          </Typography>
                          <Typography>
                            <p
                              style={{
                                //  width: "150px",
                                fontWeight: "500",
                              }}
                              className="mr-10px"
                            >
                              Rental Price:
                              <span
                                style={{
                                  marginRight: "20px",
                                  paddingLeft: "5px",

                                  fontWeight: "normal",
                                }}
                              >
												  {data?.rental_price}
                                
                                {/* {data?.rental_price} */}
                              </span>
                            </p>
                          </Typography>
                        </Typography>
                      ))}
                    </Box>
                  </Card>
                </TabPanel>
              </Tabs>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={5} xl={5} key={"sdcsdc"}>
          <ProductProfile data={data} path="he" />
        
        </Grid>
{data?.series_access == "TVOD" && <Grid item spacing={4} xs={12} sm={12} md={12} lg={12} xl={12}>
    <Features earning={Total_data} />
</Grid>}
        {data1 && (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Card
              sx={{
                boxShadow: "none",
                borderRadius: "10px",
                p: "15px 25px",
                mb: "15px",
              }}
            >
              <Box mt={2}>
                <Tabs className="product-details-tabs">
                  <TabList>
                    {data1 && <Tab>{data1?.name}</Tab>}
                    {data2 && <Tab>{data2?.name}</Tab>}
                    {data3 && <Tab>{data3?.name}</Tab>}
                    {data4 && <Tab>{data4?.name}</Tab>}
                    {data5 && <Tab>{data5?.name}</Tab>}
                    {data6 && <Tab>{data6?.name}</Tab>}
                    {data7 && <Tab>{data7?.name}</Tab>}
                    {data8 && <Tab>{data8?.name}</Tab>}
                    {data9 && <Tab>{data9?.name}</Tab>}
                    {data10 && <Tab>{data10?.name}</Tab>}

                    {/* <Tab>Cast </Tab> */}
                  </TabList>

                  {data1 && (
                    <TabPanel>
                      <Card
                        sx={{
                          boxShadow: "none",
                          borderRadius: "10px",
                          p: "25px 20px",
                          mb: "10px",
                          backgroundColor: "#F7FAFF",
                        }}
                      >
                        <Typography
                          as="h3"
                          sx={{
                            fontSize: 16,
                            mb: "15px",
                            fontWeight: 500,
                            mt: "-25px",
                            ml: "-25px",
                            mr: "-25px",
                            padding: "10px 25px",
                            borderRadius: "8px 8px 0px 0px",
                            background:
                              "linear-gradient(225deg,  var(--gradientColorLighter1) 0%, var(--gradientColorLighter2) 91.25%)",

                            color: "black",
                          }}
                        >
                          <span style={{ color: "white" }}>Series Details</span>
                        </Typography>

                        <Box>
                          {data1?.data?.map((episode) => (
                            <Typography
                              sx={{
                                fontSize: 13,
                                mt: "10px",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography>
                                <p
                                  style={{
                                    width: "400px",
                                    fontWeight: "500",
                                  }}
                                  //  className="mr-10px"
                                >
                                  Episode Title:
                                  <span
                                    style={{
                                      marginRight: "20px",
                                      fontWeight: "normal",
                                    }}
                                  >
                                    {" "}
                                    {episode?.episode_title}
                                  </span>
                                </p>
                              </Typography>
                              <Typography>
                                <p
                                  style={{
                                    //  width: "150px",
                                    fontWeight: "500",
                                  }}
                                  className="mr-10px"
                                >
                                  Episode status:
                                  <span
                                    style={{
                                      marginRight: "20px",
                                      fontWeight: "normal",
                                    }}
                                  >
                                    {" "}
                                    {episode?.status}
                                  </span>
                                </p>
                              </Typography>
                              <Typography>
                                <p
                                  style={{
                                    //  width: "150px",
                                    fontWeight: "500",
                                  }}
                                  className="mr-10px"
                                >
                                  Episode Duration:
                                  <span
                                    style={{
                                      marginRight: "20px",
                                      fontWeight: "normal",
                                    }}
                                  >
                                    {" "}
                                    {episode?.episode_duration}
                                  </span>
                                </p>
                              </Typography>
                            </Typography>
                          ))}
                        </Box>
                      </Card>
                    </TabPanel>
                  )}
                  {data2 && (
                    <TabPanel>
                      <Card
                        sx={{
                          boxShadow: "none",
                          borderRadius: "10px",
                          p: "25px 20px",
                          mb: "10px",
                          backgroundColor: "#F7FAFF",
                        }}
                      >
                        <Typography
                          as="h3"
                          sx={{
                            fontSize: 16,
                            mb: "15px",
                            fontWeight: 500,
                            mt: "-25px",
                            ml: "-25px",
                            mr: "-25px",
                            padding: "10px 25px",
                            borderRadius: "8px 8px 0px 0px",
                            background:
                              "linear-gradient(225deg,  var(--gradientColorLighter1) 0%, var(--gradientColorLighter2) 91.25%)",

                            color: "black",
                          }}
                        >
                          <span style={{ color: "white" }}>Series Details</span>
                        </Typography>

                        <Box>
                          {data2?.data.map((episode, index) => (
                            <Typography
                              sx={{
                                fontSize: 13,
                                mt: "10px",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography>
                                <p
                                  style={{
                                    width: "400px",
                                    fontWeight: "500",
                                  }}
                                  //  className="mr-10px"
                                >
                                  Episode Title:
                                  <span
                                    style={{
                                      marginRight: "20px",
                                      fontWeight: "normal",
                                    }}
                                  >
                                    {" "}
                                    {episode?.episode_title}
                                  </span>
                                </p>
                              </Typography>
                              <Typography>
                                <p
                                  style={{
                                    //  width: "150px",
                                    fontWeight: "500",
                                  }}
                                  className="mr-10px"
                                >
                                  Episode status:
                                  <span
                                    style={{
                                      marginRight: "20px",
                                      fontWeight: "normal",
                                    }}
                                  >
                                    {" "}
                                    {episode?.status}
                                  </span>
                                </p>
                              </Typography>
                              <Typography>
                                <p
                                  style={{
                                    //  width: "150px",
                                    fontWeight: "500",
                                  }}
                                  className="mr-10px"
                                >
                                  Episode Duration:
                                  <span
                                    style={{
                                      marginRight: "20px",
                                      fontWeight: "normal",
                                    }}
                                  >
                                    {" "}
                                    {episode?.episode_duration}
                                  </span>
                                </p>
                              </Typography>
                            </Typography>
                          ))}
                        </Box>
                      </Card>
                    </TabPanel>
                  )}
                  {data3 && (
                    <TabPanel>
                      <Card
                        sx={{
                          boxShadow: "none",
                          borderRadius: "10px",
                          p: "25px 20px",
                          mb: "10px",
                          backgroundColor: "#F7FAFF",
                        }}
                      >
                        <Typography
                          as="h3"
                          sx={{
                            fontSize: 16,
                            mb: "15px",
                            fontWeight: 500,
                            mt: "-25px",
                            ml: "-25px",
                            mr: "-25px",
                            padding: "10px 25px",
                            borderRadius: "8px 8px 0px 0px",
                            background:
                              "linear-gradient(225deg,  var(--gradientColorLighter1) 0%, var(--gradientColorLighter2) 91.25%)",

                            color: "black",
                          }}
                        >
                          Series Details
                        </Typography>

                        <Box>
                          {data3?.data.map((episode, index) => (
                            <Typography
                              sx={{
                                fontSize: 13,
                                mt: "10px",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography>
                                <p
                                  style={{
                                    width: "400px",
                                    fontWeight: "500",
                                  }}
                                  //  className="mr-10px"
                                >
                                  Episode Title:
                                  <span
                                    style={{
                                      marginRight: "20px",
                                      fontWeight: "normal",
                                    }}
                                  >
                                    {" "}
                                    {episode?.episode_title}
                                  </span>
                                </p>
                              </Typography>
                              <Typography>
                                <p
                                  style={{
                                    //  width: "150px",
                                    fontWeight: "500",
                                  }}
                                  className="mr-10px"
                                >
                                  Episode status:
                                  <span
                                    style={{
                                      marginRight: "20px",
                                      fontWeight: "normal",
                                    }}
                                  >
                                    {" "}
                                    {episode?.status}
                                  </span>
                                </p>
                              </Typography>
                              <Typography>
                                <p
                                  style={{
                                    //  width: "150px",
                                    fontWeight: "500",
                                  }}
                                  className="mr-10px"
                                >
                                  Episode Duration:
                                  <span
                                    style={{
                                      marginRight: "20px",
                                      fontWeight: "normal",
                                    }}
                                  >
                                    {" "}
                                    {episode?.episode_duration}
                                  </span>
                                </p>
                              </Typography>
                            </Typography>
                          ))}
                        </Box>
                      </Card>
                    </TabPanel>
                  )}
                  {data4 && (
                    <TabPanel>
                      <Card
                        sx={{
                          boxShadow: "none",
                          borderRadius: "10px",
                          p: "25px 20px",
                          mb: "10px",
                          backgroundColor: "#F7FAFF",
                        }}
                      >
                        <Typography
                          as="h3"
                          sx={{
                            fontSize: 16,
                            mb: "15px",
                            fontWeight: 500,
                            mt: "-25px",
                            ml: "-25px",
                            mr: "-25px",
                            padding: "10px 25px",
                            borderRadius: "8px 8px 0px 0px",
                            background:
                              "linear-gradient(225deg,  var(--gradientColorLighter1) 0%, var(--gradientColorLighter2) 91.25%)",

                            color: "black",
                          }}
                        >
                          Series Details
                        </Typography>

                        <Box>
                          {data4?.data.map((episode, index) => (
                            <Typography
                              sx={{
                                fontSize: 13,
                                mt: "10px",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography>
                                <p
                                  style={{
                                    width: "400px",
                                    fontWeight: "500",
                                  }}
                                  //  className="mr-10px"
                                >
                                  Episode Title:
                                  <span
                                    style={{
                                      marginRight: "20px",
                                      fontWeight: "normal",
                                    }}
                                  >
                                    {" "}
                                    {episode?.episode_title}
                                  </span>
                                </p>
                              </Typography>
                              <Typography>
                                <p
                                  style={{
                                    //  width: "150px",
                                    fontWeight: "500",
                                  }}
                                  className="mr-10px"
                                >
                                  Episode status:
                                  <span
                                    style={{
                                      marginRight: "20px",
                                      fontWeight: "normal",
                                    }}
                                  >
                                    {" "}
                                    {episode?.status}
                                  </span>
                                </p>
                              </Typography>
                              <Typography>
                                <p
                                  style={{
                                    //  width: "150px",
                                    fontWeight: "500",
                                  }}
                                  className="mr-10px"
                                >
                                  Episode Duration:
                                  <span
                                    style={{
                                      marginRight: "20px",
                                      fontWeight: "normal",
                                    }}
                                  >
                                    {" "}
                                    {episode?.episode_duration}
                                  </span>
                                </p>
                              </Typography>
                            </Typography>
                          ))}
                        </Box>
                      </Card>
                    </TabPanel>
                  )}
                  {data5 && (
                    <TabPanel>
                      <Card
                        sx={{
                          boxShadow: "none",
                          borderRadius: "10px",
                          p: "25px 20px",
                          mb: "10px",
                          backgroundColor: "#F7FAFF",
                        }}
                      >
                        <Typography
                          as="h3"
                          sx={{
                            fontSize: 16,
                            mb: "15px",
                            fontWeight: 500,
                            mt: "-25px",
                            ml: "-25px",
                            mr: "-25px",
                            padding: "10px 25px",
                            borderRadius: "8px 8px 0px 0px",
                            background:
                              "linear-gradient(225deg,  var(--gradientColorLighter1) 0%, var(--gradientColorLighter2) 91.25%)",

                            color: "black",
                          }}
                        >
                          Series Details
                        </Typography>

                        <Box>
                          {data5.data.map((episode, index) => (
                            <Typography
                              sx={{
                                fontSize: 13,
                                mt: "10px",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography>
                                <p
                                  style={{
                                    width: "400px",
                                    fontWeight: "500",
                                  }}
                                  //  className="mr-10px"
                                >
                                  Episode Title:
                                  <span
                                    style={{
                                      marginRight: "20px",
                                      fontWeight: "normal",
                                    }}
                                  >
                                    {" "}
                                    {episode?.episode_title}
                                  </span>
                                </p>
                              </Typography>
                              <Typography>
                                <p
                                  style={{
                                    //  width: "150px",
                                    fontWeight: "500",
                                  }}
                                  className="mr-10px"
                                >
                                  Episode status:
                                  <span
                                    style={{
                                      marginRight: "20px",
                                      fontWeight: "normal",
                                    }}
                                  >
                                    {" "}
                                    {episode?.status}
                                  </span>
                                </p>
                              </Typography>
                              <Typography>
                                <p
                                  style={{
                                    //  width: "150px",
                                    fontWeight: "500",
                                  }}
                                  className="mr-10px"
                                >
                                  Episode Duration:
                                  <span
                                    style={{
                                      marginRight: "20px",
                                      fontWeight: "normal",
                                    }}
                                  >
                                    {" "}
                                    {episode?.episode_duration}
                                  </span>
                                </p>
                              </Typography>
                            </Typography>
                          ))}
                        </Box>
                      </Card>
                    </TabPanel>
                  )}
                  {data6 && (
                    <TabPanel>
                      <Card
                        sx={{
                          boxShadow: "none",
                          borderRadius: "10px",
                          p: "25px 20px",
                          mb: "10px",
                          backgroundColor: "#F7FAFF",
                        }}
                      >
                        <Typography
                          as="h3"
                          sx={{
                            fontSize: 16,
                            mb: "15px",
                            fontWeight: 500,
                            mt: "-25px",
                            ml: "-25px",
                            mr: "-25px",
                            padding: "10px 25px",
                            borderRadius: "8px 8px 0px 0px",
                            background:
                              "linear-gradient(225deg,  var(--gradientColorLighter1) 0%, var(--gradientColorLighter2) 91.25%)",

                            color: "black",
                          }}
                        >
                          Series Details
                        </Typography>

                        <Box>
                          {data6?.data.map((episode, index) => (
                            <Typography
                              sx={{
                                fontSize: 13,
                                mt: "10px",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography>
                                <p
                                  style={{
                                    width: "400px",
                                    fontWeight: "500",
                                  }}
                                  //  className="mr-10px"
                                >
                                  Episode Title:
                                  <span
                                    style={{
                                      marginRight: "20px",
                                      fontWeight: "normal",
                                    }}
                                  >
                                    {" "}
                                    {episode?.episode_title}
                                  </span>
                                </p>
                              </Typography>
                              <Typography>
                                <p
                                  style={{
                                    //  width: "150px",
                                    fontWeight: "500",
                                  }}
                                  className="mr-10px"
                                >
                                  Episode status:
                                  <span
                                    style={{
                                      marginRight: "20px",
                                      fontWeight: "normal",
                                    }}
                                  >
                                    {" "}
                                    {episode?.status}
                                  </span>
                                </p>
                              </Typography>
                              <Typography>
                                <p
                                  style={{
                                    //  width: "150px",
                                    fontWeight: "500",
                                  }}
                                  className="mr-10px"
                                >
                                  Episode Duration:
                                  <span
                                    style={{
                                      marginRight: "20px",
                                      fontWeight: "normal",
                                    }}
                                  >
                                    {" "}
                                    {episode?.episode_duration}
                                  </span>
                                </p>
                              </Typography>
                            </Typography>
                          ))}
                        </Box>
                      </Card>
                    </TabPanel>
                  )}
                  {data7 && (
                    <TabPanel>
                      <Card
                        sx={{
                          boxShadow: "none",
                          borderRadius: "10px",
                          p: "25px 20px",
                          mb: "10px",
                          backgroundColor: "#F7FAFF",
                        }}
                      >
                        <Typography
                          as="h3"
                          sx={{
                            fontSize: 16,
                            mb: "15px",
                            fontWeight: 500,
                            mt: "-25px",
                            ml: "-25px",
                            mr: "-25px",
                            padding: "10px 25px",
                            borderRadius: "8px 8px 0px 0px",
                            background:
                              "linear-gradient(225deg,  var(--gradientColorLighter1) 0%, var(--gradientColorLighter2) 91.25%)",

                            color: "black",
                          }}
                        >
                          Series Details
                        </Typography>

                        <Box>
                          {data7?.data.map((episode, index) => (
                            <Typography
                              sx={{
                                fontSize: 13,
                                mt: "10px",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography>
                                <p
                                  style={{
                                    width: "400px",
                                    fontWeight: "500",
                                  }}
                                  //  className="mr-10px"
                                >
                                  Episode Title:
                                  <span
                                    style={{
                                      marginRight: "20px",
                                      fontWeight: "normal",
                                    }}
                                  >
                                    {" "}
                                    {episode?.episode_title}
                                  </span>
                                </p>
                              </Typography>
                              <Typography>
                                <p
                                  style={{
                                    //  width: "150px",
                                    fontWeight: "500",
                                  }}
                                  className="mr-10px"
                                >
                                  Episode status:
                                  <span
                                    style={{
                                      marginRight: "20px",
                                      fontWeight: "normal",
                                    }}
                                  >
                                    {" "}
                                    {episode?.status}
                                  </span>
                                </p>
                              </Typography>
                              <Typography>
                                <p
                                  style={{
                                    //  width: "150px",
                                    fontWeight: "500",
                                  }}
                                  className="mr-10px"
                                >
                                  Episode Duration:
                                  <span
                                    style={{
                                      marginRight: "20px",
                                      fontWeight: "normal",
                                    }}
                                  >
                                    {" "}
                                    {episode?.episode_duration}
                                  </span>
                                </p>
                              </Typography>
                            </Typography>
                          ))}
                        </Box>
                      </Card>
                    </TabPanel>
                  )}
                  {data8 && (
                    <TabPanel>
                      <Card
                        sx={{
                          boxShadow: "none",
                          borderRadius: "10px",
                          p: "25px 20px",
                          mb: "10px",
                          backgroundColor: "#F7FAFF",
                        }}
                      >
                        <Typography
                          as="h3"
                          sx={{
                            fontSize: 16,
                            mb: "15px",
                            fontWeight: 500,
                            mt: "-25px",
                            ml: "-25px",
                            mr: "-25px",
                            padding: "10px 25px",
                            borderRadius: "8px 8px 0px 0px",
                            background:
                              "linear-gradient(225deg,  var(--gradientColorLighter1) 0%, var(--gradientColorLighter2) 91.25%)",

                            color: "black",
                          }}
                        >
                          Series Details
                        </Typography>

                        <Box>
                          {data8?.data.map((episode, index) => (
                            <Typography
                              sx={{
                                fontSize: 13,
                                mt: "10px",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography>
                                <p
                                  style={{
                                    width: "400px",
                                    fontWeight: "500",
                                  }}
                                  //  className="mr-10px"
                                >
                                  Episode Title:
                                  <span
                                    style={{
                                      marginRight: "20px",
                                      fontWeight: "normal",
                                    }}
                                  >
                                    {" "}
                                    {episode?.episode_title}
                                  </span>
                                </p>
                              </Typography>
                              <Typography>
                                <p
                                  style={{
                                    //  width: "150px",
                                    fontWeight: "500",
                                  }}
                                  className="mr-10px"
                                >
                                  Episode status:
                                  <span
                                    style={{
                                      marginRight: "20px",
                                      fontWeight: "normal",
                                    }}
                                  >
                                    {" "}
                                    {episode?.status}
                                  </span>
                                </p>
                              </Typography>
                              <Typography>
                                <p
                                  style={{
                                    //  width: "150px",
                                    fontWeight: "500",
                                  }}
                                  className="mr-10px"
                                >
                                  Episode Duration:
                                  <span
                                    style={{
                                      marginRight: "20px",
                                      fontWeight: "normal",
                                    }}
                                  >
                                    {" "}
                                    {episode?.episode_duration}
                                  </span>
                                </p>
                              </Typography>
                            </Typography>
                          ))}
                        </Box>
                      </Card>
                    </TabPanel>
                  )}
                  {data9 && (
                    <TabPanel>
                      <Card
                        sx={{
                          boxShadow: "none",
                          borderRadius: "10px",
                          p: "25px 20px",
                          mb: "10px",
                          backgroundColor: "#F7FAFF",
                        }}
                      >
                        <Typography
                          as="h3"
                          sx={{
                            fontSize: 16,
                            mb: "15px",
                            fontWeight: 500,
                            mt: "-25px",
                            ml: "-25px",
                            mr: "-25px",
                            padding: "10px 25px",
                            borderRadius: "8px 8px 0px 0px",
                            background:
                              "linear-gradient(225deg,  var(--gradientColorLighter1) 0%, var(--gradientColorLighter2) 91.25%)",

                            color: "black",
                          }}
                        >
                          Series Details
                        </Typography>

                        <Box>
                          {data9?.data.map((episode, index) => (
                            <Typography
                              sx={{
                                fontSize: 13,
                                mt: "10px",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography>
                                <p
                                  style={{
                                    width: "400px",
                                    fontWeight: "500",
                                  }}
                                  //  className="mr-10px"
                                >
                                  Episode Title:
                                  <span
                                    style={{
                                      marginRight: "20px",
                                      fontWeight: "normal",
                                    }}
                                  >
                                    {" "}
                                    {episode?.episode_title}
                                  </span>
                                </p>
                              </Typography>
                              <Typography>
                                <p
                                  style={{
                                    //  width: "150px",
                                    fontWeight: "500",
                                  }}
                                  className="mr-10px"
                                >
                                  Episode status:
                                  <span
                                    style={{
                                      marginRight: "20px",
                                      fontWeight: "normal",
                                    }}
                                  >
                                    {" "}
                                    {episode?.status}
                                  </span>
                                </p>
                              </Typography>
                              <Typography>
                                <p
                                  style={{
                                    //  width: "150px",
                                    fontWeight: "500",
                                  }}
                                  className="mr-10px"
                                >
                                  Episode Duration:
                                  <span
                                    style={{
                                      marginRight: "20px",
                                      fontWeight: "normal",
                                    }}
                                  >
                                    {" "}
                                    {episode?.episode_duration}
                                  </span>
                                </p>
                              </Typography>
                            </Typography>
                          ))}
                        </Box>
                      </Card>
                    </TabPanel>
                  )}
                  {data10 && (
                    <TabPanel>
                      <Card
                        sx={{
                          boxShadow: "none",
                          borderRadius: "10px",
                          p: "25px 20px",
                          mb: "10px",
                          backgroundColor: "#F7FAFF",
                        }}
                      >
                        <Typography
                          as="h3"
                          sx={{
                            fontSize: 16,
                            mb: "15px",
                            fontWeight: 500,
                            mt: "-25px",
                            ml: "-25px",
                            mr: "-25px",
                            padding: "10px 25px",
                            borderRadius: "8px 8px 0px 0px",
                            background:
                              "linear-gradient(225deg,  var(--gradientColorLighter1) 0%, var(--gradientColorLighter2) 91.25%)",

                            color: "black",
                          }}
                        >
                          Series Details
                        </Typography>

                        <Box>
                          {data10?.data.map((episode, index) => (
                            <Typography
                              sx={{
                                fontSize: 13,
                                mt: "10px",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography>
                                <p
                                  style={{
                                    width: "400px",
                                    fontWeight: "500",
                                  }}
                                  //  className="mr-10px"
                                >
                                  Episode Title:
                                  <span
                                    style={{
                                      marginRight: "20px",
                                      fontWeight: "normal",
                                    }}
                                  >
                                    {episode?.episode_title}
                                  </span>
                                </p>
                              </Typography>
                              <Typography>
                                <p
                                  style={{
                                    //  width: "150px",
                                    fontWeight: "500",
                                  }}
                                  className="mr-10px"
                                >
                                  Episode status:
                                  <span
                                    style={{
                                      marginRight: "20px",
                                      fontWeight: "normal",
                                    }}
                                  >
                                    {" "}
                                    {episode?.status}
                                  </span>
                                </p>
                              </Typography>
                              <Typography>
                                <p
                                  style={{
                                    //  width: "150px",
                                    fontWeight: "500",
                                  }}
                                  className="mr-10px"
                                >
                                  Episode Duration:
                                  <span
                                    style={{
                                      marginRight: "20px",
                                      fontWeight: "normal",
                                    }}
                                  >
                                    {" "}
                                    {episode?.episode_duration}
                                  </span>
                                </p>
                              </Typography>
                            </Typography>
                          ))}
                        </Box>
                      </Card>
                    </TabPanel>
                  )}
                </Tabs>
              </Box>
            </Card>
          </Grid>
        )}
      </Grid>

      {/* {role != "Producer" && (
        <WatchedCustomers id={location.state?.id} path={path} />
      )} */}
    </>
  );
};

export default ProductDetailsContent;
