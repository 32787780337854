import React from 'react';
import { useState, useMemo, useEffect } from 'react';
import ListTable from '../utils/Table'
import Form from '../utils/Form'
import ViewChange from "../utils/ViewChange";
// import { distributor_create, distributor_delete, distributor_update, all_distributor_list } from '../../actions/distributor';
import { producer_create ,all_producer_list , producer_update } from '../../actions/producer';
import { useDispatch, useSelector } from 'react-redux';
import Export from "./../utils/Export";
import { useLocation, useNavigate } from "react-router-dom";
export default function Producer() {

  const user = useSelector((state) => state.layout.profile)
  const dispatch = useDispatch()
  // const rights = useSelector((state) => state.layout.rights)
  const rights = useSelector((state) => state.layout.right);

  const location = useLocation();
  const navigate = useNavigate()
  const [form, setForm] = useState((location?.state?.form && JSON.parse(location?.state?.form)) || {});
  const [view, setView] = useState(location?.state?.view || "view_all");
  const [isEdit, setIsEdit] = useState(false);
  
  useMemo(() => {
    if (isEdit) { setView("create_new") }
    else {
      setView("view_all")
      setForm({});
    }
  }, [isEdit]);
  const path = location?.pathname.split("/")[2]
  useEffect(() => {
    setView(path != "Collaborator" ? "create_new" : "view_all");
    setForm((location?.state?.form && JSON.parse(location?.state?.form)) || {});
    setIsEdit(path == "EditCollaborator");
  }, [location]);
  const tempTableData = {
    tableTitle: "Collaborator", 
    // deleteRecord: distributor_delete,
    updateRecord: producer_update,
    disableDelete: true,

    deleteAccess: rights?.["Collaborator"]?.["delete"] == "true",
    onDeleteText: "Are you sure want to delete?",
    onUpdateText: "Are you Sure?",
    tableHead: [
      {
        id: 'name',
        label: 'Collaborator Name',
        // link: "/Distributors/DistributerProducts",
        color: "var(--gradientColor2)",
      },
      {
        id: 'company_logo',
        label: 'Logo',
        isImage: true
      },
      {
        id: 'company_name',
        label: 'Company Name',
      },

      {
        id: 'email',
        label: 'Contact Info',
        subText: "mobileNumber"
      },
      {
        id: 'commission',
        label: 'Commision(%)',
      },

      {
        id: 'edit',
        label: 'Update',
        access: rights?.["Collaborator"]?.["edit"] == "true"
      },
    ],
    tableBody: [
    ], filterColumn: [
      // {
      //   id: "1",
      // title: "State",
      // name: "subscription",
      // options: ["State", "State","State"],
      // },

    ],
  }
  const [tableData, setTableData] = useState({ ...tempTableData })
  useMemo(() => {
    setTableData({ ...tempTableData })
  }, [rights])
  const [formStructure, setFormStructure] = useState([

    {
      id: "5",
      type: "inputBox",
      title: "Company Name",
      name: "company_name",
      required: true,
    },
    {
      id: "5",
      type: "inputBox",
      title: "Contact Person Name",
      name: "name",
      required: true,
    },
    {
      id: "5",
      type: "inputBox",
      title: "Email ID",
      variant: "email",
      disabled : isEdit && "true",
      name: "email",
      required: true,
    },
    {
      id: "4",
      type: "phone",
      title: "Mobile Number",
      maxLength: 12,
      name: "mobileNumber",

      required: true,
    },
    {
      title: "GST No (Optional)",
      id: "1",
      type: "inputBox",
      name: "GST",

    },

    {
      id: "2",
      type: "inputBox",
      title: "Address",
      name: "address",
      required: true,

    },
    {
      id: "2",
      type: "select",
      title: "Pay per view price (INR)",
      name: "commission",
      options: ["10", "20", "30", "40", "50", "60", "70", "80", "90"],
      required: true,
    },
    {
      id: "2",
      type: "image",
      title: "Company Logo",
      required: true,

      name: "company_logo",
    },


    {
      id: "8",
      type: "button",
      title: isEdit ? "Update" : "Create",
      // disabled: false,

    }
  ])
useMemo(()=>{
const temp = formStructure
if(isEdit){
  temp[2]["disabled"] = true
}else {
  temp[2]["disabled"] = false
}

},[form?.email])
  // useMemo(() => {
  //   const temp = formStructure
  //   if (form?.countryCode == "+91" || form?.countryCode == undefined) {
  //     temp[4]["title"] = "GST No"
  //   }
  //   else
  //     temp[4]["title"] = "Registration No"

  //   setFormStructure([...temp])
  // }, [form?.countryCode])

  const distributors = useSelector((state) => state.producer.producer);
  console.log(distributors,"kiiik")
  useEffect(() => {
    if(user){

      const data = new FormData()
      data.append("user" , user?.id)
      dispatch(all_producer_list(data))
    }
  }, [user])
  useMemo(() => {

    if (distributors?.statuscode == 200) {
      const temp = tableData
      temp.tableBody = distributors?.data.map((ele) => ({ ...ele, id: ele?.id }))
      setTableData({ ...temp })
    }
  }, [distributors])

  const formTitle = isEdit ? "Edit Collaborator" : "Create Collaborator"

  const message = useSelector((state) => state.layout.message);
  // console.log("3636363", form)

  const handleSubmit = (event) => {
    event.preventDefault();

    const tempForm = form
    const temp = formStructure;

    // temp[15]["disabled"] = true
    // setTimeout(() => {
    //   temp[15]["disabled"] = false
    // }, 3000)
    // console.log("363636")
    tempForm["username"] = form?.mobileNumber.slice(form?.countryCode?.length - 1)
    tempForm["tempmobileNumber"] = form?.mobileNumber
    tempForm["mobileNumber"] = form?.mobileNumber.slice(form?.countryCode?.length - 1)
    setForm({ ...tempForm })

    const data = new FormData();
    Object.keys(form).map((key) => data.append(key, form?.[key]));
    // data.append("user", user.id);
    formStructure.map((element) => {
      if (element.type == "image" && form?.[element.name] && typeof (form?.[element.name]) != "string") {
        const temp = form;
        temp["temp" + element.name] = form?.[element.name];
        temp[element.name] = URL.createObjectURL(form?.[element.name]);

        setForm({
          ...temp,
        });
      }
    });


    if (isEdit) {
      dispatch(producer_update(data))
      // setTimeout(() => {
      //   // navigate("/Dashboard")
      // }, 1000)
    }
    else {
      dispatch(producer_create(data))
    }

  };
  useMemo(() => {
    if (message?.statuscode == 200) {
      const temp = tableData;
      if (isEdit) {
        temp.tableBody.map(
          (value, index) =>
            value.id == form.id && (temp.tableBody[index] = { ...form })
        );

      } else {
        temp.tableBody[temp.tableBody.length] = {
          id: temp.tableBody.length,
          ...form,
          edit: temp.tableBody.length,
        };

      }
      setTableData({ ...temp });
      setIsEdit(false);
      setForm({});
      setTimeout(() => {
        const data = new FormData()
        data.append("user" , user?.id)
        dispatch(all_producer_list(data));
        navigate("/Collaborator/Collaborator/", { state: { view: "view_all" } })
        setView("view_all");
      }, 1000);
    }
    else {
      const tempForm = form;

      tempForm["mobileNumber"] = form?.tempmobileNumber
      tempForm["company_logo"] = form?.tempcompany_logo
      setForm({ ...tempForm });
    }
  }, [message])

  return (
    <>

      <ViewChange
        setForm={setForm}
        access={rights?.["Collaborator"]?.["create"] == "true"}
        setView={setView} setIsEdit={setIsEdit}
        view={view} isEdit={isEdit}
        create_new={"/Collaborator/CreateCollaborator/"}
        view_all={"/Collaborator/Collaborator/"}
        form={form}
        export_excel={<Export fileName={"Producers"}
        access={rights?.["Collaborator"]?.["export_excel"] == "true"}
          isClubed={true}
          exportData={tableData?.exportData || tableData?.tableBody} headings={tableData.tableHead.map((value) => value.label)} />
        } />


      {view == "create_new" &&
        <Form
          formStructure={formStructure}
          handleSubmit={handleSubmit}
          formTitle={formTitle}
          key={"Form"}
          setForm={setForm}
          form={form}
          tableData={tableData}
          setTableData={setTableData}
          isEdit={isEdit}
          setIsEdit={setIsEdit} />}
      {view == "view_all" &&
        <ListTable
          tableData={tableData}
          key={"ListTable"}
          setForm={setForm}
          setTableData={setTableData}
          setIsEdit={setIsEdit}
          create_new={"/Collaborator/EditCollaborator/"}

        />}


    </>
  );
}
