import React from "react";
import { useState, useEffect, useMemo } from "react";

import ListTable from "../utils/Table";
import { all_transaction_list, transaction_details } from "./../../actions/transaction";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Export from "./../utils/Export";

const TransactionDetails = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    // console.log(location,"parth123")

    const [tableData, setTableData] = useState({
        tableTitle: "Transaction Details",
        disableDelete: true,
        // column_sum: {
        //   name: "payment_amount",
        //   title: "Total Amount",
        // },
        // toggle_state: {
        //   title: "Audio Language",
        //   name: toggle,
        //   default: "Movie",
        //   options: [
        //     { value: "Movie", color: "success" },
        //     { value: "Series", color: "danger" },
        //   ],
        // //   handleChangeto: (event) => {
        // //     setToggle(event.target.value);
        // //   },
        // },
        tableHead: [
          {
            id: "email",
            label: "Contact Details",
            subText:"mobile_no",
            // link: "/Transactions/TransactionsDetails/TransactionsDetails",
            color: "var(--gradientColor2)",
          },
          {
            id: "payment_amount1",
            label: "Amount",
          },
          {
            id: "payment_id",
            label: "Payment Details",
            subText:"payment_method"
          },
          {
            id: "location",
            label: "Location",
            // subText: "mobile_no",
          },
          // {
          //   id: "plan",
          //   label: "Plan",
          //   // isSpecial: true,
          //   align: "left",
          // },
          // {
          //   id: "payment_amount",
          //   label: "Amount",
          // },
          // {
          //   id: "payment_id",
          //   label: "Payment ID",
          //   // subText: "payment_method"
          // },
          {
            id: "date",
            label: "Date",
          },
    
          {
            id: "device_type",
            label: "Device Type",
            subText: "device_id",
          },
    
          // {
          // 	id: "payment_status",
          // 	label: "Status",
          // 	isButtonDisplay: true
          // },
        ],
        tableBody: [],
        filterColumn: [
          // {
          //   id: "1",
          //   title: "Category Type",
          //   name: "category_type",
          //   // value: totalAmount,
          //   // default: location?.state,
          //   options: ["Video", "Web Series"],
          // },
          //   {
          //     id: "1",
          //     title: "Status",
          //     name: "payment_status",
          //     displayOn: "category_type",
          //     // value: totalAmount,
          //     // default: location?.state,
          //     options: ["Failed", "Success"],
          //   },
        ],
    
        isDateRangeFilter: "date",
      });
      const transactions = useSelector(
        (state) => state?.transactions?.transactionsDetails
      );
      // console.log(transactions, "pppppp");
    
      useEffect(() => {
        const data = new FormData();
        data.append("id" , location.state.id)
        data.append("category_type" , location.state.category_type)

        // data.append("category_type", toggle);
        dispatch(transaction_details(data));
      }, []);

      useMemo(() => {
        if (transactions?.statuscode == 200) {
          const temp = tableData;
          temp.tableBody = transactions?.data.map((ele) => ({
            ...ele,
            mobile_no: ele?.mobile_no != null ? ele?.mobile_no : " - ",
            payment_amount1:parseFloat(ele?.payment_amount).toFixed(2)
    
              
          }));
  
    
          setTableData({ ...temp });
        }
      }, [transactions]);
  return (
   <>
			<Export fileName={"Transaction Details"} exportData={tableData?.exportData || tableData?.tableBody} headings={tableData.tableHead.map((value) => value.label)} />

    <ListTable
        tableData={tableData}
        key={"ListTable"}
        setTableData={setTableData}
        // setForm={setForm}
        // setIsEdit={setIsEdit}
      />
   </>
  )
}

export default TransactionDetails
