import * as actionType from "../constants/actionTypes";

const layoutReducer = (state = { layoutData: null }, action) => {
	switch (action.type) {
		case actionType.MESSAGE:
			return { ...state, message: action.payload };
			case actionType.MESSAGE1:
			return { ...state, message1: action.payload };
			case actionType.MESSAGE2:
				return { ...state, message2: action.payload };
				case actionType.MESSAGE3:
				return { ...state, message3: action.payload };
			case actionType.LOGGEDIN:
				return { ...state, loggedin: action.payload };
				case actionType.RIGHTS:
					return { ...state, right: action.payload };
			case actionType.PROFILE:
			return { ...state, profile: action.payload };
			case actionType.ROLE:
			return { ...state, role: action.payload };
	
			
		default:
			return state;
	}
};

export default layoutReducer;
