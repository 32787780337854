import React, { useMemo, useState } from "react";
import Form from "../utils/Form";
import { useDispatch, useSelector } from "react-redux";
// import { all_distributor_list } from "../../actions/distributor";
import { all_movie_list } from "../../actions/Movie/movie";
import { useLocation, useNavigate } from "react-router-dom";
import { report_data } from "../../actions/reports";
import { Details } from "@mui/icons-material";
// import { all_advertiser_list } from "../../actions/Advertiser/advertiser";
// import { all_advertisement_list } from "../../actions/Advertiser/advertisement";
import { all_series_list } from "../../actions/WebSeries/series";
import { all_subscription_list } from "../../actions/subscription";
import { all_producer_list } from "../../actions/producer";

const Report_field = () => {
  const [form, setForm] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((state) => state.layout.profile);
  const role = useSelector((state) => state.layout.role);
  const user1 = useSelector((state) => state.layout?.distributor);
  const user2 = useSelector((state) => state.layout?.advertiser);

  const movies = useSelector((state) => state.movies.movies);
  const subscriptions = useSelector(
    (state) => state.subscriptions.subscriptions
  );
  useMemo(() => {
    if (user?.id) {
      const data = new FormData();
      data.append("id", user?.id);
      data.append("user", user?.id);
      data.append("movie_subcategory", null);
      // dispatch(all_distributor_list(data));
      dispatch(all_producer_list(data));
      dispatch(all_movie_list(data));
      // dispatch(all_advertiser_list(data))
      // dispatch(all_advertisement_list(data));
      dispatch(all_series_list(data));
      dispatch(all_subscription_list());
    }
  }, [user, location]);
  // const reportPage =()=>{
  //   navigate("/reports/reports_download/")
  // }
  const series = useSelector((state) => state.webseries.series);
  const distributors = useSelector((state) => state.producer.producer);
  // const advertisers = useSelector((state) => state?.advertisers?.advertisers);
  // const advertisements = useSelector(
  //   (state) => state.advertisers.advertisements
  // );
  const [formStructure, setFormStructure] = useState([
    {
      id: "1",
      type: "select",
      title: "Report Category",
      name: "report_category",
      options: ["Movies", "Series", "Complaint", "Transaction", "Customer"],
      required: true,
    },
    {
      id: "2",
      type: "select",
      title: "Movie Category",
      name: "movie_ownership",
      options: ["In house", "Collaborator"],
      required: false,
      display: "none",
    },
    {
      id: "3",
      type: "select",
      title: "Movie Collaborator",
      name: "distributor",
      options: ["In house", "Collaborator"],
      // required: true,
      display: "none",
    },
    {
      id: "4",
      type: "select",
      title: "Select Movie",
      name: "movie",
      options: ["In house", "Distributor"],
      // required: true,
      display: "none",
    },
    {
      id: "5",
      type: "select",
      title: "Series Category",
      name: "series_ownership",
      options: ["In house", "Collaborator"],
      required: false,
      display: "none",
    },
    {
      id: "6",
      type: "select",
      title: "Series Collaborator",
      name: "distributor",
      options: ["In house", "Distributor"],
      // required: true,
      display: "none",
    },
    {
      id: "7",
      type: "select",
      title: "Select Series",
      name: "series",
      options: [],
      required: false,
      display: "none",
    },
    {
      id: "8",
      type: "select",
      title: "Complaint Type",
      name: "complaint_type",
      display: "none",
      options: [
        "All",
        "Player Not working",
        "Rental not working",
        "Content is not appropriate",
        "Profile Related issue",
        "Application Related issue",
        "Payment done but Rental not working",
        "Others",
      ],
    },
    {
      id: "9",
      type: "select",
      title: "Select Type",
      name: "transaction_type",
      options: ["All", "Movie", "Series"],
      required: false,
      display: "none",
    },
    {
      id: "10",
      type: "select",
      title: "Select Movie",
      name: "movie_id",
      options: ["All", "Male", "Female", "Other"],
      required: false,
      display: "none",
    },
    {
      id: "11",
      type: "select",
      title: "Select Series",
      name: "series_id",
      options: ["All", "Male", "Female", "Other"],
      required: false,
      display: "none",
    },
    {
      id: "12",
      type: "start_date_end_date",
      formName1: "starting_date",
      formName2: "ending_date",
      size: "6",
      display: "none",
    },
    {
      id: "13",
      type: "button",
      title: "Create",
      //  forceShow : true,
      //  handleClick:()=>{ reportPage()}
    },
  ]);
  const [formStructureDis, setFormStructureDis] = useState([
    {
      id: "1",
      type: "select",
      title: "select Category",
      name: "report_category",
      options: ["Movies", "Series"],
      required: false,
      size: "6",
      // display: "none",
    },
    {
      id: "1",
      type: "select",
      title: "select Movie",
      name: "movie",
      options: ["In house", "Distributor"],
      required: false,
      display: "none",
      size: "6",
    },
    {
      id: "17",
      type: "button",
      title: "Create",
      //  forceShow : true,
      //  handleClick:()=>{ reportPage()}
    },
    // {
    //   id: "1",
    //   type: "select",
    //   title: "select Movie",
    //   name: "movie",
    //   options: ["In house", "Distributor"],
    //   required: false,
    //   // display: "none",
    // },
  ]);
  const [formStructureAdv, setFormStructureAdv] = useState([
    {
      id: "1",
      type: "select",
      title: "Select Advertisement",
      name: "advertisement",
      options: [],
      size: "6",
      required: true,
    },
    {
      id: "17",
      type: "button",
      title: "Create",
      //  forceShow : true,
      //  handleClick:()=>{ reportPage()}
    },
  ]);
  useMemo(()=>{
    if(role === "Distributor" && form?.report_category == "Movies"){

      const temp = formStructureDis;
      temp[1]["display"] = "block"
      temp[1]["options"] = movies?.data?.map((movie)=>
        (movie?.movie_ownership === "Collaborator") && (movie?.movie_distributor?.user == user?.id )&& movie?.movie_name
      ).filter((e)=>e)
      setFormStructureDis([...temp])
    }else if(role === "Distributor" && form?.report_category == "Series"){
      const temp = formStructureDis;
      temp[1]["display"] = "block"
      temp[1]["name"] = "series"
      temp[1]["title"]= "Select Series"
      temp[1]["options"] = series?.data?.map((series)=>
        series?.series_ownership === "Distributor" && (series?.series_distributor?.user == user?.id) && series?.series_name
      ).filter((e)=>e);
      setFormStructureDis([...temp])

    }else{
      const temp = formStructureDis;
      temp[1]["display"] = "none"
      temp[1]["options"]= []
      setFormStructureDis([...temp])

    }

  },[role ,movies ,series, form?.report_category])
  // useMemo(()=>{
  //   if(role === "Advertiser" && advertisements?.statuscode == 200){
  //     const temp = formStructureAdv;
  //     temp[0]["options"] =  advertisements?.data
  //     .map(
  //       (ele) =>
  //         user2?.name === ele?.advertiser?.name && ele?.product_name
  //     )
  //     .filter((e) => e);
  //     setFormStructureAdv([...temp])
  //   }

  // },[role,advertisements , user2])
  useMemo(() => {
    if (form?.report_category == "Movies") {
      const temp = formStructure;
      temp[1]["display"] = "block";
      temp[1]["required"] = true;
      temp[11]["display"] = "none";
      setFormStructure([...temp]);
    } else {
      const temp = formStructure;
      temp[1]["display"] = "none";
      temp[1]["required"] = false;

      setFormStructure([...temp]);
    }

    if (form?.report_category == "Series") {
      const temp = formStructure;
      temp[4]["display"] = "block";
      temp[4]["required"] = true;
      temp[11]["display"] = "none";
      setFormStructure([...temp]);
    } else {
      const temp = formStructure;
      temp[4]["display"] = "none";
      temp[4]["required"] = false;

      setFormStructure([...temp]);
    }
    if (form?.report_category == "Customer") {
      const temp = formStructure;

      temp[11]["display"] = "block";
      setFormStructure([...temp]);
    }
    if (form?.report_category == "Complaint") {
      const temp = formStructure;
      temp[7]["display"] = "block";
      temp[7]["required"] = true;
      temp[11]["display"] = "block";

      setFormStructure([...temp]);
    } else {
      const temp = formStructure;
      temp[7]["display"] = "none";
      temp[7]["required"] = false;

      setFormStructure([...temp]);
    }

    if (form?.report_category == "Transaction") {
      const temp = formStructure;
      temp[8]["display"] = "block";
      temp[8]["required"] = true;
      temp[11]["display"] = "block";

      setFormStructure([...temp]);
    } else {
      const temp = formStructure;
      temp[8]["display"] = "none";
      temp[8]["required"] = false;

      setFormStructure([...temp]);
    }
  }, [form?.report_category]);
  useMemo(() => {
    if (form?.movie_ownership === "Collaborator") {
      const temp = formStructure;
      temp[2]["display"] = "block";
      temp[2]["required"] = true;

      temp[3]["display"] = "block";
      temp[3]["required"] = true;

      setFormStructure([...temp]);
    } else if (form?.movie_ownership === "In house") {
      const temp = formStructure;
      temp[2]["display"] = "none";
      temp[2]["required"] = false;

      temp[3]["display"] = "block";
      temp[3]["required"] = true;

      setFormStructure([...temp]);
    } else {
      const temp = formStructure;
      temp[2]["display"] = "none";
      temp[2]["required"] = false;

      temp[3]["display"] = "none";
      temp[3]["required"] = false;

      setFormStructure([...temp]);
    }
  }, [form?.movie_ownership]);
  useMemo(() => {
    if (form?.series_ownership == "Collaborator") {
      const temp = formStructure;
      temp[5]["display"] = "block";
      temp[5]["required"] = true;
      temp[6]["display"] = "block";
      temp[6]["required"] = true;

      setFormStructure([...temp]);
    } else if (form?.series_ownership === "In house") {
      const temp = formStructure;
      temp[5]["display"] = "none";
      temp[5]["required"] = false;

      temp[6]["display"] = "block";
      temp[6]["required"] = true;

      setFormStructure([...temp]);
    } else {
      const temp = formStructure;
      temp[5]["display"] = "none";
      temp[5]["required"] = false;

      temp[6]["display"] = "none";
      temp[6]["required"] = false;

      setFormStructure([...temp]);
    }
  }, [form?.series_ownership]);

  useMemo(() => {
    if (form?.category_type == "Movie") {
      const temp = formStructure;
      const tempArray = ["All"];
      temp[14]["display"] = "block";
      temp[14]["name"] = "movie_id";
      temp[14]["title"] = "Select Movie";
      // movies?.data.map((movie) =>
      //   tempArray.push(
      //     (movie?.movie_access == "TVOD" && movie?.movie_name)
      //   )
      // ).filter((e) => e);
      const TVODEMovies = movies?.data
        .map((movie) => movie?.movie_access == "TVOD" && movie?.movie_name)
        .filter((e) => e);
      tempArray.push(...TVODEMovies);
      temp[14]["options"] = tempArray;
      setFormStructure([...temp]);
    } else if (form?.category_type == "Series") {
      const temp = formStructure;
      const tempArray = ["All"];
      temp[14]["display"] = "block";
      temp[14]["name"] = "series_id";
      temp[14]["title"] = "Select Series";
      const TVODSeries = series?.data
        .map((series) => series?.series_type == "TVOD" && series?.series_name)
        .filter((e) => e);
      tempArray.push(...TVODSeries);
      temp[14]["options"] = tempArray;
      setFormStructure([...temp]);
    }
  }, [form?.category_type, movies, series]);
  useMemo(() => {
    if (form?.transaction_type == "Movie") {
      const temp = formStructure;
      temp[9]["display"] = "block";
      temp[9]["required"] = true;
      const tempArray = ["All"];
      const TVODEMovies = movies?.data
        .map((movie) => movie?.movie_access == "TVOD" && movie?.movie_name)
        .filter((e) => e);
      tempArray.push(...TVODEMovies);
      temp[9]["options"] = tempArray;
      setFormStructure([...temp]);
      temp[10]["display"] = "none";
      temp[10]["required"] = false;
      setFormStructure([...temp]);
    } else if (form?.transaction_type == "Series") {
      const temp = formStructure;
      temp[9]["display"] = "none";
      temp[9]["required"] = false;
      temp[10]["display"] = "block";
      temp[10]["required"] = true;
      const tempArray = ["All"];
      const TVODSeries = series?.data
      .map((series) => series?.series_name)
      .filter((e) => e);
    tempArray.push(...TVODSeries);
    temp[10]["options"] = tempArray;
      setFormStructure([...temp]);
    } else {
      const temp = formStructure;
      temp[9]["display"] = "none";
      temp[9]["required"] = false;
      temp[10]["display"] = "none";
      temp[10]["required"] = false;
      setFormStructure([...temp]);
    }
  }, [form?.transaction_type]);
  useMemo(() => {
    if (role !== "Distributor" && distributors?.statuscode == 200) {
      const temp = formStructure;
      temp[2]["options"] = distributors?.data
        .map((distributor) => distributor?.name)
        .filter((e) => e);
      temp[5]["options"] = distributors?.data
        .map((distributor) => distributor?.name)
        .filter((e) => e);

      setFormStructure([...temp]);
    }
  }, [distributors, role]);
  // useMemo(() => {
  //   if (subscriptions?.statuscode == 200) {
  //     const temp = formStructure;
  //     const tempArray = ["All"];
  //     subscriptions?.data.map((ele) => tempArray.push(ele?.plan_name));
  //     temp[12]["options"] = tempArray;
  //     setFormStructure([...temp]);
  //   }
  // }, [subscriptions]);
  useMemo(() => {
    if (series?.statuscode == 200) {
      const temp = formStructure;
      temp[6]["options"] =
        form?.series_ownership == "Collaborator"
          ? series?.data
              .map(
                (series) =>
                  form?.distributor == series?.distributor?.name &&
                  series?.series_name
              )
              .filter((e) => e)
          : series?.data
              .map(
                (series) =>
                  // series?.series_type == "TVOD" &&
                  series?.series_ownership == "In house" && series?.series_name
              )
              .filter((e) => e);
      setFormStructure([...temp]);
    }
  }, [series, form?.distributor]);

  useMemo(() => {
    if (movies?.statuscode == 200) {
      const temp = formStructure;
      temp[3]["options"] =
        form?.movie_ownership == "Collaborator"
          ? movies?.data
              .map(
                (movie) =>
                  form?.distributor == movie?.distributor?.name &&
                  movie?.movie_name
              )
              .filter((e) => e)
          : movies?.data
              .map(
                (movie) =>
                  movie?.movie_access == "TVOD" &&
                  movie?.movie_ownership == "In house" &&
                  movie?.movie_name
              )
              .filter((e) => e);
      setFormStructure([...temp]);
    }
  }, [movies, form?.distributor]);
  // useMemo(() => {
  //   if (role !== "Advertiser" &&advertisements?.statuscode == 200) {
  //     const temp = formStructure;
  //     temp[8]["options"] = advertisers?.data?.map((ele)=>ele?.name)

  //     setFormStructure([...temp]);
  //   }
  // }, [advertisements]);
  // useMemo(()=>{
  //   if(advertisers?.statuscode == 200){
  //     temp[15]["options"] = advertisers?.
  //   }
  // },[advertisers])
  // useMemo(() => {
  //   if (advertisements?.statuscode == 200) {
  //     const temp = formStructure;
  //     temp[9]["options"] = advertisements?.data
  //       .map(
  //         (ele) =>
  //           form?.advertiser === ele?.advertiser?.name && ele?.product_name
  //       )
  //       .filter((e) => e);
  //     setFormStructure([...temp]);
  //   }
  // }, [advertisements, form?.advertiser]);
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData();
    const temp_movie_category = form;
    temp_movie_category["tempdistributor"] = form?.["distributor"];
    temp_movie_category["tempmovie"] = form?.["movie"];
    temp_movie_category["tempmovie_id"] = form?.["movie_id"];

    temp_movie_category["tempseries"] = form?.["series"];
    temp_movie_category["tempseries_id"] = form?.["series_id"];
    temp_movie_category["tempseries"] = form?.["series"];
    
    if (form?.series_id != "All") {
      temp_movie_category["series_id"] = series?.data
        .map(
          (series) =>
            form?.["series_id"]?.includes(series?.series_name) && series?.id
        )
        .filter((e) => e);
    }
    if (form?.movie_id != "All") {
      temp_movie_category["movie_id"] = movies?.data
        .map(
          (movie) => form?.["movie_id"]?.includes(movie?.movie_name) && movie.id
        )
        .filter((e) => e);
    }
    // temp_movie_category["tempplan_id"] = te
    temp_movie_category["series"] = series?.data
      .map(
        (series) =>
          form?.["series"]?.includes(series?.series_name) && series?.id
      )
      .filter((e) => e);

    temp_movie_category["distributor"] = distributors?.data
      .map(
        (option) => form?.["distributor"]?.includes(option.name) && option.id
      )
      .filter((e) => e);
    temp_movie_category["movie"] = movies?.data
      .map((movie) => form?.["movie"]?.includes(movie?.movie_name) && movie.id)
      .filter((e) => e);

    // temp_movie_category["advertiser"] = advertisers?.data
    //   .map(
    //     (advertiser) =>
    //       form?.["advertiser"]?.includes(advertiser?.name) &&
    //      advertiser?.id
    //   )
    //   .filter((e) => e);
    // temp_movie_category["advertisement"] = advertisements?.data
    //   .map(
    //     (advertisement) =>
    //       form?.["advertisement"]?.includes(advertisement?.product_name) &&
    //       advertisement?.id
    //   )
    //   .filter((e) => e);
    Object.keys(form).map((key) => data.append(key, form?.[key]));
    data.append("user", user?.id);
    // data.append("starting_date", "All");
    // data.append("ending_date", "All");
    // data.append("complaint_type", "All");

    dispatch(report_data(data));
  };
  const handleSubmitDis = (event) => {
    event.preventDefault();
    const data = new FormData();
    const temp_movie_category = form;

    temp_movie_category["tempmovie"] = form?.["movie"];
    temp_movie_category["movie_ownership"] = "Collaborator";
    temp_movie_category["distributor"] = user1?.id;
    temp_movie_category["tempseries"] = form?.["series"];
    temp_movie_category["series"] = series?.data
      .map(
        (series) =>
          form?.["series"]?.includes(series?.series_name) && series?.id
      )
      .filter((e) => e);

    temp_movie_category["movie"] = movies?.data
      .map((movie) => form?.["movie"]?.includes(movie?.movie_name) && movie.id)
      .filter((e) => e);

    Object.keys(form).map((key) => data.append(key, form?.[key]));
    data.append("user", user?.id);
    // data.append("starting_date", "All");
    // data.append("ending_date", "All");
    // data.append("complaint_type", "All");

    dispatch(report_data(data));
  };
  const handleSubmitAvd = (event) => {
    event.preventDefault();
    const data = new FormData();
    const temp_movie_category = form;

    temp_movie_category["tempadvertisement"] = form?.["advertisement"];
    temp_movie_category["report_category"] = "Advertisement";
    // temp_movie_category["advertisement"] = advertisements?.data
    // .map(
    //   (advertisement) =>
    //     form?.["advertisement"]?.includes(advertisement?.product_name) &&
    //     advertisement?.id
    // )
    // .filter((e) => e);
    // temp_movie_category["movie_ownership"] =  "Distributor"
    temp_movie_category["advertiser"] = user2?.id;
    // temp_movie_category["tempseries"] = form?.["series"];
    // temp_movie_category["series"] = series?.data
    //   .map(
    //     (series) =>
    //       form?.["series"]?.includes(series?.series_name) && series?.id
    //   )
    //   .filter((e) => e);

    // temp_movie_category["movie"] = movies?.data
    //   .map((movie) => form?.["movie"]?.includes(movie?.movie_name) && movie.id)
    //   .filter((e) => e);

    Object.keys(form).map((key) => data.append(key, form?.[key]));
    data.append("user", user?.id);
    // data.append("starting_date", "All");
    // data.append("ending_date", "All");
    // data.append("complaint_type", "All");

    dispatch(report_data(data));
  };
  const report_Details = useSelector((state) => state?.reports?.reports);
  useMemo(() => {
    if (report_Details?.statuscode == 200) {
      navigate(
        "/reports/reports_download/",
        {
          state: {
            rData: report_Details,
            report_category: form?.report_category,
            movie_ownership:
              form?.movie_ownership != undefined
                ? form?.movie_ownership
                : form?.series_ownership,
            distributor:
              role !== "distributor" ? form?.tempdistributor : user1?.name,
            plan: form?.tempplan_id,
            TType: form?.transaction_type,
            TCategory: form?.category_type,
            Series_id: form?.tempseries_id,
            Movie_Id: form?.tempmovie_id,
            TVODMovie: form?.movie_id == "All" ? form?.movie_id : null,
            TVODSeries: form?.series_id == "All" ? form?.series_id : null,
          },
        },
        setForm({})
        // role !== "Distributor" && role !== "Advertiser"  && setFormStructure([])
      );
    }
  }, [report_Details]);
  return (
    <div>
      <Form
        formStructure={
          role === "Distributor"
            ? formStructureDis
            : role === "Advertiser"
            ? formStructureAdv
            : formStructure
        }
        handleSubmit={
          role === "Distributor"
            ? handleSubmitDis
            : role === "Advertiser"
            ? handleSubmitAvd
            : handleSubmit
        }
        // formTitle={formTitle}
        key={"Form"}
        setForm={setForm}
        form={form}
      />
    </div>
  );
};

export default Report_field;
