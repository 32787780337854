import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import ProductDetails from "../components/ProductDetails/ProductDetailsContent"
import WBEpisode from "../components/WebSeries/WBEpisode/WBEpisode";

function WebSeriesModule() {
  return (
    <div className="App">
      <Routes>
        <Route path="/WebEpisode" element={<Layout children={WBEpisode()} />} />
        <Route
          path="/CreateWebEpisode"
          element={<Layout children={WBEpisode()} />}
        />
        <Route
          path="/EditWebEpisode"
          element={<Layout children={WBEpisode()} />}
        />
        <Route
          path="/WebEpisode/EpisodeDetails"
          element={<Layout children={ProductDetails()} />}
        />
      </Routes>
    </div>
  );
}

export default WebSeriesModule;
