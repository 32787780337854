import * as actionType from "../constants/actionTypes";

const bankDetailsReducer = (state = { bankdetailsData: null }, action) => {
  switch (action.type) {
    case actionType.BANKDETAILS:
      return { ...state, bankdetails: action.payload };
    case actionType.BANKDETAILSLIST:
      return { ...state, bankdetaillist: action.payload };
    case actionType.BANKDETAILCREATE:
      return { ...state, create_msg: action.payload };
    default:
      return state;
  }
};

export default bankDetailsReducer;
