

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from '../components/Layout/Layout';
// import DistributorsFormData from "../components/ProducerForm/DistributorsFormData"
// import FilmFestivalFormData from '../components/FilmFestivalSubmission/FilmFestivalFormPendingData';
import FilmFestivalDetails from "./FilmFestivalFormDetailsModule"
// import FilmFestivalFormPendingData from '../components/FilmFestivalSubmission/FilmFestivalFormPendingData';
// import FilmFestivalFormShortListData from '../components/FilmFestivalSubmission/FilmFestivalFormShortListData';
// import FilmFestivalFormRejectedData from '../components/FilmFestivalSubmission/FilmFestivalFormRejectedData';
import FilmFestivalFormNominatedData from '../components/FilmFestivalSubmission/FilmFestivalFormNominatedData';



function FilmFestivalFormNominatedDataModule() {
  return (
    <div className="App">
    
<Routes>
{/* < Route path='/FilmFestivalFormPendingData' element={<Layout children={FilmFestivalFormPendingData()}/>}/> */}
{/* < Route path='/FilmFestivalFormShortListData' element={<Layout children={FilmFestivalFormShortListData()}/>}/> */}
{/* < Route path='/FilmFestivalFormRejectedData' element={<Layout children={FilmFestivalFormRejectedData()}/>}/> */}
< Route path='/FilmFestivalNominatedData' element={<Layout children={FilmFestivalFormNominatedData()}/>}/>

< Route path='/FilmFestivalNominatedData/*' element={<FilmFestivalDetails key="FilmFestivalNominatedData"/>}/>

      </Routes>
    </div>
  );
}

export default FilmFestivalFormNominatedDataModule;