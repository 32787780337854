import React from "react";
import { useState, useMemo, useEffect } from "react";
import ListTable from "../../utils/Table";
import Form from "../../utils/Form";
import ViewChange from "../../utils/ViewChange";
import {
  country_create,
  country_delete,
  country_update,
  all_country_list,
} from "../../../actions/Masters/country";

import Import from "../../utils/Import";
import { useLocation, useNavigate } from "react-router-dom";
import Export from "../../utils/Export";
import { useDispatch, useSelector } from "react-redux";
export default function Country() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState(false);
	const rights = useSelector((state) => state.layout.right);

  const [form, setForm] = useState(
    (location?.state?.form && JSON.parse(location?.state?.form)) || {}
  );
  const [view, setView] = useState(location?.state?.view || "view_all");
  useMemo(() => {
    if (isEdit) {
      setView("create_new");
    } else {
      setView("view_all");
      setForm({});
    }
  }, [isEdit]);
  const path = location?.pathname.split("/")[3];
  useEffect(() => {
    setView(path != "Country" ? "create_new" : "view_all");
    setForm((location?.state?.form && JSON.parse(location?.state?.form)) || {});
    setIsEdit(path == "EditCountry");
  }, [location]);
  const [tableData, setTableData] = useState({
    tableTitle: "Country",
    deleteRecord: country_delete,
    deleteAccess: rights?.["Masters"]?.["delete"] == "true",
    updateRecord: country_update,
    onDeleteText: "Are you sure want to delete the Country ?",
    onUpdateText: "Are you Sure?",
    tableHead: [
      {
        id: "country_name",
        numeric: false,
        disablePadding: true,
        label: "Country",
      },
      {
        id: "country_tax",
        label: "Tax (%)",
      },
      {
        id: "currency_symbol",
        label: "Country Symbol",
        // isButtonDisplay: true,
        // isImage: true,
      },
      {
        id: "currency",
        label: "Currency Code",
        // isButtonDisplay: true,
        // isImage: true,
      },
      {
        id: "edit",
        label: "Update",
        access: rights?.["Masters"]?.["edit"] == "true"
      },
    ],
    tableBody: [],
    // filterColumn: [
    // 	{
    // 		id: "1",
    // 		title: "Select Cast Type",
    // 		name: "cast_type",
    // 		options: ["Actor", "Actress"],
    // 	},
    // ],
  });

  const formStructure = [
    {
      id: "1",
      type: "inputBox",
      title: "Country Name",
      name: "country_name",
      // isCaps: true,
      required: true,
    },
    {
      id: "2",
      type: "inputBox",
      title: "Country Symbol",
      name: "currency_symbol",
      required: true,
    },
    {
      id: "3",
      type: "inputBox",
      title: "Currency Code(EX:INR)",
      name: "currency",
      required: true,
      isCaps: true,
    },

    {
      id: "24",
      type: "inputBox",
      variant: "number",
      title: "Tax",
      // size: "2",
      name: "country_tax",
      // disabled: true,
      // display: "none",
      required: true,
    },

    {
      id: "5",
      type: "button",
      title: isEdit ? "Edit" : "Create",
    },
  ];

  const country = useSelector((state) => state.masters.country);
  useEffect(() => {
    dispatch(all_country_list());
  }, []);
  useMemo(() => {
    if (country?.statuscode == 200) {
      const temp = tableData;
      temp.tableBody = country?.data;
      setTableData({ ...temp });
    }
  }, [country]);

  const formTitle = isEdit ? "Edit Cast" : "Create Cast";
  const message = useSelector((state) => state.layout.message);
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData();
    Object.keys(form).map((key) => data.append(key, form?.[key]));

    if (isEdit) {
      dispatch(country_update(data));
    } else {
      dispatch(country_create(data));
    }
  };
  useMemo(() => {
    if (message?.statuscode == 200) {
      setIsEdit(false);
      setForm({});
      setTimeout(() => {
        dispatch(all_country_list());
        navigate("/Masters/Country/Country", { state: { view: "view_all" } });
        setView("view_all");
      }, 1000);
    }
  }, [message]);
  return (
    <>
      <ViewChange
        setForm={setForm}
        setView={setView}
        setIsEdit={setIsEdit}
        view={view}
        isEdit={isEdit}
        create_new={"/Masters/Country/CreateCountry"}
        view_all={"/Masters/Country/Country"}
        form={form}
        access={rights?.["Masters"]?.["create"] == "true"}
        // import_excel={<Import isClubed={true} module_name="cast" />}
        export_excel={
          <Export
            fileName={"Casts"}
            isClubed={true}
            exportData={tableData?.exportData || tableData?.tableBody}
            headings={tableData.tableHead.map((value) => value.label)}
          />
        }
      />

      {view == "create_new" && (
        <Form
          formStructure={formStructure}
          handleSubmit={handleSubmit}
          formTitle={formTitle}
          key={"Form"}
          setForm={setForm}
          form={form}
          tableData={tableData}
          setTableData={setTableData}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
        />
      )}
      {view == "view_all" && (
        <ListTable
          tableData={tableData}
          key={"ListTable"}
          setForm={setForm}
          setTableData={setTableData}
          setIsEdit={setIsEdit}
          create_new={"/Masters/Country/EditCountry"}
        />
      )}
    </>
  );
}
