import React from "react";
import { Box, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import styles from "./MostWatchedMovies.module.css"
import { IMAGE } from "./../../../../api";
import { Link } from "react-router-dom";




const MostWatchedMovies = ({ most_watch_movies }) => {
  // console.log(most_watch_movies, "10000")
  const NewCustomersData = most_watch_movies || [];
  // console.log(NewCustomersData, "0000")
  return (
    <>
      <Card
        sx={{
          boxShadow: "var(--gradientColorLightest2) 0px 6px 12px -2px, var(--gradientColorLightest1) 0px 3px 7px -3px",
          borderRadius: "10px",
          p: "25px 20px",
          mb: "15px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid #EEF0F7",
            paddingBottom: "10px",
            mb: "20px",
          }}
          className="for-dark-bottom-border"
        >
          <Typography
            as="h3"
            sx={{
              fontSize: 18,
              fontWeight: 500,
            }}
          >
          Highest Rented Video
          </Typography>



        </Box>

        <Box>
          {NewCustomersData.slice(0, 5).map((movie) => (
            <div className={styles.newCustomerList} key={movie?.id}>
              <div className={styles.leftContent}>
                <img src={IMAGE + movie?.movie_thumbnail
                } alt="user" />
                <div>
                  <Link to="/Movie/MovieDetails" state={{ id: movie?.id }}>
                  <p style={{ fontWeight: "600" }} >{movie?.movie_name.length > 25 ?movie?.movie_name.substring(0,25)+"..." :movie?.movie_name}</p>
                  </Link>
                  <p>{movie?.movie_category} (<span style={{color:"var(--gradientColor2)"}}>{movie?.movie_subcategory}</span>)</p>
                 
                </div>
              </div>
              <div className={styles.rightContent}>
                <div className={styles.published_by}>
                  {/* <p>{movie?.watch_hours?.watch_hours}</p> */}
                  <p>Rental Count:
                     {movie?.rental_count}</p>
                </div>
                {/* <div className={styles.downloads}>
                  {movie?.movieDownloads||0}
                </div> */}
              </div>
            </div>
          ))}
        </Box>
      </Card>
    </>
  );
};

export default MostWatchedMovies;
