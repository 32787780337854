import React, { useEffect } from "react";
import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  Image,
} from "@react-pdf/renderer";
// import Html from "react-pdf-html";
import ReactDOMServer from "react-dom/server";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import logo from "../../images/logo_final.png";
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { all_customer_list } from "../../actions/customer";
import { Link, useLocation } from "react-router-dom";
import "./Report.css";
import { Grid } from "rsuite";
const Reports = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const reportDetails = location?.state?.rData;
  const category = location?.state?.report_category;
  const Ownership = location?.state?.movie_ownership;
  const Plan = location?.state?.plan;
  const TMovie = location?.state?.TVODMovie;
  const TSeries = location?.state?.TVODSeries;
  const Type = location?.state?.TType;
  const MId = location?.state?.Movie_Id;
  const SId = location?.state?.Series_id;
  console.log(location,"newLocat")
  const Footer = () => (
    
    <View
      style={{
        position: "absolute",
        bottom: -1,
        left: 0,
        right: 0,
        textAlign: "center",
        fontFamily: "Courier", // Set the font family here
      }}
    >
      <Text style={{ fontSize: 10 }}>
        All Rights Reserved by OTT Company Name.
      </Text>
    </View>
  );
  const getCurrentDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const yyyy = today.getFullYear();
    return `${dd}/${mm}/${yyyy}`;
  };
  const MyDoc = ({ reportDetails }) => (
    <Document>
      <Page
        size={
          category == "Movies"
            ? "A4"
            : category == "Series"
            ? "A4"
            : category == "Advertisement"
            ? "A4"
            : "A2"
        }
      >
        <View style={{ fontFamily: "Courier" }}>
          <View
            style={{
              // flexDirection: "row",
              // justifyContent: "space-between",
              marginBottom: 10,
              marginLeft: 10,
            }}
          >
            <Image
              src={logo}
              style={{ height: 35, width: 130, marginTop: 10 }}
            />

            {/* Top Right Date */}
            <Text
              style={{
                position: "absolute",
                top: 10,
                right: 10,
                fontSize: 10,
                fontFamily: "Courier",
              }}
            >
              Date: {getCurrentDate()}
            </Text>

            {/* Company Address */}
            <Text style={{ fontSize: 10, fontWeight: "bold", marginTop: 5 }}>
              B2-602, Westgate Business bay, S G Highway
            </Text>
            <Text style={{ fontSize: 10, fontWeight: "bold" }}>
              Makarba, Ahmedabad, 380051
            </Text>
          </View>
          {/* Customers List Section */}
          {category === "Customer" && (
            <Text
              style={{ fontWeight: "bold", fontSize: 30, textAlign: "center" }}
            >
              Total {category}: {reportDetails?.data?.length}
            </Text>
          )}Plan
          {category === "Complaint" && (
            <Text
              style={{ fontWeight: "bold", fontSize: 30, textAlign: "center" }}
            >
              Total {category}: {reportDetails?.data?.length}
            </Text>
          )}
          {category === "Transaction" && Type == "All" && (
            <Text
              style={{ fontWeight: "bold", fontSize: 30, textAlign: "center" }}
            >
              Total Transactions : {reportDetails?.data?.length}
            </Text>
          )}
          {category === "Transaction" &&
            Plan !== undefined &&
            Type == "SVOD" && (
              <Text
                style={{
                  fontWeight: "bold",
                  fontSize: 30,
                  textAlign: "center",
                }}
              >
                {Plan} Plan Transactions : {reportDetails?.data?.length}
              </Text>
            )}
          {category === "Transaction" &&
            (TMovie != null || TSeries != null) && (
              <Text
                style={{
                  fontWeight: "bold",
                  fontSize: 30,
                  textAlign: "center",
                }}
              >
                All {TMovie != null ? "Movie" : "Series"} Transactions :{" "}
                {reportDetails?.data?.length}
              </Text>
            )}
          {MId !== undefined && category === "Transaction" && (
            <Text
              style={{ fontWeight: "bold", fontSize: 30, textAlign: "center" }}
            >
              {MId} Transactions : {reportDetails?.data?.length}
            </Text>
          )}
          {SId !== undefined && category === "Transaction" && (
            <Text
              style={{ fontWeight: "bold", fontSize: 30, textAlign: "center" }}
            >
              {SId} Transactions : {reportDetails?.data?.length}
            </Text>
          )}
          {category !== ("Customer" || "Complaint") && (
            <Text
              style={{
                fontWeight: "bold",
                fontSize: 30,
                margin: "1px",
                textAlign: "center",
              }}
            >
              {category === "Movies" &&
                reportDetails?.data[0]?.movie_name + " - " + "Report"}
              {category === "Advertisement" &&
                reportDetails?.data[0]?.advertisement_name + " - " + "Report"}
              {category === "Series" &&
                reportDetails?.data[0]?.series_name + " - " + "Report"}
            </Text>
          )}
          <Text style={{ borderBottom: "1px solid black", marginTop: 5 }} />
          {reportDetails?.data?.map((ele, index) => (
            <>
              {category === "Customer" && (
                <View
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    padding: 10,
                    borderRadius: 10,
                    // marginBottom: 12,
                    margin: 15,
                    border: "1px solid #000",
                  }}
                >
                  <View
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "20%",
                    }}
                  >
                    <Text style={{ fontWeight: "bold", fontSize: "25" }}>
                      {ele?.name}
                    </Text>
                  </View>
                  <View style={{ width: "40%" }}>
                    <Text>
                      <Text style={{ fontWeight: "bold", fontSize: "20" }}>
                        {" "}
                        Email:
                      </Text>{" "}
                      <Text>{ele?.email}</Text>
                    </Text>
                    <Text>
                      <Text style={{ fontWeight: "bold", fontSize: "20" }}>
                        {" "}
                        Mobile:
                      </Text>{" "}
                      <Text>{ele?.mobile}</Text>
                    </Text>
                    <Text>
                      <Text style={{ fontWeight: "bold", fontSize: "20" }}>
                        {" "}
                        Location:
                      </Text>{" "}
                      <Text>{ele?.location}</Text>
                    </Text>
                    <Text>
                      <Text style={{ fontWeight: "bold", fontSize: "20" }}>
                        {" "}
                        Registration_date:
                      </Text>
                      <Text>{ele?.registration_date}</Text>
                    </Text>
                  </View>
                  <View style={{ width: "40%" }}>
                    <Text>
                      <Text style={{ fontWeight: "bold", fontSize: "20" }}>
                        {" "}
                        Total_movie_watched:
                      </Text>
                      <Text>{ele?.total_movie_watched}</Text>
                    </Text>
                    <Text>
                      <Text style={{ fontWeight: "bold", fontSize: "20" }}>
                        {" "}
                        Total_rented_movie:
                      </Text>
                      <Text>{ele?.total_rented_movie}</Text>
                    </Text>
                    <Text>
                      <Text style={{ fontWeight: "bold", fontSize: "20" }}>
                        {" "}
                        Total_downloads:
                      </Text>
                      <Text>{ele?.total_downloads}</Text>
                    </Text>
                    {/* <Text>
                      <Text style={{ fontWeight: "bold", fontSize: "20" }}>
                        {" "}
                        Total_profile:
                      </Text>{" "}
                      <Text>{ele?.total_profile}</Text>
                    </Text> */}
                  </View>
                </View>
              )}
              {category === "Complaint" && (
                <View
                  key={index}
                  style={{
                    padding: 10,
                    borderRadius: 10,
                    // marginBottom: 12,
                    margin: 15,
                    border: "1px solid #000",
                  }}
                >
                  <View
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "35%",
                      }}
                    >
                      <Text style={{ fontWeight: "bold", fontSize: "25" }}>
                        {ele?.complaint_type}
                      </Text>
                    </View>
                    <View style={{ width: "40%" }}>
                      <Text>
                        <Text style={{ fontWeight: "bold", fontSize: "20" }}>
                          {" "}
                          Name:
                        </Text>{" "}
                        <Text>{ele?.first_name + " " + ele?.last_name}</Text>
                      </Text>
                      <Text>
                        <Text style={{ fontWeight: "bold", fontSize: "20" }}>
                          {" "}
                          Email:
                        </Text>{" "}
                        <Text>{ele?.email}</Text>
                      </Text>
                      <Text>
                        <Text style={{ fontWeight: "bold", fontSize: "20" }}>
                          {" "}
                          Number:
                        </Text>{" "}
                        <Text>{ele?.mobileNumber}</Text>
                      </Text>
                    </View>
                    <View style={{ width: "25%" }}>
                      <Text>
                        <Text style={{ fontWeight: "bold", fontSize: "20" }}>
                          {" "}
                          status :{" "}
                        </Text>
                        <Text>{ele?.status}</Text>
                      </Text>
                      <Text>
                        <Text style={{ fontWeight: "bold", fontSize: "20" }}>
                          {" "}
                          Create At :{" "}
                        </Text>
                        <Text>{ele?.created_at}</Text>
                      </Text>
                      <Text>
                        <Text style={{ fontWeight: "bold", fontSize: "20" }}>
                          {" "}
                          Closed_at :{" "}
                        </Text>
                        <Text>{ele?.closed_at}</Text>
                      </Text>
                    </View>
                  </View>
                  <View>
                    <Text style={{ fontSize: "15" }}>
                      description : {ele?.description}
                    </Text>
                  </View>
                </View>
              )}
              {category === "Advertisement" && (
                <View>
                  {/* <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      padding: 10,
                      borderRadius: 10,
                      margin: 12,
                      backgroundColor: "#000",
                      border: "1px solid #000",
                    }}
                  >
                    <Text style={{ fontWeight: "bold" }}>
                      Advertisement Name :
                    </Text>
                    <Text>{reportDetails?.data[0]?.advertisement_name}</Text>
                  </View> */}
                  <View>
                    {[
                      {
                        label: "Advertisement Name :",
                        value: reportDetails?.data[0]?.advertisement_name,
                      },
                      {
                        label: "Company Name :",
                        value: reportDetails?.data[0]?.advertiser_company,
                      },
                      {
                        label: "Advertiser Name :",
                        value: `${reportDetails?.data[0]?.advertiser_name}`,
                      },
                      {
                        label: "PPV Amount :",
                        value: `${reportDetails?.data[0]?.commission / 100} %`,
                      },
                      {
                        label: "Payment Status",
                        value: reportDetails?.data[0]?.payment_status,
                      },
                      {
                        label: "Recharge Amount :",
                        value: `${reportDetails?.data[0]?.total_recharge_amount} INR`,
                      },
                      {
                        label: "Subscription Type :",
                        value: `${reportDetails?.data[0]?.subscription_type}`,
                      },
                      {
                        label: "Required View :",
                        value: `${reportDetails?.data[0]?.required_view}`,
                      },
                      {
                        label: "Total Seen :",
                        value: reportDetails?.data[0]?.total_seen,
                      },
                      {
                        label: "Available Views :",
                        value: reportDetails?.data[0]?.available_views,
                      },
                    ]
                      .map((row, index) => (
                        <View
                          key={index}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            padding: 10,
                            borderRadius: 10,
                            margin: 12,
                            border: "1px solid #000",
                          }}
                        >
                          <Text style={{ fontWeight: "bold" }}>
                            {row.label}
                          </Text>
                          <Text>{row.value}</Text>
                        </View>
                      ))
                      .filter((e) => e)}
                  </View>
                </View>
              )}
              {category === "Movies" && (
                <View>
                  {/* Table Header */}
                  {/* {Ownership == "Distributor" && (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        padding: 10,
                        borderRadius: 10,
                        margin: 11,
                        backgroundColor: "#000",
                        border: "1px solid #000",
                      }}
                    >
                      <Text style={{ fontWeight: "bold" }}>
                        Distributor Name :
                      </Text>
                      <Text>{location?.state?.Distributor}</Text>
                    </View>
                  )} */}

                  {/* Table Body */}
                  <View>
                    {[
                      Ownership == "Collaborator" && {
                        label: "Collaborator Name :",
                        value: location?.state?.distributor,
                      },
                      {
                        label: "Movie Name :",
                        value: reportDetails?.data[0]?.movie_name,
                      },
                      {
                        label: "TVOD Amount :",
                        value: `${reportDetails?.data[0]?.tvod_amount} INR`,
                      },

                      {
                        label: "Total Rental :",
                        value: reportDetails?.data[0]?.total_rental_count,
                      },
                      {
                        label: "Total Earning :",
                        value: `${reportDetails?.data[0]?.total_earning} INR`,
                      },
                      {
                        label: "Tax amount :",
                        value: `${reportDetails?.data[0]?.tax_amount} INR`,
                      },
                      {
                        label: "Admin Earning :",
                        value: `${reportDetails?.data[0]?.admin_profit} INR`,
                      },
                      Ownership === "Collaborator" && {
                        label: "Collaborator Earning :",
                        value: `${reportDetails?.data[0]?.collabrator_profit} INR`,
                      },
                      {
                        label: "View :",
                        value: reportDetails?.data[0]?.movie_view,
                      },
                      // {
                      //   label: "Likes :",
                      //   value: reportDetails?.data[0]?.movie_like,
                      // },
                      {
                        label: "Downloads :",
                        value: reportDetails?.data[0]?.downloads,
                      },
                    ]
                      .filter((e) => e)
                      .map((row, index) => (
                        <View
                          key={index}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            padding: 10,
                            borderRadius: 10,
                            margin: 11,
                            border: "1px solid #000",
                          }}
                        >
                          <Text style={{ fontWeight: "bold" }}>
                            {row?.label}
                          </Text>
                          ,
                          <Text> {row?.value}</Text>
                        </View>
                      ))
                      .filter((e) => e)}
                  </View>
                </View>
              )}
              {category === "Series" && (
                <View>
                  {/* Table Header */}
                  {/* {Ownership === "Distributor" && (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        padding: 10,
                        borderRadius: 10,
                        margin: 12,
                        backgroundColor: "#000",
                        border: "1px solid #000",
                      }}
                    >
                      <Text style={{ fontWeight: "bold" }}>
                        Distributor Name :
                      </Text>
                      <Text>{location?.state?.Distributor}</Text>
                    </View>
                  )} */}

                  {/* Table Body */}
                  <View>
                    {[
                      Ownership == "Collaborator" && {
                        label: "Collaborator Name :",
                        value: location?.state?.distributor,
                      },
                      {
                        label: "Series Name :",
                        value: reportDetails?.data[0]?.series_name,
                      },
                      {
                        label: "Total Season :",
                        value: reportDetails?.data[0]?.total_season,
                      },
                      {
                        label: "Total Episode :",
                        value: reportDetails?.data[0]?.total_episode,
                      },
                      {
                        label: "TVOD Amount :",
                        value: `${reportDetails?.data[0]?.tvod_amount} INR`,
                      },

                      {
                        label: "Total Rental :",
                        value: reportDetails?.data[0]?.total_rental_count,
                      },
                      {
                        label: "Total Earning :",
                        value: `${reportDetails?.data[0]?.total_earning} INR`,
                      },
                      {
                        label: "Tax amount :",
                        value: `${reportDetails?.data[0]?.tax_amount} INR`,
                      },
                      {
                        label: "Admin Earning :",
                        value: `${reportDetails?.data[0]?.admin_profit} INR`,
                      },
                      Ownership === "Collaborator" && {
                        label: "Collaborator Earning :",
                        value: `${reportDetails?.data[0]?.collabrator_profit} INR`,
                      },
                    ]
                      .filter((e) => e)
                      .map((row, index) => (
                        <View
                          key={index}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            padding: 10,
                            borderRadius: 10,
                            margin: 12,
                            border: "1px solid #000",
                          }}
                        >
                          <Text style={{ fontWeight: "bold" }}>
                            {row?.label}
                          </Text>
                          <Text> {row?.value}</Text>
                        </View>
                      ))
                      .filter((e) => e)}
                  </View>
                </View>
              )}
              {category === "Transaction" && (
                <View
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    padding: 10,
                    borderRadius: 10,
                    // marginBottom: 12,
                    margin: 15,
                    border: "1px solid #000",
                  }}
                >
                  <View
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "30%",
                    }}
                  >
                    <Text style={{ fontWeight: "bold", fontSize: "25" }}>
                      {ele?.name}
                    </Text>
                  </View>
                  <View style={{ width: "40%" }}>
                    <Text>
                      <Text style={{ fontWeight: "bold", fontSize: "20" }}>
                        {" "}
                        Email:
                      </Text>{" "}
                      <Text>{ele?.email}</Text>
                    </Text>
                    <Text>
                      <Text style={{ fontWeight: "bold", fontSize: "20" }}>
                        {" "}
                        Mobile:
                      </Text>{" "}
                      <Text>{ele?.mobile_no}</Text>
                    </Text>
                    {/* <Text>
                      <Text style={{ fontWeight: "bold", fontSize: "20" }}>
                        {" "}
                        Location:
                      </Text>{" "}
                      <Text>{ele?.location}</Text>
                    </Text> */}
                    <Text>
                      <Text style={{ fontWeight: "bold", fontSize: "20" }}>
                        {" "}
                        Date:
                      </Text>
                      <Text>{ele?.date}</Text>
                    </Text>
                  </View>
                  <View style={{ width: "30%" }}>
                    <Text>
                      <Text style={{ fontWeight: "bold", fontSize: "20" }}>
                        {" "}
                        Category Type :
                      </Text>
                      <Text>{ele?.category_type}</Text>
                    </Text>
                    <Text>
                      <Text style={{ fontWeight: "bold", fontSize: "20" }}>
                        {" "}
                        payment Amount :
                      </Text>
                      <Text>{ele?.payment_amount} INR</Text>
                    </Text>
                    <Text>
                      <Text style={{ fontWeight: "bold", fontSize: "20" }}>
                        {" "}
                        Payment Id :
                      </Text>
                      <Text>{ele?.payment_id}</Text>
                    </Text>
                    
                  </View>
                </View>
              )}
            </>
          ))}

          <Footer />
        </View>
      </Page>
    </Document>
  );
  return (
    <>
      <div style={{ color: "#000" }}>
        <br />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "-2rem",
          }}
        >
          <span style={{ fontWeight: "1000", fontSize: "15px" }}>
            {category === "Complaint"  && (
              <b>
                Total {category} : {reportDetails?.data.length}
              </b>
            )}
             {category ===  "Customer" && (
              <b>
                Total {category} : {reportDetails?.data.length}
              </b>
            )}
            {category === "Movies" && (
              <b>{reportDetails?.data[0]?.movie_name} Report</b>
            )}
            {category === "Advertisement" && (
              <b>{reportDetails?.data[0]?.advertisement_name} Report</b>
            )}
            {category === "Series" && (
              <b>{reportDetails?.data[0]?.series_name} Report</b>
            )}
            {category === "Transaction" && (
              <b> Total Transactions : {reportDetails?.data.length} </b>
            )}
           
          
        
          </span>
          <div style={{ display: "flex", gap: "1rem" }}>
            <Button
              variant="contained"
              style={{
                background:
                  "linear-gradient(225deg,  var(--gradientColor1) 0%, var(--gradientColor2) 91.25%)",
              }}
              sx={{
                mt: 1,
                textTransform: "capitalize",
                borderRadius: "8px",
                fontWeight: "500",
                fontSize: "13px",
                padding: "12px 20px",
                minWidth: "0",
                minHeight: "0",
                color: "#000 !important",
              }}
            >
              <PDFDownloadLink
                document={<MyDoc reportDetails={reportDetails} />}
                fileName={`${category}.pdf`}
              >
                {({ blob, url, loading, error }) => (
                  <span style={{ color: "white" }}>
                    {" "}
                    {loading ? "Loading..." : "Download PDF"}
                  </span>
                )}
              </PDFDownloadLink>
            </Button>
            <Button
              variant="contained"
              style={{
                background:
                  "linear-gradient(225deg,  var(--gradientColor1) 0%, var(--gradientColor2) 91.25%)",
              }}
              sx={{
                mt: 1,
                textTransform: "capitalize",
                borderRadius: "8px",
                fontWeight: "500",
                fontSize: "13px",
                padding: "12px 20px",
                minWidth: "0",
                minHeight: "0",

                color: "#fff !important",
              }}
            >
              <Link to={"/reports/reports/"} style={{ color: "#fff" }}>
                Back
              </Link>
            </Button>
          </div>
        </div>
        <br />
        <hr />
        <br />
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            marginBottom: "12px",
          }}
        >
          {category === "Customer" && (
            <thead>
              <tr>
                <th
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                    borderRadius: "10px",
                  }}
                >
                  Name
                </th>
                <th
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                    borderRadius: "10px",
                  }}
                >
                  Mobile
                </th>
                <th
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                    borderRadius: "10px",
                  }}
                >
                  Email
                </th>
                <th
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                    borderRadius: "10px",
                  }}
                >
                  Location
                </th>
                <th
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                    borderRadius: "10px",
                  }}
                >
                  Registration Date
                </th>
                <th
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                    borderRadius: "10px",
                  }}
                >
                  Total Movies Watched
                </th>
                <th
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                    borderRadius: "10px",
                  }}
                >
                  Total Rented Movies
                </th>
                <th
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                    borderRadius: "10px",
                  }}
                >
                  Total Downloads
                </th>
                <th
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                    borderRadius: "10px",
                  }}
                >
                  Total Profile
                </th>
              </tr>
            </thead>
          )}
          {category === "Complaint" && (
            <thead>
              <tr>
                <th
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                    borderRadius: "10px",
                  }}
                >
                  Type
                </th>
                <th
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                    borderRadius: "10px",
                  }}
                >
                  Name
                </th>
                <th
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                    borderRadius: "10px",
                  }}
                >
                  Mobile
                </th>
                <th
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                    borderRadius: "10px",
                  }}
                >
                  Email
                </th>
                <th
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                    borderRadius: "10px",
                  }}
                >
                  Status
                </th>
                <th
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                    borderRadius: "10px",
                  }}
                >
                  Create At
                </th>
                <th
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                    borderRadius: "10px",
                  }}
                >
                  Close At
                </th>
              </tr>
            </thead>
          )}
          {category === "Transaction" && (
            <thead>
              <tr>
                <th
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                    borderRadius: "10px",
                  }}
                >
                  Type
                </th>
                <th
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                    borderRadius: "10px",
                  }}
                >
                  Name
                </th>
                <th
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                    borderRadius: "10px",
                  }}
                >
                  Mobile
                </th>
                <th
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                    borderRadius: "10px",
                  }}
                >
                  Email
                </th>
                <th
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                    borderRadius: "10px",
                  }}
                >
                  Date
                </th>
                <th
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                    borderRadius: "10px",
                  }}
                >
                  payment Amount
                </th>
                <th
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                    borderRadius: "10px",
                  }}
                >
                  Payment Id
                </th>
                
              </tr>
            </thead>
          )}
          {category === "Customer" && (
            <tbody>
              {reportDetails?.data?.map((ele, index) => (
                <tr key={index}>
                  <td
                    style={{
                      border: "1px solid #000",
                      padding: "10px",
                      textAlign: "center",
                    }}
                  >
                    {ele?.name}
                  </td>
                  <td
                    style={{
                      border: "1px solid #000",
                      padding: "10px",
                      textAlign: "center",
                    }}
                  >
                    {ele?.email}
                  </td>
                  <td
                    style={{
                      border: "1px solid #000",
                      padding: "10px",
                      textAlign: "center",
                    }}
                  >
                    {ele?.mobile}
                  </td>
                  <td
                    style={{
                      border: "1px solid #000",
                      padding: "10px",
                      textAlign: "center",
                    }}
                  >
                    {ele?.location}
                  </td>
                  <td
                    style={{
                      border: "1px solid #000",
                      padding: "10px",
                      textAlign: "center",
                    }}
                  >
                    {ele?.registration_date}
                  </td>
                  <td
                    style={{
                      border: "1px solid #000",
                      padding: "10px",
                      textAlign: "center",
                    }}
                  >
                    {ele?.total_movie_watched}
                  </td>
                  <td
                    style={{
                      border: "1px solid #000",
                      padding: "10px",
                      textAlign: "center",
                    }}
                  >
                    {ele?.total_rented_movie}
                  </td>
                  <td
                    style={{
                      border: "1px solid #000",
                      padding: "10px",
                      textAlign: "center",
                    }}
                  >
                    {ele?.total_downloads}
                  </td>
                  <td
                    style={{
                      border: "1px solid #000",
                      padding: "10px",
                      textAlign: "center",
                    }}
                  >
                    {ele?.total_profile}
                  </td>
                </tr>
              ))}
            </tbody>
          )}
          {category === "Complaint" && (
            <tbody>
              {reportDetails?.data?.map((ele, index) => (
                <tr key={index}>
                  <td
                    style={{
                      border: "1px solid #000",
                      padding: "10px",
                      textAlign: "center",
                    }}
                  >
                    {ele?.complaint_type}
                  </td>
                  <td
                    style={{
                      border: "1px solid #000",
                      padding: "10px",
                      textAlign: "center",
                    }}
                  >
                    {ele?.first_name + " " + ele?.last_name}
                  </td>
                  <td
                    style={{
                      border: "1px solid #000",
                      padding: "10px",
                      textAlign: "center",
                    }}
                  >
                    {ele?.email}
                  </td>
                  <td
                    style={{
                      border: "1px solid #000",
                      padding: "10px",
                      textAlign: "center",
                    }}
                  >
                    {ele?.mobileNumber}
                  </td>
                  <td
                    style={{
                      border: "1px solid #000",
                      padding: "10px",
                      textAlign: "center",
                    }}
                  >
                    {ele?.status}
                  </td>
                  <td
                    style={{
                      border: "1px solid #000",
                      padding: "10px",
                      textAlign: "center",
                    }}
                  >
                    {ele?.created_at}
                  </td>
                  <td
                    style={{
                      border: "1px solid #000",
                      padding: "10px",
                      textAlign: "center",
                    }}
                  >
                    {ele?.closed_at}
                  </td>
                </tr>
              ))}
            </tbody>
          )}
          {category === "Movies" && Ownership === "Collaborator" && (
            <thead>
              <tr>
                <th
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                    borderRadius: "10px",
                  }}
                >
                  Collaborator Name :
                </th>
                <th
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                    borderRadius: "10px",
                  }}
                >
                  {location?.state?.distributor}
                </th>
              </tr>
            </thead>
          )}
          {category === "Movies" && (
            <tbody>
              <tr key="1">
                <td
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  Movie Name :
                </td>
                <td
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  {reportDetails?.data[0]?.movie_name}
                </td>
              </tr>
              <tr key="2">
                <td
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  TVOD Amount :
                </td>
                <td
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  {reportDetails?.data[0]?.tvod_amount} INR
                </td>
              </tr>
              <tr key="3">
                <td
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  Total Earning:
                </td>
                <td
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  {reportDetails?.data[0]?.total_earning} INR
                </td>
              </tr>
              <tr key="4">
                <td
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  Total Rental
                </td>
                <td
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  {reportDetails?.data[0]?.total_rental_count}
                </td>
              </tr>
              <tr key="5">
                <td
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  Tax amount :
                </td>
                <td
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  {reportDetails?.data[0]?.tax_amount} INR
                </td>
              </tr>
              <tr key="6">
                <td
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  Admin Earning :
                </td>
                <td
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  {reportDetails?.data[0]?.admin_profit} INR
                </td>
              </tr>
              {Ownership === "Collaborator" ? (
                <tr key="7">
                  <td
                    style={{
                      border: "1px solid #000",
                      padding: "10px",
                      textAlign: "center",
                    }}
                  >
                    Collaborator Earning :
                  </td>
                  <td
                    style={{
                      border: "1px solid #000",
                      padding: "10px",
                      textAlign: "center",
                    }}
                  >
                    {reportDetails?.data[0]?.collabrator_profit} INR
                  </td>
                </tr>
              ) : (
                ""
              )}
              <tr key="8">
                <td
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  View :
                </td>
                <td
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  {reportDetails?.data[0]?.movie_view}
                </td>
              </tr>
              {/* <tr key="9">
                <td
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  Likes :
                </td>
                <td
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  {reportDetails?.data[0]?.movie_like}
                </td>
              </tr> */}
              <tr key="10">
                <td
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  Downloads :
                </td>
                <td
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  {reportDetails?.data[0]?.downloads}
                </td>
              </tr>
            </tbody>
          )}
          {category === "Series" && Ownership === "Collaborator" && (
            <thead>
              <tr>
                <th
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                    borderRadius: "10px",
                  }}
                >
                  Collaborator Name :
                </th>
                <th
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                    borderRadius: "10px",
                  }}
                >
                  {location?.state?.distributor}
                </th>
              </tr>
            </thead>
          )}
          {category === "Series" && (
            <tbody>
              <tr key="1">
                <td
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  Series Name :
                </td>
                <td
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  {reportDetails?.data[0]?.series_name}
                </td>
              </tr>
              <tr key="1">
                <td
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  Total Season :
                </td>
                <td
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  {reportDetails?.data[0]?.total_season}
                </td>
              </tr>
              <tr key="1">
                <td
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  Total Episode :
                </td>
                <td
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  {reportDetails?.data[0]?.total_episode}
                </td>
              </tr>
              <tr key="2">
                <td
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  TVOD Amount :
                </td>
                <td
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  {reportDetails?.data[0]?.tvod_amount} INR
                </td>
              </tr>
              <tr key="3">
                <td
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  Total Earning:
                </td>
                <td
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  {reportDetails?.data[0]?.total_earning} INR
                </td>
              </tr>
              <tr key="4">
                <td
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  Total Rental
                </td>
                <td
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  {reportDetails?.data[0]?.total_rental_count}
                </td>
              </tr>
              <tr key="5">
                <td
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  Tax amount :
                </td>
                <td
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  {reportDetails?.data[0]?.tax_amount} INR
                </td>
              </tr>
              <tr key="6">
                <td
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  Admin Earning :
                </td>
                <td
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  {reportDetails?.data[0]?.admin_profit} INR
                </td>
              </tr>
              {Ownership === "Collaborator" ? (
                <tr key="7">
                  <td
                    style={{
                      border: "1px solid #000",
                      padding: "10px",
                      textAlign: "center",
                    }}
                  >
                    Collaborator Earning :
                  </td>
                  <td
                    style={{
                      border: "1px solid #000",
                      padding: "10px",
                      textAlign: "center",
                    }}
                  >
                    {reportDetails?.data[0]?.collabrator_profit} INR
                  </td>
                </tr>
              ) : (
                ""
              )}
            </tbody>
          )}
          {category === "Advertisement" && (
            <tbody>
              <tr key="0">
                <td
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  Advertisement Name :
                </td>
                <td
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  {reportDetails?.data[0]?.advertisement_name}
                </td>
              </tr>
              <tr key="1">
                <td
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  Company Name :
                </td>
                <td
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  {reportDetails?.data[0]?.advertiser_company}
                </td>
              </tr>
              <tr key="2">
                <td
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  Advertiser Name :
                </td>
                <td
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  {reportDetails?.data[0]?.advertiser_name}
                </td>
              </tr>
              <tr key="3">
                <td
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  PPV Amount :
                </td>
                <td
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  {reportDetails?.data[0]?.commission / 100}
                </td>
              </tr>
              <tr key="4">
                <td
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  Payment Status
                </td>
                <td
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  {reportDetails?.data[0]?.payment_status}
                </td>
              </tr>
              <tr key="5">
                <td
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  Recharge Amount :
                </td>
                <td
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  {reportDetails?.data[0]?.total_recharge_amount} INR
                </td>
              </tr>
              <tr key="6">
                <td
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  Subscription Type :
                </td>
                <td
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  {reportDetails?.data[0]?.subscription_type}
                </td>
              </tr>
              {Ownership === "Collaborator" ? (
                <tr key="7">
                  <td
                    style={{
                      border: "1px solid #000",
                      padding: "10px",
                      textAlign: "center",
                    }}
                  >
                    Required View :
                  </td>
                  <td
                    style={{
                      border: "1px solid #000",
                      padding: "10px",
                      textAlign: "center",
                    }}
                  >
                    {reportDetails?.data[0]?.required_view}
                  </td>
                </tr>
              ) : (
                ""
              )}
              <tr key="8">
                <td
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  Total Seen :
                </td>
                <td
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  {reportDetails?.data[0]?.total_seen}
                </td>
              </tr>
              <tr key="9">
                <td
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  Available Views :
                </td>
                <td
                  style={{
                    border: "1px solid #000",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  {reportDetails?.data[0]?.available_views}
                </td>
              </tr>
            </tbody>
          )}
          {category === "Transaction" && (
            <tbody>
              {reportDetails?.data?.map((ele, index) => (
                <tr key={index}>
                  <td
                    style={{
                      border: "1px solid #000",
                      padding: "10px",
                      textAlign: "center",
                    }}
                  >
                    {ele?.category_type}
                  </td>
                  <td
                    style={{
                      border: "1px solid #000",
                      padding: "10px",
                      textAlign: "center",
                    }}
                  >
                    {ele?.name}
                  </td>
                  <td
                    style={{
                      border: "1px solid #000",
                      padding: "10px",
                      textAlign: "center",
                    }}
                  >
                    {ele?.mobile_no}
                  </td>
                  <td
                    style={{
                      border: "1px solid #000",
                      padding: "10px",
                      textAlign: "center",
                    }}
                  >
                    {ele?.email}
                  </td>
                  <td
                    style={{
                      border: "1px solid #000",
                      padding: "10px",
                      textAlign: "center",
                    }}
                  >
                    {ele?.date}
                  </td>
                  <td
                    style={{
                      border: "1px solid #000",
                      padding: "10px",
                      textAlign: "center",
                    }}
                  >
                    {ele?.payment_amount} INR
                  </td>
                  <td
                    style={{
                      border: "1px solid #000",
                      padding: "10px",
                      textAlign: "center",
                    }}
                  >
                    {ele?.payment_id}
                  </td>
                 
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
    </>
  );
};

export default Reports;
