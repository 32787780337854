

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from '../components/Layout/Layout';
import FilmFestivalFormDetail from '../components/FilmFestivalFormDetail/FilmFestivalFormDetail';


function FilmFestivalFormDetailsModule() {
  return (
    <div className="App">
    
<Routes>
< Route path='/FilmFestivalFormDetails' element={<Layout children={FilmFestivalFormDetail()}/>}/>
      </Routes>
    </div>
  );
}

export default FilmFestivalFormDetailsModule;
