import { combineReducers } from 'redux';
import masters from './masters';
import subscriptions from './subscriptions'
import customers from './customers'
import merchandise from './merchandise'
import movies from './movies'
import transactions from "./transactions"
import watchhours from "./watchhours"
import webseries from './webseries';
import setting from './setting'
import dashboard from './dashboard'
import reports from "./reports"
import bankDetails from "./bankdetails"
import layout from "./layout"
import producer from './producer'
import subtitle from "./subtitleReducer"
import filmFestival from "./filmfestival"
import payment from "./payment"
import complaints from "./complaints"
export const reducers = combineReducers({ masters,reports,filmFestival,payment, bankDetails, complaints,  layout, dashboard, setting, subscriptions, customers,  merchandise, movies,subtitle,  transactions,producer, watchhours, webseries });
