import React from 'react'
import { useState, useMemo, useEffect } from "react";
import ListTable from "../utils/Table";
import {
	
	all_film_festival_movie_form_list,
} from "../../actions/filmfestival";
import { useDispatch, useSelector } from "react-redux";


const FilmFestivalFormNominatedData = () => {
    const dispatch = useDispatch();

    const [isEdit, setIsEdit] = useState(false);
	const [form, setForm] = useState({});

    const tempTableData={
		tableTitle: "Film Festival Nominated Video Collections",
		disableDelete: true,	

		tableHead: [
			{
				id: "production_name",
				label: "Production Name",
				subText:"distribution_name",
				link:"/FilmFestivalNominatedData/FilmFestivalNominatedData/FilmFestivalFormDetails"
			},
			{
				id:"created_at",
				label:"Data"
			},
			{
				id: "type_of_film1",
				label: "Video Title",
				subText:"category_type"
			},
			{
				id: "email",
				label: "Contact info" ,
				subText:"mobileNumber"
			},
			{
				id: "language",
				label: "Language" ,
			},
			{
				id: "rent1",
				label: "Rental Amount" ,
			},
			{
				id: "status",
				label: "Status" ,
				isButtonDisplay: true
			},
			
			
		],
		tableBody: [
			
		],
		isDateRangeFilter: "created_at",
	}
	const [tableData, setTableData] = useState({...tempTableData});
    const film_festival_data = useSelector((state) => state.filmFestival.film_festival_data);
    // console.log(film_festival_data)
	useEffect(() => {
		dispatch(all_film_festival_movie_form_list({status:"Nominated"}));
	}, []);
		useMemo(() => {
			if (film_festival_data?.statuscode == 200) {
				const temp = tableData;
				temp.tableBody = film_festival_data?.data.map((ele)=> ({...ele,
					rent1 :parseFloat(ele.rent).toFixed(2) ,
					type_of_film1: ele?.category_type =="Web Series" ? ele?.type_of_series : ele?.type_of_film
				
				}));
				setTableData({ ...temp });
			}
		}, [film_festival_data]);
  return (
    <>
			
    { <ListTable
        tableData={tableData}
        key={"ListTable"}
        setForm={setForm}
        setTableData={setTableData}
        setIsEdit={setIsEdit}
    />}

</>
  )
}
// all_film_festival_movie_form_list
export default FilmFestivalFormNominatedData