import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Features from "./elements/Features";
import RegisteredUsers from "./elements/RegisteredUsers";
import DailyRevenue from "./elements/DailyRevenue";
import TotalRevenue from "./elements/TotalRevenue";
import SubscribedUsers from "./elements/SubscribedUsers";
import HighestSubscription from "./elements/HighestSubscription";
import MostWatchedMovies from "./elements/MostWatchedMovies";
import MostWatchedSeries from "./elements/MostWatchedSeries";
import RecentlyAddedMovies from "./elements/RecentlyAddedMovies";
import RecentSubscriber from "./elements/RecentSubscriber";
import Renewal from "./elements/Renewal";
import MonthlyRevenue from "./elements/MonthlyRevenue";
import {
  movies_data_dashboard,
  count_on_dashboard,
  total_subscribe_user,
  most_watch_tv_show_dashboard,
  recent_subscriber_dashboard,
} from "../../actions/dashboard";
import { useDispatch, useSelector } from "react-redux";
import RecentlyAddedSeries from "./elements/RecentlyAddedSeries";
import { all_producer_list } from "../../actions/producer";

export default function Dashboard() {
  const user = useSelector((state) => state.layout.profile);
  const role = useSelector((state) => state.layout.role);
  const distributors = useSelector((state) => state.producer.producer);
console.log(distributors,"neweDiszfdc")
  const dispatch = useDispatch();
  const data = new FormData();
  data.append("id", user?.id);
  useEffect(() => {
    if(user){

      dispatch(movies_data_dashboard(data));
      role != "Distributor" &&   dispatch(count_on_dashboard(data));
      role != "Distributor" &&  dispatch(total_subscribe_user(data));
      // dispatch(most_watch_tv_show_dashboard(data))
      role != "Distributor" &&  dispatch(recent_subscriber_dashboard(data));
    }
  }, [user]);
  const registeredUsers = useSelector((state) => state.dashboard.totalusers);
  const most_watch_series = useSelector((state) => state.dashboard?.dashboardmovies?.most_watch_series);
  const recently_added_series = useSelector((state) => state.dashboard?.dashboardmovies?.recently_added_series);


  const dashboardcount = useSelector(
    (state) => state.dashboard.dashboardcount
  );
  // const dashboardcount1 = useSelector(
  //   (state) => state.dashboard.dashboardcount
  // );
  // console.log(recently_added_series, "beeeee");
  const recently_added_data = useSelector(
    (state) => state.dashboard.dashboardmovies?.recently_added_data
  );
  const most_watch_movies = useSelector(
    (state) => state.dashboard.dashboardmovies?.most_watch_movies
  );
  // console.log(most_watch_movies,"11111")

  const monthlyrevenue = useSelector(
    (state) => state.dashboard?.monthlyrevenue
  );
  // const tv_show_dashboard = useSelector((state) => state.dashboard?.tv_show_dashboard);
  const recentSubscriberDashboard = useSelector(
    (state) => state.dashboard?.recent_subscriber_dashboard
  );
  return (
    // <>
    //   <RegisteredUsers registeredUsers={registeredUsers} />

    //   <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
    //     <Grid item xs={12} md={12} lg={12} xl={8}>
    //       <Features dashboardcount={dashboardcount} />

    //       <Grid container columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
    //         <Grid item xs={12} md={12}>
    //           <MonthlyRevenue monthlyrevenue={monthlyrevenue} />
    //         </Grid>
    //         <Grid item xs={12} md={12}>

    //         </Grid>
    //         <Grid item xs={12} md={12}>
    //           <RecentlyAddedMovies recently_added_data={recently_added_data} />
    //         </Grid>
    //         <Grid item xs={12} md={12}>
    //           <RecentlyAddedSeries recently_added_series={recently_added_series} />
    //         </Grid>
    //       </Grid>
    //     </Grid>

    //     <Grid item xs={12} md={12} lg={12} xl={4}>
    //       <DailyRevenue />
    //       <MostWatchedMovies most_watch_movies={most_watch_movies} />
    //       <MostWatchedSeries tv_show_dashboard={most_watch_series}/>
    //     </Grid>
    //   </Grid>
    // </>
    <>
   {role != "Distributor" &&  <RegisteredUsers registeredUsers={registeredUsers} />}

    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
    <Grid item xs={12} md={12} lg={12} xl={12}>
   {role != "Distributor" &&  <Features dashboardcount={dashboardcount} />}

    </Grid>
      <Grid item xs={12} md={12} lg={12} xl={8}>

        <Grid container columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
          <Grid item xs={12} md={12}>
            <MonthlyRevenue monthlyrevenue={monthlyrevenue} />
          </Grid>
          <Grid item xs={12} md={12}>

          </Grid>
          <Grid item xs={12} md={12}>
            <RecentlyAddedMovies recently_added_data={recently_added_data} />
          </Grid>
          <Grid item xs={12} md={12}>
            <RecentlyAddedSeries recently_added_series={recently_added_series} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={12} lg={12} xl={4}>
        <DailyRevenue />
        <MostWatchedMovies most_watch_movies={most_watch_movies} />
        <MostWatchedSeries tv_show_dashboard={most_watch_series}/>
      </Grid>
    </Grid>
  </>
  );
}
