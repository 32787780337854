
import React from 'react'
import "./Thank.css"

const Thank = () => {
  return (
 
     <div className="testbox">
      <div className="banner">
        <p style={{ color: 'black' }}>
        Thank you for submitting your information, which we greatly appreciate. We will carefully review your details and reach out if necessary<br /><br />
        Should you have any questions or require any further assistance, please do not hesitate to contact us at <br /><br />
        <a href="mailto:connect@24sevenflix4u.co.uk" style={{color:"#ac1600" , fontSize:"15px" , fontWeight:"700"}}>connect@24sevenflix4u.co.uk</a> .<br /><br />
        We are grateful for your submission and eagerly anticipate a fruitful & long-term collaboration.<br /><br /><br />
        Best wishes, <br />
          <img src="https://admin.24sevenflix4u.co.uk/static/media/logo_final.aebfabe754cdb65518d2.png" style={{ height: '50px' }} /><br />
          Team 24 SEVEN FLIX4U
        </p>
      </div>
    </div>
   
  )
}

export default Thank