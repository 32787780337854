import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

// import poster from "./../../images/poster.jpg";
// import "./ProductDetailsContent.css";
// import Features from "./Features";
// import ProductProfile from "./ProductProfile";
import { producer_movie_detail_form } from "./../../actions/producer";
// import { episode_details } from "./../../actions/WebSeries/episode";
import { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IMAGE } from "./../../api/index";
import { saveAs } from 'file-saver'

// import WatchedCustomers from "./WatchedCustomers";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

const FormDetails = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  // const role = useSelector((state) => state.layout.role);
  // const path = location.pathname.split("/").filter((x) => x)[1];
  // console.log(path1,"879665")
  const formData = new FormData();
  formData.append("id", location.state?.id);
  useMemo(() => {
    dispatch(producer_movie_detail_form(formData));
  }, [location.state?.id]);
  const producerformdata = useSelector((state) => state.producer.formdetail?.data);
  // console.log(producerformdata,location.state?.id,"7536951")
//   const data = useSelector((state) =>
//     path == "MovieDetails"
//       ? state.movies.movie?.Data
//       : state?.webseries?.episode?.Data
//   );
//   const cast = useSelector((state) =>
//     path == "MovieDetails"
//       ? state.movies.movie?.Cast
//       : state.webseries?.episode?.Data?.episode_cast
//   );
//   const watch_hours = useSelector((state) =>
//     path == "MovieDetails"
//       ? state.movies.movie?.watch_hours
//       : state.webseries?.episode
//   );
  const Subtitle_list = useSelector((state) =>
   
  state.producer.formdetail?.data?.subtitle_list
   
  );
  const Audio_list = useSelector((state) =>
   
  state.producer.formdetail?.data?.audio_file_list
    
  );
  const SubtitleCount = useSelector((state) =>
    
  state.producer.formdetail?.data?.subtitle_count
     
  );
  const AudioCount = useSelector((state) =>
   
  state.producer.formdetail?.data?.audio_file_count
      
  );
//   const earning = useSelector((state) => state.webseries?.episode);
//   // const earning1 =useSelector((state) =>state)

//   const access =
//     path == "MovieDetails"
//       ? data?.movie_access
//       : data?.series_name?.series_type;
//   // console.log(data,"110000")
  return (
    <>
      <Grid
        container
        rowSpacing={1}
        justifyContent="center"
        columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
      >
        <Grid item spacing={4} xs={12} sm={12} md={6} lg={7} xl={7}>
          <Card
            sx={{
              boxShadow: "none",
              borderRadius: "10px",
              p: "15px 25px",
              mb: "15px",
            }}
          >
            <Box mt={2}>
              <Tabs className="product-details-tabs">
                <TabList>
                  <Tab>
                    {producerformdata?.category_type =="Movie" ?
                     "Movie Details" 
                      : "Series Details"} 
                  </Tab>
                  <Tab>Dates & Country</Tab>
                  {/* <Tab>Links</Tab> */}
                </TabList>

                <TabPanel>
                  <Card
                    sx={{
                      boxShadow: "none",
                      borderRadius: "10px",
                      p: "25px 20px",
                      mb: "10px",
                      backgroundColor: "#F7FAFF",
                    }}
                  >
                    <Typography
                      as="h3"
                      sx={{
                        fontSize: 16,
                        mb: "15px",
                        fontWeight: 500,
                        mt: "-25px",
                        ml: "-25px",
                        mr: "-25px",
                        padding: "10px 25px",
                        borderRadius: "8px 8px 0px 0px",
                        background:
                          "linear-gradient(225deg,  var(--gradientColorLighter1) 0%, var(--gradientColorLighter2) 91.25%)",

                        color: "white !important",
                      }}
                    >
                      {producerformdata?.category_type =="Movie" ?
                      "Movie" : "Series" }
                      Details
                    </Typography>

                    <Box>
                      
                        <Typography
                          sx={{
                            fontSize: 13,
                            mt: "10px",
                            display: "flex",
                          }}
                        >
                          <span
                            style={{
                              width: "150px",
                              fontWeight: "500",
                            }}
                            className="mr-10px"
                          >
                            Category:
                          </span>
                          {producerformdata?.category_type =="Movie" ? producerformdata?.type_of_film : producerformdata?.type_of_series}
                        </Typography>
                      
                      {/* {path != "MovieDetails" && (
                        <Typography
                          sx={{
                            fontSize: 13,
                            mt: "10px",
                            display: "flex",
                          }}
                        >
                          <span
                            style={{
                              width: "150px",
                              fontWeight: "500",
                            }}
                            className="mr-10px"
                          >
                            Series Name:
                          </span>
                          {data?.series_name}
                        </Typography>
                      )} */}
                      {!producerformdata?.category_type =="Movie"  &&
                        <Typography
                          sx={{
                            fontSize: 13,
                            mt: "10px",
                            display: "flex",
                          }}
                        >
                          <span
                            style={{
                              width: "150px",
                              fontWeight: "500",
                            }}
                            className="mr-10px"
                          >
                            season :
                          </span>
                          {producerformdata?.season}
                        </Typography>
                      }

                      <Typography
                        sx={{
                          fontSize: 13,
                          mt: "10px",
                          display: "flex",
                        }}
                      >
                        <span
                          style={{
                            width: "150px",
                            fontWeight: "500",
                          }}
                          className="mr-10px"
                        >
                          Language:
                        </span>
                        {producerformdata?.language}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 13,
                          mt: "10px",
                          display: "flex",
                        }}
                      >
                        <span
                          style={{
                            width: "150px",
                            fontWeight: "500",
                          }}
                          className="mr-10px"
                        >
                          Rent:
                        </span>
                      {  parseFloat(producerformdata?.rent).toFixed(2)} INR
                       {/* {producerformdata?.rent} */}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 13,
                          mt: "10px", 
                          display: "flex",
                        }}
                      >
                        <span
                          style={{
                            width: "150px",
                            fontWeight: "500",
                          }}
                          className="mr-10px"
                        >
                          title:
                        </span>
                       {producerformdata?.title}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 13,
                          mt: "10px",
                          display: "flex",
                        }}
                      >
                        <span
                          style={{
                            width: "150px",
                            fontWeight: "500",
                          }}
                          className="mr-10px"
                        >
                          Festival Awards:
                        </span>
                       {producerformdata?.festival_awards}
                      </Typography>
                    </Box>
                  </Card>
                </TabPanel>
                <TabPanel>
                  <Card
                    sx={{
                      boxShadow: "none",
                      borderRadius: "10px",
                      p: "25px 20px",
                      mb: "10px",
                      backgroundColor: "#F7FAFF",
                    }}
                  >
                    <Typography
                      as="h3"
                      sx={{
                        fontSize: 16,
                        fontWeight: 500,
                        mb: "15px",
                        mt: "-25px",
                        ml: "-25px",
                        mr: "-25px",
                        padding: "10px 25px",
                        borderRadius: "8px 8px 0px 0px",
                        background:
                          "linear-gradient(225deg,  var(--gradientColorLighter1) 0%, var(--gradientColorLighter2) 91.25%)",

                          color: "white !important",
                      }}
                    >
                      Dates  & Country 
                    </Typography>
                    

                    <Box>
                    <Typography
                        sx={{
                          fontSize: 13,
                          mt: "10px",
                          display: "flex",
                        }}
                      >
                        <span
                          style={{
                            width: "150px",
                            fontWeight: "500",
                          }}
                          className="mr-10px"
                        >
                          Released Status :
                        </span>
                       {producerformdata?.movie_released_status}
                      </Typography>
                      {/* <Typography
                        sx={{
                          fontSize: 13,
                          mt: "10px",
                          display: "flex",
                        }}
                      >
                        <span
                          style={{
                            width: "150px",
                            fontWeight: "500",
                          }}
                          className="mr-10px"
                        >
                          Release Date :
                        </span>
                       {producerformdata?.proposed_release_date}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 13,
                          mt: "10px",
                          display: "flex",
                        }}
                      >
                        <span
                          style={{
                            width: "150px",
                            fontWeight: "500",
                          }}
                          className="mr-10px"
                        >
                          Premiere Date:
                        </span>
                        {producerformdata?.proposed_premiere_date}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 13,
                          mt: "10px",
                          display: "flex",
                        }}
                      >
                        <span
                          style={{
                            width: "150px",
                            fontWeight: "500",
                          }}
                          className="mr-10px"
                        >
                          Theatrical Date
                        </span>
                       {producerformdata?.theatrical_date}
                      </Typography> */}
                       { producerformdata?.theatrical_date && <Typography
                        sx={{
                          fontSize: 13,
                          mt: "10px",
                          display: "flex",
                        }}
                      >
                        <span
                          style={{
                            width: "150px",
                            fontWeight: "500",
                          }}
                          className="mr-10px"
                        >
                          Release Date :
                        </span>
                       {producerformdata?.theatrical_date}
                      </Typography>}
                     { !producerformdata?.theatrical_date && <Typography
                        sx={{
                          fontSize: 13,
                          mt: "10px",
                          display: "flex",
                        }}
                      >
                        <span
                          style={{
                            width: "150px",
                            fontWeight: "500",
                          }}
                          className="mr-10px"
                        >
                          Premiere Date:
                        </span>
                        {producerformdata?.proposed_premiere_date}
                      </Typography>}
                     { !producerformdata?.theatrical_date&& <Typography
                        sx={{
                          fontSize: 13,
                          mt: "10px",
                          display: "flex",
                        }}
                      >
                        <span
                          style={{
                            width: "150px",
                            fontWeight: "500",
                          }}
                          className="mr-10px"
                        >
                          Theatrical Date
                        </span>
                       {producerformdata?.theatrical_date}
                      </Typography>}
                      <Typography
                        sx={{
                          fontSize: 13,
                          mt: "10px",
                          display: "flex",
                        }}
                      >
                        <span
                          style={{
                            width: "150px",
                            fontWeight: "500",
                          }}
                          className="mr-10px"
                        >
                        Production Year
                        </span>
                       {producerformdata?.year_of_production}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 13,
                          mt: "10px",
                          display: "flex",
                        }}
                      >
                        <span
                          style={{
                            width: "150px",
                            fontWeight: "500",
                          }}
                          className="mr-10px"
                        >
                       Country Of Origin
                        </span>
                       {producerformdata?.country_of_origin}
                      </Typography>
                    </Box>
                  </Card>
                </TabPanel>
                {/* <TabPanel>
                  <Card
                    sx={{
                      boxShadow: "none",
                      borderRadius: "10px",
                      p: "25px 20px",
                      mb: "10px",
                      backgroundColor: "#F7FAFF",
                    }}
                  >
                    <Typography
                      as="h3"
                      sx={{
                        fontSize: 16,
                        fontWeight: 500,
                        mb: "15px",
                        mt: "-25px",
                        ml: "-25px",
                        mr: "-25px",
                        padding: "10px 25px",
                        borderRadius: "8px 8px 0px 0px",
                        background:
                          "linear-gradient(225deg,  var(--gradientColorLighter1) 0%, var(--gradientColorLighter2) 91.25%)",

                          color: "white !important",
                      }}
                    >
                     Links & Content
                    </Typography>

                    <Box>
                      <Typography
                        sx={{
                          fontSize: 13,
                          mt: "10px",
                          display: "flex",
                        }}
                      >
                        <span
                          style={{
                            width: "150px",
                            fontWeight: "500",
                          }}
                          className="mr-10px"
                        >
                          Trailer Link :
                        </span>
                       {producerformdata?.trailer_link}
                      </Typography>

                   
                      <Typography
                        sx={{
                          fontSize: 13,
                          mt: "10px",
                          display: "flex",
                        }}
                      >
                        <span
                          style={{
                            width: "150px",
                            fontWeight: "500",
                          }}
                          className="mr-10px"
                        >
                         mp4_URL:
                        </span>
                        {producerformdata?.mp4_URL}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 13,
                          mt: "10px",
                          display: "flex",
                        }}
                      >
                        <span
                          style={{
                            width: "150px",
                            fontWeight: "500",
                          }}
                          className="mr-10px"
                        >
                        Imdb / facebook Url
                        </span>
                        {producerformdata?.imdb_facebook_url}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 13,
                          mt: "10px",
                          display: "flex",
                        }}
                      >
                        <span
                          style={{
                            width: "150px",
                            fontWeight: "500",
                          }}
                          className="mr-10px"
                        >
                          Sound Format

                        </span>
                       {producerformdata?.sound_format_required}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 13,
                          mt: "10px",
                          display: "flex",
                        }}
                      >
                        <span
                          style={{
                            width: "150px",
                            fontWeight: "500",
                          }}
                          className="mr-10px"
                        >
                       Country Of Oragin
                        </span>
                       {producerformdata?.country_of_origin}
                      </Typography>
                    </Box>
                  </Card>
                </TabPanel> */}
              </Tabs>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={5} xl={5} key={"sdcsdc"}>
          {/* <ProductProfile data={data} path={path} />
          <Features earning={earning} path={path} /> */}
          <Card
            sx={{
              boxShadow: "none",
              borderRadius: "10px",
              p: "15px 25px",
              mb: "15px",
            }}
          >
            <Box mt={2}>
              <Tabs className="product-details-tabs">
                <TabList>
                  {/* <Tab>
                    {producerformdata.category_type =="Movie" ? "Movie Details" : "Series Details"}
                  </Tab> */}
                  <Tab>Production Details</Tab>
                  <Tab>Person Details</Tab>
                </TabList>

                <TabPanel>
                  <Card
                    sx={{
                      boxShadow: "none",
                      borderRadius: "10px",
                      p: "25px 20px",
                      mb: "10px",
                      backgroundColor: "#F7FAFF",
                    }}
                  >
                    <Typography
                      as="h3"
                      sx={{
                        fontSize: 16,
                        mb: "15px",
                        fontWeight: 500,
                        mt: "-25px",
                        ml: "-25px",
                        mr: "-25px",
                        padding: "10px 25px",
                        borderRadius: "8px 8px 0px 0px",
                        background:
                          "linear-gradient(225deg,  var(--gradientColorLighter1) 0%, var(--gradientColorLighter2) 91.25%)",

                          color: "white !important",
                      }}
                    >
                      
                     
                      Production Details
                    </Typography>

                    <Box>
                      
                        <Typography
                          sx={{
                            fontSize: 13,
                            mt: "10px",
                            display: "flex",
                          }}
                        >
                          <span
                            style={{
                              width: "150px",
                              fontWeight: "500",
                            }}
                            className="mr-10px"
                          >
                            Production Name:
                          </span>
                          {producerformdata?.production_name}
                        </Typography>
                      

                      {/* {!producerformdata?.category_type =="Movie"  ( */}
                        <Typography
                          sx={{
                            fontSize: 13,
                            mt: "10px",
                            display: "flex",
                          }}
                        >
                          <span
                            style={{
                              width: "150px",
                              fontWeight: "500",
                            }}
                            className="mr-10px"
                          >
                           Distribution Name
                          </span>
                          {producerformdata?.distribution_name}
                        </Typography>
                      {/* )} */}
                       <Typography
                        sx={{
                          fontSize: 13,
                          mt: "10px",
                          display: "flex",
                        }}
                      >
                        <span
                          style={{
                            width: "150px",
                            fontWeight: "500",
                          }}
                          className="mr-10px"
                        >
                          Producer:
                        </span>
                        {producerformdata?.producer}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 13,
                          mt: "10px",
                          display: "flex",
                        }}
                      >
                        <span
                          style={{
                            width: "150px",
                            fontWeight: "500",
                          }}
                          className="mr-10px"
                        >
                          Director:
                        </span>
                        {producerformdata?.director}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 13,
                          mt: "10px",
                          display: "flex",
                        }}
                      >
                        <span
                          style={{
                            width: "150px",
                            fontWeight: "500",
                          }}
                          className="mr-10px"
                        >
                          Cast:
                        </span>
                       {producerformdata?.cast}
                      </Typography>
                    </Box>
                  </Card>
                </TabPanel>
                <TabPanel>
                  <Card
                    sx={{
                      boxShadow: "none",
                      borderRadius: "10px",
                      p: "25px 20px",
                      mb: "10px",
                      backgroundColor: "#F7FAFF",
                    }}
                  >
                    <Typography
                      as="h3"
                      sx={{
                        fontSize: 16,
                        mb: "15px",
                        fontWeight: 500,
                        mt: "-25px",
                        ml: "-25px",
                        mr: "-25px",
                        padding: "10px 25px",
                        borderRadius: "8px 8px 0px 0px",
                        background:
                          "linear-gradient(225deg,  var(--gradientColorLighter1) 0%, var(--gradientColorLighter2) 91.25%)",

                          color: "white !important",
                      }}
                    >
                      
                     
                    Person Details
                    </Typography>

                    <Box>
                      
                        <Typography
                          sx={{
                            fontSize: 13,
                            mt: "10px",
                            display: "flex",
                          }}
                        >
                          <span
                            style={{
                              width: "150px",
                              fontWeight: "500",
                            }}
                            className="mr-10px"
                          >
                            Contact Person :
                          </span>
                          {producerformdata?.contact_person_name}
                        </Typography>
                      

                      
                        <Typography
                          sx={{
                            fontSize: 13,
                            mt: "10px",
                            display: "flex",
                          }}
                        >
                          <span
                            style={{
                              width: "150px",
                              fontWeight: "500",
                            }}
                            className="mr-10px"
                          >
                          Mobile No
                          </span>
                          {producerformdata?.mobileNumber}
                        </Typography>
                  
                       <Typography
                        sx={{
                          fontSize: 13,
                          mt: "10px",
                          display: "flex",
                        }}
                      >
                        <span
                          style={{
                            width: "150px",
                            fontWeight: "500",
                          }}
                          className="mr-10px"
                        >
                          email:
                        </span>
                        {producerformdata?.email}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 13,
                          mt: "10px",
                          display: "flex",
                        }}
                      >
                        <span
                          style={{
                            width: "150px",
                            fontWeight: "500",
                          }}
                          className="mr-10px"
                        >
                          GST No:
                        </span>
                        {producerformdata?.GST}
                      </Typography>
  
                     
                      <Typography
                        sx={{
                          fontSize: 13,
                          mt: "10px",
                          display: "flex",
                        }}
                      >
                        <span
                          style={{
                            width: "150px",
                            fontWeight: "500",
                          }}
                          className="mr-10px"
                        >
                          Rights Available:
                        </span>
                       {producerformdata?.rights_available}
                      </Typography>
                    </Box>
                  </Card>
                </TabPanel>
                </Tabs>
            </Box>
          </Card>

        </Grid>
        {/* <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Card
            sx={{
              boxShadow: "none",
              borderRadius: "10px",
              p: "15px 25px",
              mb: "15px",
            }}
          >
            <Box>
              <Card
                sx={{
                  boxShadow: "none",
                  borderRadius: "10px",
                  p: "25px 20px",
                  mb: "10px",
                  backgroundColor: "#F7FAFF",
                }}
              >
                <Typography
                  as="h3"
                  sx={{
                    fontSize: 16,
                    mb: "15px",
                    fontWeight: 500,
                    mt: "-25px",
                    ml: "-25px",
                    mr: "-25px",
                    padding: "10px 25px",
                    borderRadius: "8px 8px 0px 0px",
                    background:
                      "linear-gradient(225deg,  var(--gradientColorLighter1) 0%, var(--gradientColorLighter2) 91.25%)",

                      color: "white !important",
                  }}
                >
                  Subtitle List
                </Typography>
                <Table>
                  <TableHead>
                    <TableRow style={{ fontWeight: 900, fontSize: "25px" }}>
                      <TableCell style={{ fontWeight: 900, fontSize: "17px" }}>
                        Language
                      </TableCell>
                      <TableCell style={{ fontWeight: 900, fontSize: "17px" }}>
                        File
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {SubtitleCount !== 0 ? (
                    <TableBody>
                      {Subtitle_list?.map((sub) => (
                        <TableRow>
                          <TableCell>{sub?.subtitle_language}</TableCell>
                          <TableCell>
                            <a
                              href={IMAGE + sub?.subtitle_file}
                              target="_blank"
                              download="new-file"
                            >
                              {sub?.subtitle_file.split("/")[6]}
                            </a>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  ) : (
                    <TableBody align="center">
                      No subtitle is available for this Movie
                    </TableBody>
                  )}
                </Table>
              </Card>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Card
            sx={{
              boxShadow: "none",
              borderRadius: "10px",
              p: "15px 25px",
              mb: "15px",
            }}
          >
            <Box>
              <Card
                sx={{
                  boxShadow: "none",
                  borderRadius: "10px",
                  p: "25px 20px",
                  mb: "10px",
                  backgroundColor: "#F7FAFF",
                }}
              >
                <Typography
                  as="h3"
                  sx={{
                    fontSize: 16,
                    mb: "15px",
                    fontWeight: 500,
                    mt: "-25px",
                    ml: "-25px",
                    mr: "-25px",
                    padding: "10px 25px",
                    borderRadius: "8px 8px 0px 0px",
                    background:
                      "linear-gradient(225deg,  var(--gradientColorLighter1) 0%, var(--gradientColorLighter2) 91.25%)",

                      color: "white !important",
                  }}
                >
                  Audio File List
                </Typography>
                <Table>
                  <TableHead>
                    <TableRow style={{ fontWeight: 900, fontSize: "25px" }}>
                      <TableCell style={{ fontWeight: 900, fontSize: "17px" }}>
                        Audio
                      </TableCell>
                      <TableCell style={{ fontWeight: 900, fontSize: "17px" }}>
                        File
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {AudioCount !== 0 ? (
                    <TableBody>
                      {Audio_list?.map((sub) => (
                        <TableRow>
                          <TableCell>{sub?.audio_language}</TableCell>
                          <TableCell>
                            <a
                              // href={IMAGE + sub?.audio_file}
                              // target="_blank"
                              onClick={() => { saveAs(IMAGE + sub?.audio_file, 'promocode.mp3') }}
                              // download="new-file"
                            >
                              {sub?.audio_file.split("/")[6]}
                            </a>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  ) : (
                    <TableBody align="center">
                      No Audio files are available for this Movie
                    </TableBody>
                  )}
                </Table>
              </Card>
            </Box>
          </Card>
        </Grid> */}
      <Grid  item spacing={4} xs={12} sm={12} md={12} lg={12} xl={12}>
      <Card
            sx={{
              boxShadow: "none",
              borderRadius: "10px",
              p: "15px 25px",
              mb: "15px",
            }}
          >
            <Box mt={2}>
              <Tabs className="product-details-tabs">
                <TabList>
                <Tab>Links</Tab>
                <Tab>Subtitle Link</Tab>
                <Tab>Audio Link</Tab>

                </TabList>

                <TabPanel>
                  <Card
                    sx={{
                      boxShadow: "none",
                      borderRadius: "10px",
                      p: "25px 20px",
                      mb: "10px",
                      backgroundColor: "#F7FAFF",
                    }}
                  >
                    <Typography
                      as="h3"
                      sx={{
                        fontSize: 16,
                        fontWeight: 500,
                        mb: "15px",
                        mt: "-25px",
                        ml: "-25px",
                        mr: "-25px",
                        padding: "10px 25px",
                        borderRadius: "8px 8px 0px 0px",
                        background:
                          "linear-gradient(225deg,  var(--gradientColorLighter1) 0%, var(--gradientColorLighter2) 91.25%)",

                          color: "white !important",
                      }}
                    >
                     Links & Content
                    </Typography>

                    <Box>
                      <Typography
                        sx={{
                          fontSize: 13,
                          mt: "10px",
                          display: "flex",
                        }}
                      >
                        <span
                          style={{
                            width: "150px",
                            fontWeight: "500",
                          }}
                          className="mr-10px"
                        >
                          Trailer Link :
                        </span>
                       {producerformdata?.trailer_link}
                      </Typography>

                   
                      <Typography
                        sx={{
                          fontSize: 13,
                          mt: "10px",
                          display: "flex",
                        }}
                      >
                        <span
                          style={{
                            width: "150px",
                            fontWeight: "500",
                          }}
                          className="mr-10px"
                        >
                         mp4_URL:
                        </span>
                        {producerformdata?.mp4_URL}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 13,
                          mt: "10px",
                          display: "flex",
                        }}
                      >
                        <span
                          style={{
                            width: "150px",
                            fontWeight: "500",
                          }}
                          className="mr-10px"
                        >
                        Imdb / facebook Url
                        </span>
                        {producerformdata?.imdb_facebook_url}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 13,
                          mt: "10px",
                          display: "flex",
                        }}
                      >
                        <span
                          style={{
                            width: "150px",
                            fontWeight: "500",
                          }}
                          className="mr-10px"
                        >
                          Sound Format

                        </span>
                       {producerformdata?.sound_format_required}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 13,
                          mt: "10px",
                          display: "flex",
                        }}
                      >
                        <span
                          style={{
                            width: "150px",
                            fontWeight: "500",
                          }}
                          className="mr-10px"
                        >
                       Country Of Oragin
                        </span>
                       {producerformdata?.country_of_origin}
                      </Typography>
                    </Box>
                  </Card>
                </TabPanel>
                <TabPanel>
                  <Card
                    sx={{
                      boxShadow: "none",
                      borderRadius: "10px",
                      p: "25px 20px",
                      mb: "10px",
                      backgroundColor: "#F7FAFF",
                    }}
                  >
                    <Typography
                      as="h3"
                      sx={{
                        fontSize: 16,
                        fontWeight: 500,
                        mb: "15px",
                        mt: "-25px",
                        ml: "-25px",
                        mr: "-25px",
                        padding: "10px 25px",
                        borderRadius: "8px 8px 0px 0px",
                        background:
                          "linear-gradient(225deg,  var(--gradientColorLighter1) 0%, var(--gradientColorLighter2) 91.25%)",

                          color: "white !important",
                      }}
                    >
                    Subtitle Links
                    </Typography>

                    <Box>
                    {Subtitle_list?.map((sub) => (
                      <Typography
                        sx={{
                          fontSize: 13,
                          mt: "10px",
                          display: "flex",
                        }}
                      >
                        <span
                          style={{
                            width: "150px",
                            fontWeight: "500",
                          }}
                          className="mr-10px"
                        >
                        {sub?.subtitle_language}
                        </span>
                        <a
                              href={sub?.subtitle_file}
                              target="_blank"
                              download="new-file"
                            >
                              {sub?.subtitle_file}
                            </a>
                      </Typography>

))}
                      {/* <Typography
                        sx={{
                          fontSize: 13,
                          mt: "10px",
                          display: "flex",
                        }}
                      >
                        <span
                          style={{
                            width: "150px",
                            fontWeight: "500",
                          }}
                          className="mr-10px"
                        >
                         mp4_URL:
                        </span>
                        {producerformdata?.mp4_URL}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 13,
                          mt: "10px",
                          display: "flex",
                        }}
                      >
                        <span
                          style={{
                            width: "150px",
                            fontWeight: "500",
                          }}
                          className="mr-10px"
                        >
                        Imdb / facebook Url
                        </span>
                        {producerformdata?.imdb_facebook_url}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 13,
                          mt: "10px",
                          display: "flex",
                        }}
                      >
                        <span
                          style={{
                            width: "150px",
                            fontWeight: "500",
                          }}
                          className="mr-10px"
                        >
                          Sound Format

                        </span>
                       {producerformdata?.sound_format_required}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 13,
                          mt: "10px",
                          display: "flex",
                        }}
                      >
                        <span
                          style={{
                            width: "150px",
                            fontWeight: "500",
                          }}
                          className="mr-10px"
                        >
                       Country Of Oragin
                        </span>
                       {producerformdata?.country_of_origin}
                      </Typography> */}
                    </Box>
                  </Card>
                </TabPanel>
                <TabPanel>
                  <Card
                    sx={{
                      boxShadow: "none",
                      borderRadius: "10px",
                      p: "25px 20px",
                      mb: "10px",
                      backgroundColor: "#F7FAFF",
                    }}
                  >
                    <Typography
                      as="h3"
                      sx={{
                        fontSize: 16,
                        fontWeight: 500,
                        mb: "15px",
                        mt: "-25px",
                        ml: "-25px",
                        mr: "-25px",
                        padding: "10px 25px",
                        borderRadius: "8px 8px 0px 0px",
                        background:
                          "linear-gradient(225deg,  var(--gradientColorLighter1) 0%, var(--gradientColorLighter2) 91.25%)",

                          color: "white !important",
                      }}
                    >
                    Audio Links
                    </Typography>

                    <Box>
                    {Audio_list?.map((sub) => (
                      <Typography
                        sx={{
                          fontSize: 13,
                          mt: "10px",
                          display: "flex",
                        }}
                      >
                        <span
                          style={{
                            width: "150px",
                            fontWeight: "500",
                          }}
                          className="mr-10px"
                        >
                        {sub?.audio_language}
                        </span>
                        <a
                              href={sub?.audio_file}
                              target="_blank"
                              download="new-file"
                            >
                              {sub?.audio_file}
                            </a>
                      </Typography>

))}
                      {/* <Typography
                        sx={{
                          fontSize: 13,
                          mt: "10px",
                          display: "flex",
                        }}
                      >
                        <span
                          style={{
                            width: "150px",
                            fontWeight: "500",
                          }}
                          className="mr-10px"
                        >
                         mp4_URL:
                        </span>
                        {producerformdata?.mp4_URL}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 13,
                          mt: "10px",
                          display: "flex",
                        }}
                      >
                        <span
                          style={{
                            width: "150px",
                            fontWeight: "500",
                          }}
                          className="mr-10px"
                        >
                        Imdb / facebook Url
                        </span>
                        {producerformdata?.imdb_facebook_url}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 13,
                          mt: "10px",
                          display: "flex",
                        }}
                      >
                        <span
                          style={{
                            width: "150px",
                            fontWeight: "500",
                          }}
                          className="mr-10px"
                        >
                          Sound Format

                        </span>
                       {producerformdata?.sound_format_required}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 13,
                          mt: "10px",
                          display: "flex",
                        }}
                      >
                        <span
                          style={{
                            width: "150px",
                            fontWeight: "500",
                          }}
                          className="mr-10px"
                        >
                       Country Of Oragin
                        </span>
                       {producerformdata?.country_of_origin}
                      </Typography> */}
                    </Box>
                  </Card>
                </TabPanel>
              </Tabs>
            </Box>
          </Card>
      </Grid>
      </Grid>
      {/* {role != "Producer" && (
        <WatchedCustomers id={location.state?.id} path={path} />
      )} */}
    </>
  );
  // return (
  //   <div>fsd</div>
  // )
};

export default FormDetails;
